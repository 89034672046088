export default {
  authenticationTip:
    '根据国家工信部规定，比特浏览器客户需要进行企业或个人认证，请您仔细填写资料，认证成功后信息不可修改，该信息仅用于认证，平台方不会泄露。',
  enterpriseCertification: '企业认证',
  enterpriseName: '企业名称',
  pleaseEnterpriseName: '请输入企业名称',
  creditCode: '统一社会信用代码',
  pleaseCreditCode: '请输入统一社会信用代码',
  pleaseCreditCode1: '请输入正确的社会信用代码',
  pleaseIdCard: '请输入身份证号',
  pleaseIdCard1: '输入正确的中国身份证',
  legalPerson: '法人信息',
  idCard: '法人身份证号',
  pleaseLegalPersonName: '请输入法人姓名',
  pleaseLegalPersonNameTip: '法人姓名最多50字符',
  companyNameMax: '企业名称最多50字符',
  enterprise: '企业',
  authentication: '认证管理',
  Personal: '个人认证',
  certificationSuccessful: '认证成功',
  certificationFailed: '认证失败',
  personalTip: '请填写正确的姓名、证件类型、证件号码',
  personalTip1: '请输入真实姓名',
  personalTip2: '请选择证件类型',
  personalTip3: '请输入证件号码',
  personalTip4: '认证步骤：',
  personalTip5: '使用支付宝“扫一扫”扫描生成的二维码，点击开始认证；',
  personalTip6: '根据支付宝的提示，在支付宝内完成刷脸认证。',
  personalTip7: '认证完成后，点击“已完成认证”，在个人认证面板中显示“认证成功”即完成认证！',
  personalTip7_1: '认证完成后，点击“已完成认证”。',
  nextStep: '下一步',
  Back: '上一步',
  personalTip8: '已完成认证',
  personalTip9: '请填写真实姓名',
  personalTip10: '姓名不能超过10个字符',
  personalTip11: '请填写证件号码',
  personalTip12: '请输入正确的证件号码',
  personalTip13: '证件号码长度不超过30位',
  IDtype: '证件类型',
  idNo: '证件号码',
  name: '姓名',
  authenticationFailed: '认证失败，请重新认证！',
  authenticationFailed1: '认证失败，请确认信息无误后重新认证！',
  certified: '待认证',
  toAuthen: '去认证',
  enterpriseSuccess: '企业认证成功',
  legalPersonNameTip: '法人姓名根据上传企业营业执照自动识别，无法修改，如识别不准确，请联系客服。',
  underReview: '企业认证审核中，30分钟内审核完成，请您耐心等待！',
  agentVerification: '经办人认证',
  agentName: '经办人姓名',
  idCard: '身份证号',
  clickGoCertification: '点击“去认证”将使用您的支付宝进行刷脸识别认证',
  agentTip:
    '注意：只有成功完成经办人刷脸认证后，才可以继续进行下一步“企业信息核验”，企业信息核验主要包括：企业名称、统一社会信用代码、法人姓名、法人身份证件号等。',
  authenticationCompleted: '已完成认证',
  enterpriseVerification: '企业信息核验',
  enterLegalIdCard: '请输入法人身份证号',
  goEnterpriseCertification:
    '点击“去认证”将核验您填写的企业名称、统一社会信用代码、法人姓名、法人身份证号等，认证成功后，即可进行下一步的企业认证',
  auditFailed: '审核失败',
  selectEnterpriseAuthorization: '请选择您将要企业认证的方式',
  paymentTitle: '企业对公账户打款',
  paymentTitleTip: '使用企业银行账户向第三方平台银行账户发起反向打款，成功后认证完成。',
  paymentTip1: '企业对公打款流程',
  paymentTip2: '点击下方“获取账户”按钮，获取对公打款的账户信息。',
  paymentTip3: '请使用认证公司的对公银行账号向下方获取的账户打款指定金额。',
  paymentTip4: '注意：必须使用认证公司的对公银行账户，打款实际到账金额必须与获取的金额保持一致，否则认证失败。',
  paymentTip5: `打款完成请点击“已完成打款”然后等待认证，大约需要30分钟。`,
  getAccount: '获取账户',
  accountInformation: '账户信息',
  recipientName: '收款方公司名称',
  recipientAccount: '收款方账户',
  recipientBank: '收款方开户行',
  paymentAmount: '打款金额',
  legalAuthorization: '企业法人授权签署',
  legalAuthorizationTip: '通知企业法人并启动企业授权签署，完成签署任务后认证完成。',
  legalAuthorizationProcess: '企业法人授权签署流程',
  legalAuthorizationProcess1: '输入法人手机号，获取验证码，获取成功后，输入验证码。',
  legalAuthorizationProcess2: '点击“获取签署链接”按钮，获取链接地址/二维码（支持复制），使用【手机浏览器】打开地址后按步骤进行认证。',
  legalAuthorizationProcess3: '若已经完成签署授权认证，请点击“已完成授权”按钮。',
  legalAuthorizationProcess4: '当签署链接失效时，请重新输入法人手机号，获取签署链接。',
  getSigning: '获取签署链接',
  signingLink: '签署链接',
  paymentCompleted: '若已经完成打款，请点击“已完成打款”按钮',
  authorizationCompleted: '若已经完成签署授权认证，请点击“已完成授权”按钮',
  enterpriseCompleted: '恭喜您，已完成企业认证',
  paymentDone: '已完成打款',
  authorizationCompletedBtn: '已完成授权',
  legalPhone: '法人手机号',
  legalPersonName: '法人姓名',
  awaitCompletedPayment: '账户对公打款待完成，请完成后点击“已完成打款”按钮。',
  authorisationCertification: '签署授权认证待完成，请完成后点击“已完成授权”按钮。',
  download: '下载'
}
