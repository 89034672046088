export default {
  fillIn: 'Введите',
  select: 'Выберите',
  pickFile: 'Выбрать файл',
  fillInUsername: 'Введите имя пользователя',
  fillInGroupName: 'Введите название группы',
  fillInInt: 'Введите целое число',
  fillInNum: 'Введите число',
  edit: 'Изменить',
  cancel: 'Отмена',
  confirm: 'Подтвердить',
  action: 'Действие',
  loading: 'Загрузка...',
  positiveInt: 'Введите целое положительное число',
  notExcel: 'Выберите файл Excel для загрузки',
  importSuccess: 'Импорт успешен',
  reset2default: 'Сбросить настройки по умолчанию',
  reset: 'Сбросить',
  save: 'Сохранить',
  saveSuccess: 'Сохранение успешно',
  updateSuccess: 'Изменение успешно',
  verificationSuccessful: 'Верификация успешна',
  checkAll: 'Выбрать все',
  dontPopupAgain: 'Больше не показывать',
  close: 'Закрыть',
  numMax1000: 'Введите число меньше 1000',
  numMax10000: 'Введите четырехзначное число',
  numMax10000_1: 'Введите число меньше 10000',
  numMin100: 'Введите число больше 100',
  numMin500: 'Введите число больше 500',
  numMax2000: 'Введите число меньше 2000',
  numMin200: 'Введите число больше 200',
  numMin120: 'Введите число больше {arg}',
  numMin40: 'Введите число больше {arg}',
  searchPlaceholder: 'Введите содержание поиска',
  search: 'Поиск',
  advancedSearch: 'Фильтрация',
  searchNow: 'Начать поиск',
  userPlaceholder: 'Поиск учетных записей сотрудников',
  image: 'Выбрать изображение',
  back: 'Назад',
  filter: 'Фильтр',
  next: 'Далее',
  prev: 'Назад',
  copy: 'Копировать',
  copySuccess: 'Скопировано успешно',
  deleteSuccess: 'Удалено успешно',
  clear: 'Очистить',
  home: 'Вернуться на главную страницу',
  retry: 'Повторить',
  minimize: 'Свернуть в трей',
  cancelShareSelected: 'Отмена обмена данными',
  filterRepeat: 'Повторение фильтра',
  deleteProxy: 'Удалить выбранные прокси',
  numMax: 'Введите целое число (макс. 5 знаков)'
}
