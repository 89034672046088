export default {
  attention: 'Внимание:',
  tips1:
    '1. Ниже приведены рекомендуемые платформы прокси-IP, официально мы только предоставляем информацию. Принимайте решение  об оценке качества и услуг самостоятельно, BitBrower не дает гарантий и не несет ответственность! По вопросам использования прокси IP обращайтесь к службе поддержки платформы!',
  tips2: '',
  itemTitle: 'Ваша учетная запись на платформе {arg} была автоматически зарегистрирована. Вот данные для входа:',
  account: 'Учетная запись:',
  password: 'Начальный пароль:',
  itemTitle2: 'Эксклюзивный промокод на скидку 40%:',
  toAdmin: 'Перейти на официальный сайт {arg}',
  toIndex: 'Перейти на официальный сайт {arg}',
  toWebsite: 'Перейти на официальный сайт {arg}',
  showMore: 'Показать больше рекомендаций',
  hideMore: 'Скрыть дополнительные рекомендации',
  addTelMsg: 'Пожалуйста, заполните номер телефона или адрес электронной почты перед использованием',
  promoCode: 'Промокод',
  activationCode: '(получите специальный код активации на 200 МБ трафика: bitbrowser)'
}
