import { LocalStorage, Cookies, uid } from 'quasar'
import { generateNewUserToken } from 'src/api/user'
import store from 'src/store'

export const TOKEN_KEY = 'BITBROWSER_TOKEN'
const userNameKey = 'BITBROWSER_USERNAME'
const passowrdKey = 'BITBROWSER_PASSWORD'
const qrGroupKey = 'QR_GROUP_KEY'

const LoginTimeKey = 'Login_Time'
const markTokenCycleKey = 'MarkTokenCycle4'
const LoginSid = 'Login_SID'

/**
 * @description 获取token
 * @returns String
 */
export function getToken() {
  return LocalStorage.getItem(TOKEN_KEY)
}

/**
 * @description 存储token
 * @param token
 * @returns {void|*}
 */
export function setLocalToken(token) {
  Cookies.set(TOKEN_KEY, token, { expires: 365, path: '/' }) // 一年
  return LocalStorage.set(TOKEN_KEY, token)
}

export function saveLoginSid(sid) {
  if (!sid) return
  LocalStorage.set(LoginSid, sid)
}

export function getLoginSid() {
  return LocalStorage.getItem(LoginSid) || ''
}

/**
 * @description 移除token
 * @returns {void|Promise<void>}
 */
export function removeToken() {
  Cookies.remove(TOKEN_KEY)
  return LocalStorage.remove(TOKEN_KEY)
}

/**
 * @description 存储本地的用户信息
 * */
export function saveUserinfo2Local(userName, password) {
  LocalStorage.set(userNameKey, userName)
  LocalStorage.set(passowrdKey, password)
}

/**
 * @description 获取本地存储的用户信息
 * */
export function getLocalUserinfo() {
  return {
    userName: LocalStorage.getItem(userNameKey),
    password: LocalStorage.getItem(passowrdKey)
  }
}

/**
 * @description 清除本地用户信息
 * */
export function clearLocalUserinfo() {
  LocalStorage.remove(userNameKey)
  LocalStorage.remove(passowrdKey)
}

// 是否点击过官方群【我知道了】
export function hasKnowGroupQr() {
  return Cookies.has(qrGroupKey)
}

// 存储官方群【我知道了】，随机值
export function saveKnowGroupQr() {
  Cookies.set(qrGroupKey, uid(), { expires: 365 })
}

// 标记刚登录，5秒后自动删除
export function markLoginNow() {
  LocalStorage.set(LoginTimeKey, Date.now())
  setTimeout(() => {
    removeLoginMark()
  }, 5000)
}

// 移除登录标记
export function removeLoginMark() {
  LocalStorage.remove(LoginTimeKey)
}

// 检测是否需要弹出登录提示
export function needShowLoginTips() {
  return LocalStorage.has(LoginTimeKey)
}

// 标记token更换周期
export function markTokenCycle() {
  Cookies.set(markTokenCycleKey, 'C_Y', { expires: 7, path: '/' })
}

// 是否正在重置token
export function isExchangeToken() {
  return Cookies.has('TokenExchanging')
}

// 登录用户，重新设置一次token
export async function resetUserToken() {
  try {
    if (Cookies.has(markTokenCycleKey)) return
    markTokenCycle()

    // 5秒钟更换token有效期，期间内接口报错不提示
    Cookies.set('TokenExchanging', 'Y', { expires: new Date(Date.now() + 5000), path: '/' })

    const res = await generateNewUserToken()

    // TODO 旧版字符串返回信息，上线后删掉
    if (res.result) {
      store.commit('user/SET_TOKEN', res.result)
      return
    }

    if (res.access_token) {
      store.commit('user/SET_TOKEN', res.access_token)
    }
    if (res.sid) {
      store.commit('user/SET_SID', res.sid)
    }
  } catch (err) {}
}
