import { isExternal } from 'utils/validate'
import qs from 'qs'

/**
 * @description all模式渲染后端返回路由,支持包含views路径的所有页面
 * @param asyncRoutes
 * @returns {*}
 */
export function convertRouter(asyncRoutes) {
  // 过滤掉旧版的路由方式
  asyncRoutes = asyncRoutes.filter(item => !item.component.startsWith('@/views'))
  return asyncRoutes.map(route => {
    if (route.component) {
      const path = route.component
      // console.log(path)
      // webpack必须要传入静态参数和变量组合，不要传入整个变量，防止将整个变量识别为 .* ，而不能正确处理
      route.component = () => import(`pages/${path}`)
    }
    if (route.children && route.children.length) {
      route.children = convertRouter(route.children)
      route.redirect = route.children[0]
    } else {
      delete route.children
    }
    return route
  })
}

/**
 * @description 根据roles数组拦截路由
 * @param routes 路由
 * @param baseUrl 基础路由
 * @returns {[]}
 */
export function filterRoutes(routes, baseUrl = '/') {
  return routes.map(route => {
    route = { ...route }
    // route.path = route.path !== '*' && !isExternal(route.path) ? resolve(baseUrl, route.path) : route.path
    if (route.children) {
      route.children = filterRoutes(route.children, route.path)
      route.childrenNameList = route.children.flatMap(_ => _.childrenNameList)
      // if (!route.redirect) route.redirect = route.children[0].redirect ? route.children[0].redirect : route.children[0].path
    } else route.childrenNameList = [route.name]
    return route
  })
}

/**
 * 根据path路径获取matched
 * @param routes 菜单routes
 * @param name 路由名
 * @returns {*} matched
 */
export function handleMatched(routes, name) {
  return routes
    .filter(route => route.childrenNameList.indexOf(name) + 1)
    .flatMap(route => (route.children ? [route, ...handleMatched(route.children, name)] : [route]))
}

/**
 * 生成单个多标签元素，可用于同步/异步添加多标签
 * @param tag route页信息
 * @param init 是否是从router获取路由
 */
export function handleTabs(tag, init = false) {
  let parentIcon = null
  if (tag.matched)
    for (let i = tag.matched.length - 2; i >= 0; i--) if (!parentIcon && tag.matched[i].meta.icon) parentIcon = tag.matched[i].meta.icon
  if (!parentIcon) parentIcon = 'menu-line'
  const path = handleActivePath(tag, true)
  if (tag.name && tag.meta && tag.meta.tabHidden !== true) {
    return {
      path: path,
      query: tag.query,
      params: tag.params,
      name: tag.name,
      matched: init ? [tag.name] : tag.matched.map(route => route.components.default.name),
      parentIcon,
      meta: { ...tag.meta }
    }
  }
}

/**
 * 根据当前route获取激活菜单
 * @param route 当前路由
 * @param isTab 是否是标签
 * @returns {string|*}
 */
export function handleActivePath(route, isTab = false) {
  const { meta, path } = route
  const rawPath = route.matched ? route.matched[route.matched.length - 1].path : path
  const fullPath = route.query && Object.keys(route.query).length ? `${route.path}&${qs.stringify(route.query)}` : route.path
  if (isTab) return meta.dynamicNewTab ? fullPath : rawPath
  if (meta.activeMenu) return meta.activeMenu
  return fullPath
}

/**
 * 获取当前跳转登录页的Route
 * @param currentPath 当前页面地址
 */
export function toLoginRoute(currentPath) {
  if (currentPath !== '/')
    return {
      path: '/login',
      query: { redirect: currentPath },
      replace: true
    }
  else return { path: '/login', replace: true }
}

/**
 * 获取路由中所有的Name
 * @param routes 路由数组
 * @returns {*} Name数组
 */
export function getNames(routes) {
  return routes.flatMap(route => {
    let names = []
    if (route.name) names.push(route.name)
    if (route.children) names.push(...getNames(route.children))
    return names
  })
}
