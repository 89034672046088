export default {
  typePlaceholder: 'Выберите категорию для поиска',
  datePlaceholder: 'Поиск по времени',
  contentPlaceholder: 'Поиск по содержимому операций',
  type: 'Категория',
  content: 'Содержимое операции',
  username: 'Пользователь операции',
  createdTime: 'Время операции',
  browsers: 'Управление окнами',
  groups: 'Управление группами',
  users: 'Управление сотрудниками',
  roles: 'Управление ролями',
  setting: 'Настройки системы',
  app: 'Программные операции',
  bill: 'Управление счетами',
  maskLogBtn: 'Блокировать журнал операций',
  maskLog:
    'Вы уверены, что хотите заблокировать журнал операций? После блокировки журнал больше не будет отображаться ни для основного аккаунта, ни для аккаунтов сотрудников.<p class="text-red q-mt-sm">Внимание: Блокировка журнала операций является необратимой операцией, после подтверждения блокировки журнал операций больше не будет отображаться никогда!</p>',
  maskLogTip: 'Журнал операций успешно заблокирован',
  maskLogTip1: 'Журнал операций заблокирован',
  historyLog: 'Исторический журнал',
  newLogs: 'Последние записи',
  chidrenPlaceholder: 'Выберите категорию для поиска',
  loginLogs: 'Журнал входа',
  actionLosg: 'Журнал операций',
  exit: 'Выход',
  action: 'Операция',
  time: 'Время',
  localDB: 'Данные локальной базы данных',
  fieldPlaceholder: 'Выберите поле для поиска',
  fieldValue: 'Значение поля',
  deleteLocalLog: 'Удалить журнал',
  deleteLocalLogSuccess: 'Данные не могут быть восстановлены после удаления, вы уверены, что хотите удалить журнал?'
}
