export default {
  addTask: '新建RPA任务',
  updateTask: '修改RPA任务',
  bindBrowser: '绑定窗口',
  workflowDashboard: '进入流程设计（使用112内核）',
  taskConcurrent: '任务并发设置',
  taskConcurrentTips: '*此处代表同时打开多少个窗口执行该RPA任务',
  userManual: '操作手册',
  taskName: '任务名称',
  workflowId: '工作流ID',
  type: '类型',
  startTime: '开始时间',
  endTime: '结束时间',
  remark: '备注',
  remarkPlaceholder: '请输入RPA任务备注',
  taskNameRule: '请填写任务名称',
  workflowRule: '请填写工作流ID',
  browserConcurrent: '窗口并发数',
  browserConcurrentRule: '请填写窗口并发数',
  frequency: '执行频率',
  frequencyRule: '请填写执行频率',
  specifiedTime: '定时任务',
  timeInterval: '循环任务',
  immediately: '立即执行',
  day: '天',
  hour: '小时',
  minute: '分钟',
  selected: '已选择',
  startTimeRule: '请选择开始时间',
  startTimeRule2: '开始时间必须大于当前时间',
  endTimeRule: '请选择结束时间',
  endTimeRule2: '结束时间必须晚于开始时间',
  deleteTaskText: '确认删除任务吗？',
  rpaStatus: '状态',
  running: '运行中',
  pending: '等待执行',
  disabled: '已禁用',
  finished: '已执行',
  concurrentPlaceholder: '请输入并发数',
  concurrentValue: '任务并发值',
  concurrentRule1: '请输入并发值',
  runTask: '开始任务',
  stopTask: '停止任务',
  deleteTask: '删除任务',
  statusTooltip: '开启/禁用RPA任务',
  disableTask: '确认禁用RPA任务吗？<p class="q-mt-sm text-red">禁用任务后，将不再触发定时器执行。</p>',
  enableTask: `确认启用RPA任务吗？`,
  logs: '任务日志',
  logsTime: '执行时间',
  logContent: '执行结果',
  intervalPrepend: '每隔',
  intervalAppend: '执行一次',
  concurrentTips: '此处代表允许多少个任务同时运行，请根据电脑配置设置！',
  success: '成功',
  bindError: '请至少绑定一个窗口',
  hasBind: '已绑定：',
  bindNow: '绑定',
  logsType: '类型',
  openBrowser: '打开窗口',
  openSkip: '窗口已打开，跳过',
  openFailed: '窗口打开失败',
  closeBrowser: '关闭窗口',
  intNums: '请输入大于0的整数',
  skipTask: '任务运行中，跳过',
  enableRule: '请先修改RPA任务，确保开始时间大于当前时间！',
  timeout: '任务超时时间',
  timeoutTips: '*每个窗口打开后若超过该时间，将自动关闭（填写0该功能将失效）',
  timeoutRequired: '请填写任务超时时间',
  timeoutNums: '请填写大于等于0的数字',
  seconds: '秒',
  selectionTips:
    '注意：已绑定的窗口会展示在列表的最上方，点击箭头图标进行倒序/正序排列，已勾选绑定的窗口和未勾选绑定的窗口会分开进行排序。',
  appendPageData: '全选该页',
  cancelPageData: '反全选该页',
  cancelAll: '取消选择全部',
  taskSetting: '任务设置【并发{arg}】',
  taskSettingTitle: '任务设置',
  runningStrategy: '窗口执行策略',
  notOpened: '已打开窗口禁止执行RPA任务',
  allCanRun: '已打开窗口允许执行RPA任务',
  allCanRunTips: '已打开的窗口执行RPA任务，将不再触发“任务超时时间”！',
  runAtOpened: '已打开窗口执行任务',
  automaTips: '预计将于下周一至下周二进行内嵌automa扩展的版本升级，强烈建议所有RPA用户，导出已设计好的工作流进行备份，防止意外。',
  specifiedCore: '使用 {version} 内核'
}
