export default {
  add: '添加员工',
  users: '员工',
  assigned: '已用',
  total: '总数',
  role: '角色：',
  roleHead: '角色',
  usernameHead: '用户名',
  username: '用户名：',
  showBrowsers: '查看员工窗口数据',
  resetPass: '重置用户密码',
  resetPlaceholder: '请输入密码',
  resetPassword: '密码',
  confirmPasswordPlaceholder: '请再次输入密码',
  confirmPassword: '确认密码',
  rolePlaceholder: '选择角色（可点击右侧按钮快捷创建角色）',
  namePlaceholder: '请输入姓名',
  name: '姓名',
  usernamePlaceholder: '请输入登录用户名',
  passwordPlaceholder: '请输入登录密码',
  password: '登录密码',
  tel: '手机号码',
  telPlaceholder: '请输入手机号码',
  emailPlaceholder: '请输入邮箱',
  email: '邮箱',
  userType: '身份',
  userType2Tips: '可以管理所有的员工账号及员工的窗口数据、分组数据',
  userType3Tips: '可以管理名下员工账号及员工的窗口数据、分组数据',
  userType4Tips: '不具有管理权限，仅可以管理名下窗口数据',
  members: '管理成员',
  manager: '所属经理',
  noData: '暂无数据',
  group: '授权分组',
  belong: '归属：',
  browserCount: '窗口数：',
  browserCountHint: '仅主账户可分配窗口数量',
  browserCountLabel: '可创建浏览器窗口数',
  envCountLabel: '可创建云手机环境数',
  groupDialogBtn: '进入查看',
  userGroup: '员工分组',
  userGroupTips: '注意：选择“员工分组”后，点击“进入查看”按钮，将会直接跳转到浏览器窗口界面，并自动筛选出该员工您所指定分组的窗口数据。',
  browserCountTableHead: '窗口数',
  devCountTableHead: '手机环境数',
  countColumnTips: '已用窗口数 / 总数)',
  status: '状态',
  createdTime: '添加日期',
  passwordRuleMsg1: '请输入密码',
  passwordRuleMsg2: '密码至少6位',
  passwordRuleMsg3: '密码不超过30位',
  confirmPasswordMsg1: '请再次输入密码',
  confirmPasswordMsg2: '两次输入密码不一致',
  addDialogTitle: '添加员工',
  editDialogTitle: '修改员工',
  roleRuleMsg: '请选择角色',
  usernameRuleMsg: '用户名最少6位',
  emailRuleMsg: '请输入正确的邮箱账号',
  emailRuleMsg2: '邮箱账号长度不能超过50位',
  browserCountRuleMsg: '请输入可创建浏览器窗口数',
  browserCountRuleMsg2: '最小窗口数不可小于0',
  browserCountRuleMsg3: '可创建浏览器窗口数不能多于已开通或已分配的窗口总数',
  envCountRuleMsg: '请输入可创建云手机环境数',
  envCountRuleMsg2: '最小手机环境数不可小于0',
  envCountRuleMsg3: '可创建云手机环境数不能多于已开通或已分配的环境总数',

  deleteConfirm:
    '您确定要删除此用户吗？<br/><span class="text-negative">子账号名下有归属窗口时，无法直接删除用户，请先删除或转移归属用户窗口，再删除子账号！</span>',
  deleteSuccess: '用户删除成功',
  deleteUser: '删除用户',
  resetSuccess: '用户密码重置成功',
  activateConfirm: '您确定要启用此用户吗？',
  deactivateConfirm: '您确定要停用此用户吗？',
  activateSuccess: '用户启用成功',
  deactivateSuccess: '用户停用成功',
  editSuccess: '用户修改成功',
  addSuccess: '用户添加成功',
  allBrowsers: '全部窗口',
  ownBrowsers: '自建窗口',
  userGroupDialogTitle: '查看员工窗口数据（{name}）',
  roles: '角色管理',
  addRole: '添加角色',
  roleNamePlaceholder: '请输入角色名称',
  roleName: '角色名称',
  permission: '权限',
  roleNameRule1: '请输入角色名称',
  roleNameRule2: '角色名称不能超过8个字符',
  roleNameRule3: '请输入中英文或数字',
  editRoleTitle: '修改角色',
  deleteRoleTitle: '删除角色',
  addRoleTitle: '添加角色',
  deleteRoleConfirm: '您确定删除此角色吗？<br/>角色删除后，不可恢复，请谨慎操作！',
  deleteRoleSuccess: '角色删除成功',
  editRoleSuccess: '角色修改成功',
  addRoleSuccess: '角色添加成功',
  checkPermission: '请选择权限',
  toBrowsers: '进入查看',
  groupHint: '同一分组可以授权给多个员工账号',
  secondaryPasswordMsg: '请输入该账号对应的密码，以执行该操作！',
  securityVerification: '请完成安全验证',
  securityVerification1: '向右滑动填充拼图',
  securityVerification2: '再试一次',
  closeDualVerify: '开启/关闭双重验证',
  closeDualVerifyDialog: '您确定关闭该员工的双重验证吗？',
  openDualVerifyDialog: '您确定开启该员工的双重验证吗？',
  openSuccess: '开启双重验证成功',
  closeSuccess: '关闭双重验证成功',
  oneClick: '一键生成',
  usernameRequired: '请输入用户名',
  usernameLenLimit: '用户名不超过30位',
  usernameSchema: '用户名为英文、数字或下划线',
  dashboardRoleTip: '首页无论是否勾选，所有用户均会默认展示首页，首页子权限仅控制首页内功能模块是否显示。'
}
