export default {
  authenticationTip:
    'Пользователи BitBrowser должны пройти аутентификацию личности или предприятия согласно правилам Министерства промышленности и информационных технологий. Информация не подлежит изменению после успешной аутентификации и используется исключительно для этой цели.',
  enterpriseCertification: 'Аутентификация предприятия',
  enterpriseName: 'Наименование предприятия',
  pleaseEnterpriseName: 'Введите наименование предприятия',
  creditCode: 'Код единого социального кредита',
  pleaseCreditCode: 'Введите код единого социального кредита',
  pleaseCreditCode1: 'Введите правильный код социального кредита',
  pleaseIdCard: 'Введите номер удостоверения личности',
  pleaseIdCard1: 'Введите правильное удостоверение личности гражданина Китая',
  legalPerson: 'Информация о юридическом лице',
  idCard: 'Номер удостоверения личности',
  pleaseLegalPersonName: 'Введите фамилию и имя юридического лица',
  pleaseLegalPersonNameTip: 'Фамилия и имя юридического лица может содержать до 50 символов',
  companyNameMax: 'Наименование предприятия может содержать до 50 символов',
  enterprise: 'Предприятие',
  authentication: 'Управление аутентификацией',
  Personal: 'Аутентификация личности',
  certificationSuccessful: 'Успешная аутентификация',
  certificationFailed: 'Аутентификация не удалась',
  personalTip: 'Введите правильные фамилию и имя, тип документа и номер документа',
  personalTip1: 'Введите настоящие фамилию и имя',
  personalTip2: 'Выберите тип документа',
  personalTip3: 'Введите номер документа',
  personalTip4: 'Этапы аутентификации:',
  personalTip5: "Используйте функцию 'Сканировать' в Alipay для сканирования сгенерированного QR-кода. Нажмите 'Начать аутентификацию'.",
  personalTip6: 'Следуйте инструкциям в Alipay, чтобы завершить аутентификацию по распознаванию лиц.',
  personalTip7:
    "После завершения аутентификации нажмите 'Аутентификация завершена'. В личной панели аутентификации будет отображаться 'Успешная аутентификация'!",
  personalTip7_1: "После завершения аутентификации нажмите 'Аутентификация завершена'.",
  nextStep: 'Следующий шаг',
  Back: 'Назад',
  personalTip8: 'Аутентификация завершена',
  personalTip9: 'Введите настоящие фамилию и имя',
  personalTip10: 'Имя не может превышать 10 символов',
  personalTip11: 'Введите номер документа',
  personalTip12: 'Введите правильный номер документа',
  personalTip13: 'Длина номера документа не должна превышать 30 символов',
  IDtype: 'Тип документа',
  idNo: 'Номер документа',
  name: 'Фамилия и имя',
  authenticationFailed: 'Аутентификация не удалась. Повторите аутентификацию!',
  authenticationFailed1: 'Аутентификация не удалась. Подтвердите информацию и повторите аутентификацию!',
  certified: 'Подлежит аутентификации',
  toAuthen: 'Перейти к аутентификации',
  enterpriseSuccess: 'Успешная аутентификация предприятия',
  legalPersonNameTip:
    'Имя юридического лица автоматически определяется на основе загруженной лицензии на ведение предпринимательской деятельности и не может быть изменено. Если определение неверное, свяжитесь с службой поддержки.',
  underReview:
    'Проверка аутентификации предприятия находится в процессе, завершение проверки займет около 30 минут. Пожалуйста, будьте терпеливы!',
  agentVerification: 'Проверка агента',
  agentName: 'Фамилия и имя агента',
  clickGoCertification: "Кнопка 'Аутентификация' - оплата через AliPay с лицевой идентификацией",
  agentTip:
    "Примечание: Только после успешного завершения аутентификации агента можно перейти к следующему этапу 'Проверка информации о предприятии', который включает в себя: наименование предприятия, единый код социального кредита, имя юридического лица, номер удостоверения личности юридического лица и т. д.",
  authenticationCompleted: 'Аутентификация завершена',
  enterpriseVerification: 'Проверка предприятия',
  enterLegalIdCard: 'Введите номер удостоверения личности юридического лица',
  goEnterpriseCertification:
    "Нажмите 'Перейти к аутентификации', чтобы проверить наименование предприятия, единый код социального кредита, имя юридического лица, номер удостоверения личности юридического лица и т. д. Если аутентификация прошла успешно, вы можете перейти к следующему этапу аутентификации предприятия",
  auditFailed: 'Проверка не удалась',
  selectEnterpriseAuthorization: 'Выберите метод аутентификации предприятия',
  paymentTitle: 'Оплата на корпоративный счет предприятия',
  paymentTitleTip:
    'Используйте корпоративный банковский счет для инициирования обратного платежа на банковский счет сторонней платформы. После успешной оплаты аутентификация завершается.',
  paymentTip1: 'Процесс корпоративного платежа',
  paymentTip2: "Нажмите кнопку 'Получить счет' ниже, чтобы получить информацию о счете для корпоративных платежей.",
  paymentTip3: 'Пожалуйста, используйте корпоративный банковский счет для оплаты указанной суммы на полученный ниже счет.',
  paymentTip4:
    'Примечание: Необходимо использовать корпоративный банковский счет аутентифицированной компании, и фактическая сумма платежа должна соответствовать полученной ниже сумме, в противном случае аутентификация не будет проведена.',
  paymentTip5: "После завершения оплаты нажмите 'Оплата завершена', а затем подождите аутентификации. Это займет около 30 минут.",
  getAccount: 'Получить счет',
  accountInformation: 'Информация о счете',
  recipientName: 'Название компании получателя',
  recipientAccount: 'Счет получателя',
  recipientBank: 'Банк получателя',
  paymentAmount: 'Сумма оплаты',
  legalAuthorization: 'Правовая авторизация предприятия',
  legalAuthorizationTip:
    'Уведомите юридическое лицо предприятия и начните подпись правовой авторизации предприятия. После завершения подписи аутентификация завершена.',
  legalAuthorizationProcess: 'Процесс подписания правовой авторизации предприятия',
  legalAuthorizationProcess1:
    'Введите мобильный номер юридического лица, получите код подтверждения и введите код подтверждения после успешного получения.',
  legalAuthorizationProcess2:
    "Нажмите кнопку 'Получить ссылку для подписания', чтобы получить ссылку/QR-код (поддерживается копирование). Откройте адрес ссылки с помощью мобильного браузера и следуйте инструкциям для аутентификации.",
  legalAuthorizationProcess3: "Если аутентификация авторизации подписи завершена, пожалуйста, нажмите кнопку 'Авторизация завершена'.",
  legalAuthorizationProcess4:
    'Если ссылка для подписания истекла, повторно введите мобильный номер юридического лица, чтобы получить ссылку для подписания.',
  getSigning: 'Получить ссылку для подписания',
  signingLink: 'Ссылка для подписания',
  paymentCompleted: "Если оплата завершена, пожалуйста, нажмите кнопку 'Оплата завершена'",
  authorizationCompleted: "Если аутентификация авторизации подписи завершена, пожалуйста, нажмите кнопку 'Авторизация завершена'",
  enterpriseCompleted: 'Поздравляем, Аутентификация предприятия завершена',
  paymentDone: 'Оплата завершена',
  authorizationCompletedBtn: 'Авторизация завершена',
  legalPhone: 'Мобильный номер юридического лица',
  legalPersonName: 'Фамилия и имя юридического лица',
  awaitCompletedPayment: "Ожидание завершения платежа на корпоративный счет. Нажмите 'Оплата завершена' после завершения.",
  authorisationCertification: "Ожидание завершения аутентификации подписи. Нажмите 'Авторизация завершена' после завершения.",
  download: 'Скачать'
}
