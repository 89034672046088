export default {
  browserGroup: '窗口分组',
  add: '添加',
  groupName: '分组名称：',
  search: '查询',
  toBrowser: '点击快速查看分组窗口',
  toBrowser1: '点击快速查看设备',
  toEnvironment: '点击快速查看环境',
  copyID: '复制ID',
  groupNamePlaceholder: '请输入分组名称',
  sortPlaceholder: '请输入一个大于等于0排序数字',
  sortHint: '说明：请输入数字，数字越小排序越靠前',
  sort: '排序',
  belongTo: '归属',
  belongUser: '归属用户',
  belongUserTips: '修改“归属用户”后，该分组及分组下窗口将转移给其他用户，请谨慎操作！',
  belongUserTipsPhone: '修改“归属用户”后，该分组及分组下手机将转移给其他用户，请谨慎操作！',
  belongUserTipsEnv: '修改“归属用户”后，该分组及分组下环境将转移给其他用户，请谨慎操作！',
  groupNameRuleMsg1: '请输入分组名称',
  groupNameRuleMsg2: '分组名不能超过50个字符',
  groupNameRuleMsg3: '名称中不能包含空格与换行符',
  sortRuleMsg1: '请输入数字',
  sortRuleMsg2: '排序数字必须大于等于0且小于10000',
  addGroup: '添加分组',
  browserCount: '分组窗口数',
  createdTime: '创建时间',
  deleteConfirmText: '您确定要删除此分组吗？<br/>分组删除后，不可恢复，请谨慎操作！',
  deleteSuccess: '分组删除成功',
  editSuccess: '分组修改成功',
  addSuccess: '分组添加成功',
  copySuccess: '复制成功',
  deleteGroup: '删除分组',
  phoneGroup: '云手机分组'
}
