import { getUsdRate, getSymbolList } from 'api/cost'

const state = () => ({
  usdRate: 0, // 汇率
  symbolList: [] // 币种列表
})

const getters = {
  usdRate: state => state.usdRate
}

const mutations = {
  SET_USD_RATE(state, rate) {
    state.usdRate = rate
  }
}

const actions = {
  async getUsdRate({ commit }) {
    try {
      // 获取汇率
      const res = await getUsdRate()
      commit('SET_USD_RATE', res.result)
    } catch (error) {}
  }
}

export default { state, getters, mutations, actions }
