import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import constantRoutes from './routes'

const creationConfig = {
  history: process.env.VUE_ROUTER_MODE === 'history' ? createWebHistory() : createWebHashHistory(),
  routes: constantRoutes // 默认用静态路由创建
}

const router = createRouter(creationConfig)

export function addRouter(routes) {
  routes.forEach(route => {
    if (!router.hasRoute(route.name)) router.addRoute(route)
    if (route.children) addRouter(route.children)
  })
}

export function resetRouter(routes = constantRoutes) {
  router.getRoutes().forEach(route => {
    const routeName = route.name
    router.hasRoute(routeName) && router.removeRoute(routeName)
  })
  addRouter(routes)
}

export default router
