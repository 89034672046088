export default {
  workbench: 'Страница рабочего стола в окне браузера',
  chuhai2345: '2345 навигация по международным ресурсам',
  workbenchDisable: 'Скрыто',
  localServer: 'Локальная страница',
  workbenchTips: 'Использование локальной страницы или отключение может сэкономить трафик',
  abortImg: 'Отключить загрузку изображений',
  abortImgTips:
    'Чтобы предотвратить невозможность отображения CAPTCHA, рекомендуется установить значение 10 КБ; установка значения 0 КБ не будет загружать изображения!',
  abortMedia: 'Запретить автовоспроизведение/предзагрузку видео',
  abortMediaTips: 'Включение запрещает автопроигрывание видео и предзагрузку, не работает для нестандартных форматов',
  muteAudio: 'Запретить воспроизведение звука на веб-сайтах',
  muteAudioTips: 'Все видео и аудио будут воспроизводиться без звука (но в нормальном режиме),',
  stopWhileNetError: 'Остановить открытие при отсутствии сети',
  stopWhileNetErrorTips: 'Остановить загрузку браузера, если сеть недоступна из-за прокси или других причин',
  syncTabs: 'Синхронизация вкладок',
  syncTabsTips: 'Синхронизировать открытые вкладки в браузере',
  syncCookies: 'Синхронизация Cookie',
  syncCookiesTips: 'Синхронизировать Cookies браузера, чтобы сохранить состояние авторизации',
  syncIndexedDb: 'Синхронизация IndexedDB',
  syncIndexedDbTips: 'Синхронизировать данные IndexedDB, некоторые сайты используют IndexedDB вместе с Cookie',
  syncLocalStorage: 'Синхронизация Local Storage',
  syncLocalStorageTips: 'Синхронизация Local Storage, некоторые сайты используют его для входа.',
  syncBookmarks: 'Синхронизация закладок',
  syncBookmarksTips: 'Синхронизировать закладки браузера',
  syncAuthorization: 'Синхронизация сохраненных имени пользователя и пароля',
  syncAuthorizationTips: 'Синхронизация сохраненных имён пользователя и паролей в вашем браузере, поддерживается в версии 6.0.1 и выше.',
  credentialsEnableService: 'Запретить окно сохранения пароля',
  credentialsEnableServiceTips: 'При включении браузер не будет предлагать сохранять пароли',
  syncHistory: 'Синхронизация истории',
  syncHistoryTips: 'Синхронизировать историю посещений браузера',
  syncExtensions: 'Синхронизация данных расширений',
  syncExtensionsTips:
    'Синхронизируйте данные расширения для данного окна, чтобы сохранить состояние входа в приложение расширения. <span class="text-red">Не рекомендуется включать эту опцию при использовании криптовалютного кошелька; рекомендуется хранить данные приложения расширения локально!</span>',
  syncUserExtensions: 'Синхронизация данных расширений между окнами',
  syncUserExtensionsTips:
    'Все окна, в которых включена эта опция, будут синхронизировать последние данные расширений (эти окна всегда будут иметь одни и те же данные расширений; это позволяет им сохранять состояние входа в расширения)',
  clearCacheFilesBeforeLaunch: 'Удалить кэш перед запуском браузера',
  clearCacheFilesBeforeLaunchTips: 'Перед запуском браузера удалить все файлы кэша и не синхронизировать кэш, сохраненный на сервере',
  clearCookiesBeforeLaunch: 'Удалить Cookie перед запуском браузера',
  clearCookiesBeforeLaunchTips: 'Перед запуском браузера удалить все Cookie и не синхронизировать Cookie, сохраненные на сервере',
  clearHistoriesBeforeLaunch: 'Удалить историю перед запуском браузера',
  clearHistoriesBeforeLaunchTips:
    'Перед запуском браузера удалить всю историю посещений и не синхронизировать историю, сохраненную на сервере',
  randomFingerprint: 'Случайный отпечаток при запуске браузера',
  randomFingerprintTips: 'Каждый раз при запуске браузера будет генерироваться случайный отпечаток',
  allowedSignin: 'Разрешить вход в Chrome',
  allowedSigninTips:
    'После закрытия не обязательно заходить  в Chrome для входа на Gmail и другие сайты Google (синхронизация состояния входа на Gmail и другие сайты Google между разными компьютерами).',
  enableBackgroundMode: 'Продолжить работу приложения после закрытия браузера',
  enableBackgroundModeTips:
    'После закрытия браузера приложение будет продолжать работу в фоновом режиме, что позволяет экономить некоторые системные ресурсы',
  disableGpu: 'Использовать аппаратное ускорение',
  disableGpuTips:
    'После включения автоматически запустится аппаратное ускорение GPU при поддержке системы, что улучшит характеристики браузера',
  settingTips:
    'Подсказка: при создании нового окна предпочтительное значение будет использоваться в качестве значения по умолчанию для соответствующего атрибута. Изменение значения здесь не повлияет на уже существующие окна и не поддерживается локальным API.',
  settingTips1:
    ' При создании нового окна предпочтительное значение будет использоваться в качестве значения по умолчанию для соответствующего атрибута. Изменение значения здесь не повлияет ни на какие атрибуты уже существующих окон и не поддерживается локальным API.',
  disableTranslatePopup: 'Отключить всплывающее окно Google Translate',
  disableTranslatePopupTips: 'При включении будет запрещено автоматическое открытие всплывающего окна Google Translate в браузере',
  stopWhileIpChange: 'При смене IP прекратить открытие',
  stopWhileIpChangeTips: 'При изменении IP - остановить открытие браузера',
  resetLastIp: 'Сбросить IP',
  resetLastIpConfirm: 'Вы уверены, что хотите сбросить IP последнего открытого окна?',
  resetLastIpSuccess: 'IP сброшен, изменения вступят в силу после сохранения настроек!',
  disableSyncTips:
    'Примечание: Версия 5.0.9 и ниже больше не поддерживает функцию синхронизации. Если вам требуется следующая функция синхронизации, обновите до последней версии!',
  disableSyncTips1:
    'После включения следующих элементов синхронизации соответствующие данные будут синхронизированы с облаком (облегчая использование на разных устройствах/совместную работу). При совместном использовании/передаче профиля данные с включенных элементов также будут синхронизированы с получателем. Пожалуйста, действуйте осторожно.',
  syncExtensionsWarning:
    '<p class="text-red">При использовании криптовалютного кошелька не рекомендуется включать эту опцию. Рекомендуется хранить данные приложения расширения локально. Вы уверены, что хотите продолжить?</p>',
  syncUserExtensionVersionTips: 'Версия 6.0.1 и выше поддерживает эту функцию.',
  abortImgSizePrefix: 'Заблокировать загрузку',
  abortImgSizeSuffix: 'изображений, чтобы сэкономить трафик',
  stopWhileCountryChange: 'Прекратить доступ при изменении соответствующей  IP страны/региона',
  stopWhileCountryChangeTips:
    'Если текущий IP-адрес соответствует другой стране/региону по сравнению с последним разом, когда он был открыт, доступ к браузеру будет остановлен.',
  clearCacheWithoutExtensions: 'Удалить кэш перед запуском (сохранить данные расширения)',
  clearCacheWithoutExtensionsTips:
    'Удалить файлы кэш перед запуском браузера (сохранить данные расширения) и не синхронизироваться с кэш, сохраненным на сервере.',
  syncPaymentsAndAddress: 'Синхронизировать сохраненные методы оплаты и адреса',
  syncPaymentsAndAddressTips: 'Синхронизировать методы оплаты и адреса, сохраненные в окне браузера.',
  hide: 'Свернуть',
  more: 'Показать больше',
  disableNotifications: 'Отключить уведомления на веб-сайтах',
  disableNotificationsTips: 'При включении блокируются всплывающие уведомления на веб-сайтах.',
  disableClipboard: 'Отключить доступ к буферу обмена на веб-сайтах',
  disableClipboardTips: 'При включении предотвращает доступ веб-сайтов к содержимому буфера обмена для повышения безопасности.',
  memorySaver: 'Экономия памяти',
  memorySaverTips:
    'Когда включено, BitBrowser освобождает память от неактивных вкладок. Это предоставляет активным вкладкам и другим приложениям больше ресурсов компьютера и поддерживает высокую скорость работы BitBrowser.'
}
