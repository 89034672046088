export default {
  attention: '注意：',
  tips1:
    '1. 以下为客户所推荐的代理IP平台，官方仅做整理展示，服务与质量请自行判断，官方不做保证和承担责任！所用代理IP使用相关问题请咨询平台方客服！',
  tips2: '2. 免责声明：请合法合规使用，若因违规使用产生一切后果自行负责，与本软件官方无关！',
  itemTitle: '自动为您注册的{arg}平台账号密码如下：',
  account: '平台账号：',
  password: '初始密码：',
  itemTitle2: '专属6折优惠券码：',
  toAdmin: '进入{arg}官网',
  toIndex: '进入{arg}官网',
  toWebsite: '进入{arg}官网',
  showMore: '查看更多推荐',
  hideMore: '收起更多推荐',
  addTelMsg: '请完善手机号或邮箱后再进行使用',
  activationCode: '（领200M流量专属激活码：bitbrowser）'
}
