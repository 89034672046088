export default {
  seq: 'Seq',
  activate: 'Subscribe',
  renew: 'Renew',
  add: 'Add',
  editBrowser: 'Update Profile',
  import: 'Batch Import Profiles',
  batchAdd: 'Batch Add',
  batchUpdate: 'Batch Update',
  used: 'Added ',
  total: 'Total ',
  pkgEnvs: 'Subscription Profiles: ',
  pkgUsedEnvs: 'Added Profiles: ',
  pkgAssignedEnv: 'Assigned Profiles: ',
  pkgDisabledEnv: 'Disabled Profiles: ',
  pkgBrowsers: 'Package: ',
  pkgUsed: 'Added: ',
  pkgAssigned: 'Assigned: ',
  pkgDisabled: 'Disabled: ',
  openCount: 'Times ',
  activatePkg: 'Subscribe',
  changePkg: 'Change Plan',
  placeholder1: 'Filter by user name',
  all: 'All',
  mine: 'Mine',
  commonly: 'Fav',
  allStatus: 'All',
  opened: 'Opened',
  unOpened: 'Not Opened',
  share2me: 'Share',
  transform2me: 'Transfer',
  group: 'Group',
  groups: 'Groups',
  noGroup: 'Ungrouped',
  checked: 'Checked: ',
  refreshData: 'Refresh',
  customizeColumns: 'Customize Columns',
  recycle: 'Recycle Bin',
  moreAction: 'More',
  openChecked: 'Open Selected',
  clone: 'Clone Selected',
  setCommonly: 'Set As Favorite',
  cancelCommonly: 'Cancel Favorite',
  export: 'Export Profiles',
  exportChecked: 'Export Selected Profiles',
  exportBySeq: 'Export Profiles By Seq',
  exportByGroup: 'Export Profiles By Group',
  batchEdit: 'Batch Update Profiles',
  updateGroupOfChecked: 'Update Group',
  updateProxyOfChecked: 'Update Proxy',
  updateRemarkOfChecked: 'Update Remarks',
  updateSortOfChecked: 'Update Custom Serial Number',
  updatePropertiesOfChecked: 'Update All Properties Of Selected',
  closeBrowsers: 'Close Browsers',
  closeChecked: 'Close Selected Browsers',
  closeAll: 'Close All Browsers',
  clearCaches: 'Clear Browser Cache',
  clearCachesAll: 'Clear All Cache',
  share: 'Share To Users',
  shareBrowser: 'Share To Users',
  transform: 'Transfer To User',
  transformBrowser: 'Transfer To User',
  removeShared: 'Delete Shared Browser Profiles',
  deleteBrowsers: 'Delete Profiles',
  deleteBrowser: 'Delete Profiles',
  deleteCompletely: 'Permanently Delete Selected',
  deleteCompletelybrowser: 'Permanently Delete',
  moveToRecycle: 'Delete Selected To Recycle Bin',
  moveBrowserToRecycle: 'Delete To Recycle Bin',
  deleteCheckPwd: `<p class="text-bold q-mb-sm">If you need to disable the password verification, to <u class="cursor-pointer">Profile</u> to disable it! </p>`,
  belongTo: 'Belong To: ',
  noProxy: 'No Proxy',
  remark: 'Remark',
  lastOpenTime: 'Last Open Time:',
  lastCloseTime: 'Last Close Time:',
  lastUpdateTime: 'Last Update Time:',
  userName: 'User: ',
  isOpening: 'Is opening this profiles!',
  browserDisabled: 'The number of available Profiles is reduced, and the Profiles are automatically invalidated.',
  opening: 'Opening',
  open: 'Open',
  goDown: 'Go Down',
  goUp: 'Go Up',
  goMiddle: 'Go Middle',
  editGroup: 'Update Group',
  editGroupWithSeq: 'Update Group (Seq: {seq})',
  editNameWithSeq: 'Update Profile Name (Seq: {seq})',
  editNameWithSeq1: 'Update Phone Name (Seq: {seq})',
  browserName: 'Name',
  editRemark: 'Update Remarks',
  editRemarkWithSeq: 'Update Remarks (Seq: {seq})',
  editRemarkType: 'Update Method',
  replaceRemark: 'Replace',
  appendRemark: 'Append',
  appendRemarkTips: 'Note: New lines will be added based on the original remarks if "Append" method is used.',
  remarkContent: 'Content',
  exportFromSeq: 'Start Seq',
  minSeqPlaceholder: 'Please enter a number greater than 0',
  exportToSeq: 'End Seq',
  maxSeqPlaceholder: 'Please enter a number greater than the starting sequence',
  shareTitle: 'Share Browsers',
  shareTitle1: 'Share Phone',
  shareTips:
    'You can share the profile with multiple users for collaborative use. Simply enter the usernames and click the "Add" button. If some platforms are unable to comment after sharing, please enable IndexedDB synchronization and Local Storage synchronization and try again.',
  shareTips1: 'The phone can be shared with multiple users, just enter the user name and click the "Add" button.',
  shareWith: 'Share To',
  shareAdd: 'Add',
  shareRemark: 'Remark',
  transformTitle: 'Transfer Profiles',
  transformTitle1: 'Transfer Phone',
  transformTips:
    'After transferring the profile, the profile will be completely owned by the transferred user, and you will no longer see the profile. Please exercise caution! If some platforms cannot be commented after the migration, enable synchronous IndexedDB and Local Storage and try again.',
  transformTips1:
    'Tip: After transfer, the phone will completely belong to the transferred user, and you have no permission to see the phone, please be careful!',
  transformTo: 'Transfer To',
  groupName: 'Group Name',
  checkAll: 'Select All',
  loading: 'Loading...',
  checkGroup: 'Select Group',
  ruleNameMsg: 'Profile name cannot exceed 50 characters',
  ruleRemarkMsg: 'Remark cannot exceed 500 characters',
  ruleMinSeqMsg: 'The starting number is greater than 0',
  ruleMaxSeqMsg: 'The end number is greater than the start number',
  usernameMinLength: 'The user name is at least 6 characters',
  usernameExists: 'Already added the user',
  remarkMaxLength: 'Remarks up to 100 max length',
  transformGroupMsg: 'Please enter transfered group name under the user',
  noAddPermission: 'You currently do not have permission to create a browser, please contact the account admin to subscribe.',
  setSuccess: 'Success',
  notInClient: 'Please install the client software and proceed opreation!',
  installText: 'Install Desktop Client',
  getExtensionFailed: 'Get extensions failed, please check your network.',
  getConfigFailed: 'Get configuration failed, please refresh and try again.',
  openAnyway:
    'The browser has been opened in another account, do you still want to open it? <p style="margin-top: 10px">Note: After repeated opening, different accounts will trigger synchronization operations after closing browser, it may cause data synchronization error!</p>',
  cloneMsg: 'Clone browser, only one is allowed, multi-selection is prohibited.',
  cloneBrowser: 'Clone Profile',
  openAnyway2: 'The browser has been synchronously closed by other account. whether reopen it now?',
  openAnyway3: 'No more prompts this time',
  selectBrowser: 'Please Select Profiles.',
  noBrowserChecked: 'No Browser Selected.',
  freeAccountTips:
    'The free package will have certain restrictions, if you need unlimited use, please subscribe the charging package to use.',
  close: 'Close',
  checkedIncludesOpened: 'The selected browser contains opened browsers, please close the browser before proceeding.',
  checkedIncludesOpenedPhone: 'The selected Phone contains opened phones, please close the phone before proceeding.',
  checkedIncludesOpenedEnv: 'Selected phone contains open instances. Please close them before proceeding.',
  editShareGroupMsg: 'Group of shared browser cannot be edited.',
  editNameSuccess: 'Browser Name Updated.',
  editRemarkSuccess: 'Remark Updated.',
  deleteConfirmMsg: 'Cofirm to delete the browser? <br/>You can restore in browser recycle bin.',
  moveToRecycleSuccess: 'The browser has moved to recycle bin.',
  deleteCompletelyConfirm:
    '<p class="text-red">The permanently deleted profiles cannot be restored. Confirm to completely delete the selected  profiles?</p>',
  deleteCompletelySuccess: 'Success',
  deleteShareConfirm: 'Confirm to delete the shared browsers?',
  deleteShareSuccess: 'Success',
  handleInClient: 'Please handle in client.',
  clearCachesConfirm: 'Clearing the browser cache will invalidate all login status.<br/>Do you want to clear it?',
  clearCacheSuccess: 'Success',
  checkedHasClosed: 'The selected browser has closed.',
  closeBrowser: 'browser has closed',
  allBrowserClosed: 'All browsers has closed.',
  transformSuccess: 'Success',
  transformSuccess1: 'Success',
  atleatShareOneUser: 'Fill in and add at least one user',
  shareSuccess: 'Success',
  exportConfirm: 'Confirm to export selected browser profiles?',
  exportFilename: 'Exported Browsers.xlsx',
  exportBrowser: 'Export Profile',
  browserHasOpened: ' The Browser Has Opened.',
  exportFilenameWithSeq: 'Exported Browsers {seq}.xlsx',
  batchOpenTitle: 'Batch Open Browsers',
  batchOpenChecked: 'Select Browsers: ',
  batchOpened: 'Opened: ',
  batchOpenFailed: 'Open Failed: ',
  toSetting: 'Click Here To Resolve.',
  batchOpening: 'Opening...',
  batchOpenTips: 'Note: After the pop-up closed, the unopened browser will no longer to open.',
  CPU使用率: 'CPU Utilization Rate',
  内存使用率: 'Memory Utilization Rate',
  缓存所在目录剩余空间小于: 'The directory remaining space of the cache is less than ',
  extractIpFailed: 'Extract agent IP failed, stop opening',
  batchUpdateDialog: 'Batch Update Browsers',
  batchUpdateImport: 'For a single Excel, the number of imported browser should not exceed 1000',
  batchUpdateComment: 'Instructions: ',
  batchUpdateComments1: 'Export the browser that needs to update information; ',
  batchUpdateComments2: 'Modify the corresponding information based the exported ID;',
  batchUpdateComments3:
    'For fields where you do not wish to update and want to retain the existing data, such as cookies, simply fill in the corresponding cell with "Skip";',
  batchUpdateComments4: 'Upload the modified Excel to the server.',
  batchUpdateTips:
    'Note: Do not delete or modify the ID, the browser will not be recognized, and please keep same as before, if you want no update!',
  proxyIp: 'Proxy IP',
  createdTime: 'Created Time',
  config: 'Config',
  fileImport: 'File Import',
  importExcel: 'Import Excel',
  downloadTemplate: 'Downlod Template',
  importTips: '*It also supports importing common fingerprint browsers files.',
  downTemplateTips: 'Note',
  downTemplateTips2: 'The import template has been updated, please download the latest template to use.',
  checkFileErrMsg: 'Select File',
  downloadFilename: 'Import Browser Template.xlsx',
  uploadMaxSize: 'The upload max size is 20MB!',
  openProgressDialog: 'The Browser Is Opening...',
  browserOpened: 'The Browser Has Opened',
  searchItem: 'Search Item',
  setDefault: 'Set Default',
  searchNamePlaceholder: 'Enter browser name',
  searchUsernamePlaceholder: 'Enter user name',
  searchSeqPlaceholder: 'Enter seq (Separate with commas)',
  setSeq: 'Multiple Seq',
  minSeq: 'Min ',
  maxSeq: 'Max',
  searchRemarkPlaceholder: 'Enter remarks',
  searchRemark: 'Remark',
  searchProxyPlaceholder: 'Select proxy method',
  searchHostPlaceholder: 'Enter host',
  searchIdPlaceholder: 'Enter ID',
  searchBrowserStatus: 'Status',
  seqRuleMsg: 'Enter a number from 0 - 999999',
  seqSplitRuleMsg: 'Enter comma-separated integer sequence',
  proxyRuleMsg: 'Select a valid proxy type',
  hostRuleMsg: 'Host up to 100 characters',
  ipRuleMsg: 'IP up to 100 characters',
  shareBtn: 'Shared Profiles',
  shareBtn1: 'Shared Phones',
  shareDialog: 'My Shared Profiles',
  shareBrowserNamePlaceholder: 'Enter browser name',
  sharePhoneNamePlaceholder: 'Enter device name',
  shareRemarkPlaceholder: 'Enter remarks',
  shareUsernamePlaceholder: 'Enter user name',
  shareHandlerUser: 'Operation User',
  shareCancel: 'Cancel',
  shareCancelTips: "Do you want to cancel this browser's sharing? <br/>After cancelled, the shared user will no longer see this browser.",
  shareCancelTips1: "Are you sure to cancel sharing this phone? Once canceled, the shared user won't see it.",
  shareCancelSuccess: 'Success',
  检查并同步扩展中: 'Checking And Syncing Extensions...',
  '扩展同步失败，请尝试重新启动': 'Sync Extensions Failed, Please Try To Relaunch App...',
  '可能因为网络不稳造成，请尝试在客户端的左上角切换线路后重试！':
    'It may be caused by unstable network. Please try to switch the line in the upper left corner of the client and try again!',
  开始获取IP: 'Getting Proxy IP...',
  '直连模式，网络畅通': 'No Proxy, smooth network...',
  IP代理成功: 'IP Agent Succeeded...',
  网络不通已停止打开浏览器: 'Network Error And Stop Opening...',
  正在同步指纹信息: 'Syncing Fingerprint config...',
  正在同步缓存配置: 'Syncing Cache Files...',
  指纹同步失败: 'Syncing Fingerprint Failed',
  '浏览器缓存文件创建失败，请重试': 'Cache Dir Creation Failed, Please Reopen Again',
  正在打开浏览器窗口: 'Opening Browser...',
  '正在更新浏览器内核，请稍后':
    'Updating the browser kernel, the browser will automatically open after the update is complete! (If the download speed is slow, please turn off the proxy and try again)',
  打开窗口发生异常:
    'Browser launch failed: 1. Check if there is an orange kernel upgrade prompt button below the software title. Upgrade the kernel and try again; 2. Check if the window has been open for too long (more than 1 month). Try backing up important data, clearing the cache, and reopening; 3. It might be due to incorrect system time. Adjust it to the correct time; 4. The problem has not been solved after trying the above methods, please contact customer service!',
  缓存所在目录剩余空间小于: 'Browser {arg1} launch failed, the free space of the cache files located disk is less than {arg2}.',
  内存使用率超出: 'Browser {arg1} launch failed, the memory is greater than {arg2}.',
  同步IndexedDB失败: 'Sync IndexedDB Failed.',
  '同步Local Storage失败': 'Sync Local Storage Failed.',
  同步书签失败: 'Sync Bookmarks Failed.',
  同步历史记录失败: 'Sync History Failed.',
  同步已保存的密码失败: 'Sync Saved Password Failed.',
  同步Google账号信息失败: 'Sync Google Account Failed.',
  代理IP无法连接: 'Proxy IP cannot connect.',
  窗口已打开: 'The Browser Has Opened.',
  exportCookies: 'Export Cookies',
  batchBackupCookie: 'Batch Backup Cookies',
  batchBackupCookieTip: 'You are about to back up the cookies from the currently selected profile to the server. Do you wish to proceed?',
  exportBrowserCookies: 'Export Cookies',
  exportSelectedCookies: 'Export Selected',
  exportCookiesBySeq: 'Export by Seq',
  exportCookiesByGroup: 'Export by Group',
  exportCookiesConfirm: "Confirm to export the select browser's cookies as txt?",
  importCookies: 'Batch Update Cookies',
  importCookiesTips: 'Note:',
  importCookiesTips1: 'Save each cookie to a separate txt file, named after the browser profile seq, such as 1234.txt;',
  importCookiesTips2: 'Put all txt files into the same folder, name the folder Cookies, and then compress it into a zip file;',
  importCookiesTips3: 'A maximum of 100 txt files can be imported at a time, and the size of the zip file cannot exceed 20MB;',
  importCookiesTips4:
    'After uploading, the cookie of the profile corresponding to the seq will be replaced with the newly uploaded cookie.',
  notZip: 'Please select a zip file.',
  importCookiesUploader: 'The ZIP archive contains no more than 100 txt files.',
  updateCookiesSuccess: 'Cookie updated successfully.',
  reopenAtPos: 'Reopen Browsers',
  reopenSelectedAtPos: 'Reopen Selected',
  reopenAllAtPos: 'Reopen All',
  reopening: 'Reopening Browsers',
  reopeningBrowser: 'Reopening Browsers',
  reopenFinished: 'Reopen Finished.',
  reopenErrors: 'Browsers that reopen failed:',
  reopeningAndWait: 'Browser reopening, please wait...',
  nameSortTips:
    'If the name of the profile is named with numbers and you want to sort them completely according to the size of the numbers, please start with a relatively large number (the number of digits in all profiles names needs to be consistent, for example: 10000, 10001, 10099, 10100, 10101, 10110), to ensure that you can sort by the size of the numbers, otherwise there may be a similar situation where 100 and 110 are ranked between 10 and 99!',
  nameSortTips1:
    'If the name of the profile is named with numbers and you want to sort them completely according to the size of the numbers, please start with a relatively large number (the number of digits in all profiles names needs to be consistent, for example: 10000, 10001, 10099, 10100, 10101, 10110), to ensure that you can sort by the size of the numbers, otherwise there may be a similar situation where 100 and 110 are ranked between 10 and 99!',
  restoreSuccess: 'Restore Success.',
  noBackupCookie: 'No need to restore the cookie for this browser profile as it has not been backed up.',
  noShareSelf: 'Cannot share with yourself, please re-enter the username',
  correctUsername: 'Please enter the correct username',
  IP变更停止打开: 'IP Changed And Stop Opening...',
  上次打开窗口IP: 'Last IP: {arg1}',
  当前打开窗口IP: 'Current IP: {arg1}',
  '当前网络故障，请检查网络': 'Network Error...',
  ipChangeAttention:
    'Note: If you want to open the browser, please go to "Browser Profiles -> Config" and turn off the "Stop Opening When IP Changes" switch.',
  clearCacheWithoutExtensions: 'Clear Cache (Keep Extensions)',
  clearCachesConfirm2:
    'This operation will clear all cache files except for browser extensions and extension data. Are you sure you want to proceed with the clearance?',
  clearCloudCache: 'Delete Cloud Extensions Data',
  clearCloudCacheConfirm:
    'After the cloud extended data cache is deleted, the replacement of the computer login software will fail to synchronize the extended data cache, and the "Synchronize Extended Application Data" switch in the profile will be automatically closed. Are you sure to delete it?',
  closeAllBrowser: 'Please perform the action after closing the window',
  waitBatchOpenBrowser: 'Opening windows in batches, please wait...',
  beginning: 'starts with',
  contain: 'contains',
  precise: 'precise',
  stopWhileCountryChange: 'The current IP corresponds to a different country/region, stop opening the browser...',
  lastCountryName: 'The country/region at the last opening: {arg1}',
  currentCountryName: 'Current country/region: {arg1}',
  countryChangeAttention:
    "Note: If you wish to continue opening this profile, please go to 'Config -> Preference' and turn off the 'Cease Access For Changed IP Location' switch.",
  clientTooOld:
    'Your client version is too old. Please upgrade to the latest version for full functionality and enhanced security features. Your fingerprint will be more secure.',
  downloadLatestClient: 'Download the latest version',
  action: 'Operation',
  transferCancelTips:
    'Confirm transferring the selected profile to the user currently being shared with? After the transfer, the window will be entirely owned by the transferred user, and you will no longer be able to see it. Please proceed with caution!',
  transferCancelTips1:
    "Are you sure to transfer the selected phone? Once transferred, it will be owned by the recipient, and you won't be able to see it.!",
  transformSelect: 'Transfer',
  sharePeople: 'Sharer:',
  selectUserPlaceholder: 'Please select User',
  updateConfig: 'Update Profile',
  coreProductTip: 'Please select profiles from the same core product for batch modification.',
  isMacOpenBrowserTips:
    'The Mac system currently does not support opening profiles created by the Firefox kernel. Please use the Chrome kernel.',
  repairProduct: 'Attempt Repair',
  修复失败: 'Repair Failed. If you have proxy software enabled, please close it and try again.',
  修复成功: 'Repair Successful. Please reopen the profile.',
  customSort: 'Sorting',
  editCustomSort: 'Edit Sorting',
  sortLabel: 'Sorting',
  customSortBtn: 'Random Custom Sorting',
  customSortDialogTip1: 'Please go to "Customize Columns" and check "Custom Sorting" before you can use this function.',
  customSortDialogTip2: '{msg} will overwrite the original sorting value, and cannot be retrieved, do you want to continue to modify?',
  sortOkBtn: 'Go to Settings',
  sortRuleMsg2: 'The sorting number must be greater than or equal to 0 and less than 3000000.',
  customSeq: 'Sorting',
  seqRang: 'Seq Range',
  seqMultiple: 'Multiple Seq',
  specifySeq: 'Multiple Sorting',
  customSeqRange: 'Sorting Range',
  customSeqMsg: 'Random custom sorting',
  positiveSequenceNum: 'Ascending order',
  reverseSequenceNum: 'Descending order',
  startSerialNumlabel: 'Starting',
  sequenceNumplaceholder: 'Please enter the serial number',
  positiveSequenceTip:
    'The selected profiles will generate sequence numbers in positive order according to the set starting sequence number, which will overwrite the original sorting value and cannot be retrieved!',
  reverseSequenceTip:
    'The selected profiles will generate sequence numbers in reverse order according to the set starting sequence number, which will overwrite the original sorting value and cannot be retrieved!',
  exportSelected: 'Export Selected Profiles',
  verifyNow: 'Verify Now',
  batchOpenComplateFlag: 'Keep this popup after completion',
  cookieRobot: 'Cookie Robot',
  cookieRobotTip1:
    'For newly created profiles, some websites may be considered suspicious upon opening. A certain browsing history is required. Users can use this Cookie Robot to automatically browse the target website to generate browsing records.',
  cookieRobotTip2: 'By default, the selected platform URL is opened in the profile, but users can customize and add the required URLs.',
  closeAfterRobotDone: 'Close the profile after execution is complete.',
  cookieRobotUrlValid: 'Please provide at least one URL.',
  delBackup: 'Delete Backup',
  searchIdRule: 'Please enter a valid ID'
}
