<template>
  <q-dialog
    ref="dialog"
    :model-value="visible"
    @hide="onHide"
    @before-show="onBeforeShow"
    no-backdrop-dismiss
    :no-route-dismiss="noRouteDismiss"
  >
    <div class="column dialog-container no-wrap relative-position" :style="styleObj">
      <div class="text-center relative-position q-mb-sm q-mt-md q-mr-md" style="color: #464646; font-size: 14px">
        <strong>{{ title }}</strong>
        <slot name="subTitle"></slot>
        <q-btn
          icon="minimize"
          class="absolute theme-minimize"
          round
          flat
          unelevated
          dense
          style="right: 20px; top: -10px; z-index: 2; color: #464646"
          v-if="miniVisible"
          @click="onMixinCancel"
        ></q-btn>
        <q-btn
          icon="o_close"
          class="absolute"
          round
          flat
          unelevated
          dense
          style="right: -10px; top: -10px; z-index: 2"
          @click="onCancel"
          v-if="!hideClose"
        ></q-btn>
      </div>
      <div class="col" :class="{ 'overflow-auto': !showOverflowClass }">
        <div class="q-pa-sm">
          <slot></slot>
        </div>
      </div>
      <div class="text-right q-pa-sm" v-if="!hideFooter">
        <template v-if="$slots.footer">
          <slot name="footer"></slot>
        </template>
        <template v-else>
          <q-btn no-caps unelevated class="q-mr-sm theme-cancel-btn" @click="onCancel">
            {{ cancelText || $t('form.cancel') }}
          </q-btn>
          <q-btn no-caps color="primary" unelevated @click="onConfirm" :loading="loading">{{ confirmText || $t('form.confirm') }}</q-btn>
        </template>
      </div>
      <q-inner-loading :showing="innerLoading">
        <q-spinner color="primary" size="5em" :thickness="1" />
        <span>{{ $t('form.loading') }}</span>
      </q-inner-loading>
    </div>
  </q-dialog>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  name: 'BitDialog',
  props: {
    title: String,
    visible: Boolean,
    loading: Boolean,
    width: Number,
    maxWidth: Number,
    hideFooter: Boolean,
    hideClose: Boolean,
    confirmText: String,
    cancelText: String,
    innerLoading: Boolean,
    noRouteDismiss: Boolean,
    miniVisible: Boolean,
    showOverflowClass: Boolean
  },
  emits: ['hide', 'before-show', 'confirm', 'update:visible', 'update:miniVisible'],
  computed: {
    styleObj() {
      const style = {}

      if (this.width) {
        style.width = this.width + 'px'
      }

      // 为俄语打破最大长度限制
      if (this.maxWidth) {
        style.maxWidth = this.maxWidth + 'px'
      }

      return style
    }
  },
  data() {
    return {}
  },
  methods: {
    onHide() {
      this.$emit('hide')
      this.onCancel()
    },
    onBeforeShow() {
      this.$emit('before-show')
    },
    onConfirm: debounce(function () {
      this.$emit('confirm')
    }, 300),
    onCancel() {
      this.$emit('update:visible', false)
    },
    onMixinCancel() {
      this.$emit('update:miniVisible', false)
      this.$emit('update:visible', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-container {
  width: 500px;
  background: #fff;
  box-sizing: border-box;
}
</style>
