export default {
  browserGroup: 'Browser Profile Group',
  add: 'Add',
  groupName: 'Group Name: ',
  search: 'Search',
  toBrowser: 'Click to goto the grouping browsers.',
  toEnvironment: 'Click to quickly view the phone profiles.',
  toBrowser1: 'Click to goto the grouping device',
  copyID: 'Copy ID',
  groupNamePlaceholder: 'Please enter group name',
  sortPlaceholder: 'Please enter a sort number greater than or equal to 0',
  sortHint: 'Hint: Please enter a number, the smaller the number, the higher the order.',
  sort: 'Sort',
  belongTo: 'Belonging User',
  belongUser: 'Belonging User',
  belongUserTips: 'After updating the "Belonging User", the group and the browsers under the group will both be transferred to the user.',
  belongUserTipsPhone:
    "After changing the 'Owner User', the group and phones under the group will be transferred to another user. Please proceed with caution!",
  belongUserTipsEnv: `After modifying "Belonging User" the group and the profiles within the group will be transferred to another user. Please proceed with caution!`,
  groupNameRuleMsg1: 'Group name is required.',
  groupNameRuleMsg2: 'Group name cannot exceed 50 characters.',
  groupNameRuleMsg3: 'The name cannot contain spaces and line breaks.',
  sortRuleMsg1: 'Please enter a number',
  sortRuleMsg2: 'The sort number must be greater than or equal to 0 and less than 10000.',
  addGroup: 'Add Group',
  browserCount: 'Profiles',
  createdTime: 'Created Time',
  deleteConfirmText: 'Confirm to delete group?<br/>After the group is deleted, it cannot be restored. Please be careful!',
  deleteSuccess: 'Success',
  editSuccess: 'Success',
  addSuccess: 'Success',
  copySuccess: 'Success',
  deleteGroup: 'Delete Group',
  phoneGroup: 'Phone Group'
}
