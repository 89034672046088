import { boot } from 'quasar/wrappers'
import PermissionDirective from '../directives/permission'

export default boot(({ app }) => {
  // We globally register our directive with Vue;
  // Rememeber that all directives in Vue will start with 'v-'
  // but that should not be part of your directive name
  // https://vuejs.org/v2/guide/custom-directive.html
  // 'my-directive' will be used as 'v-my-directive'
  app.directive('permission', PermissionDirective)

  // app.use({ install })
})
