import dashboard from './dashboard'
import browser from './browser'
import form from './form'
import fingerprint from './fingerprint'
import preference from './preference'
import proxy from './proxy'
import windowBounds from './windowBounds'
import group from './group'
import agent from './agent'
import extensions from './extensions'
import user from './user'
import promotion from './promotion'
import withdraw from './withdraw'
import cost from './cost'
import setting from './setting'
import logs from './logs'
import profile from './profile'
import recycle from './recycle'
import common from './common'
import rpa from './rpa'
import phone from './phone'
import enterpriseManage from './enterpriseManage'
import computingPower from './computingPower'
import environment from './environment'
export default {
  dashboard,
  browser,
  form,
  fp: fingerprint,
  preference,
  proxy,
  wd: windowBounds,
  group,
  agent,
  extensions,
  user,
  promotion,
  withdraw,
  cost,
  setting,
  logs,
  profile,
  recycle,
  common,
  rpa,
  phone,
  em: enterpriseManage,
  cp: computingPower,
  et: environment
}
