import { openOrClose } from 'api/browser'
import { LocalStorage } from 'quasar'

const state = () => ({
  name: '',
  openedWindows: new Map(), // id集合
  openedPhones: new Set(), // id集合
  updateAvailable: false, // 发现新版本
  showUpdateDialog: false, // 更新窗口
  downloadPercent: 0, // 下载进度
  logo: require('assets/logo.svg'),
  softUrl: '', // 软件下载地址
  reloadAppPage: false,
  isMobile: '', //是否是移动端
  timeDifference: 0, // 本地时间与服务端时间差值
  clientVersion: '',
  themeDark: LocalStorage.getItem('darkMode') || false,
  isGolbalFireFox: false
})

const getters = {
  name: state => state.name,
  nameEn: state => state.nameEn,
  openedWindows: state => state.openedWindows,
  openedPhones: state => state.openedPhones,
  openedWindowIds: state => new Set(state.openedWindows.keys()),
  isInBitBrowser: () => !!window.isInBitBrowser,
  updateAvailable: state => state.updateAvailable,
  showUpdateDialog: state => state.showUpdateDialog,
  downloadPercent: state => state.downloadPercent,
  logo: state => state.logo,
  softUrl: state => state.softUrl,
  reloadAppPage: state => state.reloadAppPage,
  isMobile: state => state.isMobile,
  timeDifference: state => state.timeDifference,
  clientVersion: state => state.clientVersion,
  themeDark: state => state.themeDark,
  isGolbalFireFox: state => state.isGolbalFireFox
}

const mutations = {
  WINDOW_OPENED(state, config) {
    state.openedWindows.set(config.id, config)
  },
  WINDOW_CLOSED(state, id) {
    state.openedWindows.delete(id)
  },
  SET_PHONE_OPENED(state, arr) {
    state.openedPhones = new Set(arr)
  },
  PHONE_OPENED(state, id) {
    state.openedPhones.add(id)
  },
  PHONE_CLOSED(state, id) {
    state.openedPhones.delete(id)
  },
  // 刷新或其他原因导致重新加载时，同步客户端的数据
  SYNC_IDS(state, map) {
    state.openedWindows = map
  },
  UPDATE_AVAILABLE(state, available) {
    state.updateAvailable = available
  },
  SHOW_UPDATE_DIALOG(state, show) {
    state.showUpdateDialog = show
  },
  UPDATE_PERCENT(state, percent) {
    state.downloadPercent = percent
  },
  SET_OEM_INFO(state, info) {
    state.name = info.title
  },
  SET_SOFT_URL(state, url) {
    state.softUrl = url
  },
  SET_RELOAD_PAGE(state, payload) {
    state.reloadAppPage = payload
  },
  SET_ISMOBILE(state, flag) {
    state.isMobile = flag
  },
  SET_TIME_DIFFERENCE(state, payload) {
    state.timeDifference = payload
  },
  SET_CLIENT_VERSION(state, payload) {
    state.clientVersion = payload
  },
  THEME_DARK(state, dark) {
    state.themeDark = dark
    LocalStorage.set('darkMode', dark)
  },
  SET_ISGLOBALFIREFOX(state, flag) {
    state.isGolbalFireFox = flag
  }
}

const actions = {
  windowOpened({ commit }, config) {
    openOrClose({ ids: [config.id], status: 1 })
  },
  windowClosed({ commit }, { id, urls }) {
    openOrClose({ ids: [id], status: 0, url: urls || '' })
  }
}

export default { state, getters, mutations, actions }
