export default {
  typePlaceholder: 'Filter By Category',
  datePlaceholder: 'Filter by created time',
  contentPlaceholder: 'Filter By Content',
  type: 'Category',
  content: 'Content',
  username: 'Operation User',
  createdTime: 'Operation Time',
  browsers: 'Browsers',
  groups: 'Groups',
  users: 'Users',
  roles: 'Roles',
  setting: 'Settings',
  app: 'App',
  bill: 'Bill',
  maskLogBtn: 'Block Operation Logs',
  maskLog: `Confirm to block the operation log? After blocking, your account and all sub accounts will no longer display any operation logs. <p class="text-red q-mt-sm"> Note: Blocking the operation log is an irreversible operation. Once the block is confirmed, the operation log will never be displayed again! </p>`,
  maskLogTip: 'Log masking succeeded',
  maskLogTip1: 'Operation Blocked.',
  historyLog: 'Historical Log',
  newLogs: 'Latest Entries',
  chidrenPlaceholder: 'Please Filter By Catego',
  loginLogs: 'Login Logs',
  actionLosg: 'Operation Logs',
  exit: 'Logout',
  action: 'Operation',
  time: 'Time',
  localDB: 'Local Database Data',
  fieldPlaceholder: 'Select Field to Search',
  fieldValue: 'Field Value',
  deleteLocalLog: 'Delete Log',
  deleteLocalLogSuccess: 'Data cannot be recovered after deletion, are you sure you want to delete the log?'
}
