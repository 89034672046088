let ec

const triggerRpaCallbacks = []
const triggerPhoneOpenedCallbacks = []
const triggerPhoneCloseCallbacks = []

export function registerRpa(callback) {
  let index = triggerRpaCallbacks.findIndex(itemFn => itemFn.toString() === callback.toString())
  if (index > -1) {
    triggerRpaCallbacks.splice(index, 1)
  }
  triggerRpaCallbacks.push(callback)
}

export function registerPhoneClose(callback) {
  let index = triggerPhoneCloseCallbacks.findIndex(itemFn => itemFn.toString() === callback.toString())
  if (index > -1) {
    triggerPhoneCloseCallbacks.splice(index, 1)
  }
  triggerPhoneCloseCallbacks.push(callback)
}

export function registerPhonedOpened(callback) {
  let index = triggerPhoneOpenedCallbacks.findIndex(itemFn => itemFn.toString() === callback.toString())
  if (index > -1) {
    triggerPhoneOpenedCallbacks.splice(index, 1)
  }
  triggerPhoneOpenedCallbacks.push(callback)
}

function triggerRpa(e) {
  let data = {}
  if (e) {
    data = JSON.parse(e.data)
  }
  triggerRpaCallbacks.map(fn => fn && fn(data))
}

function triggerPhoneOpened(e) {
  let data = {}
  if (e) {
    data = JSON.parse(e.data)
  }
  triggerPhoneOpenedCallbacks.map(fn => fn && fn(data))
}

function triggerPhoneClosed(e) {
  let data = {}
  if (e) {
    data = JSON.parse(e.data)
  }
  triggerPhoneCloseCallbacks.map(fn => fn && fn(data))
}

export async function registSSEListener() {
  if (window.isInBitBrowser) {
    if (!ec) {
      let e = await window.bitClientMain.getLocalServerPort()
      ec = new EventSource(`http://127.0.0.1:${e}/sse`)
    }
    if (ec) {
      ec.addEventListener('rpa-task-error', triggerRpa)
      ec.addEventListener('phone-closed', triggerPhoneClosed)
      ec.addEventListener('phone-opened', triggerPhoneOpened)
    }
  }
}

// 关闭SSE连接
export function closeSSEEvent() {
  try {
    ec.close()
    console.log('ec.CLOSED ==>>> ', ec.CLOSED)
    ec = null
  } catch (err) {}
}

export const SUPPORTPHONEVERSION = '7.0.2'
