export default {
  groupControl: '群控系统',
  groupControlLabel: '群控系统',
  groupControlDesc: '1控多窗口同步操作',
  proxyIp: '代理IP推荐',
  proxyIpDesc: '汇集全球海量代理IP资源',
  chuhai2345: '出海资源汇集',
  chuhai2345Desc1: '2345出海导航',
  chuhai2345Desc2: '汇集优质出海资源',
  scriptMarket: '脚本市场',
  scriptMarketDesc: '脚本定制供需发布平台',
  zhiwenshouji: '指纹手机',
  zhiwenshoujiDesc: '无限生成防关联新机环境&无人直播',
  userLabel: '用户：',
  roleLabel: '身份：',
  balanceLabel: '账户余额：',
  rechargeLabel: '充值',
  getCoupon: '老用户领取8折优惠券',
  package: '经典套餐:',
  browsers: '窗口环境',
  unit: '个',
  users: '员工',
  additionUsers: '额外增加员工',
  expired: '有效期：',
  browsersCount: '总额度：',
  used: '已使用：',
  assigned: '已授权：',
  activate: '去开通',
  totalUsers: '总额度：',
  controlBrowsers: '总控数：',
  groupControlDocs: '操作指南（图文）',
  groupControlVideoDocs: '操作指南（视频）',
  qa: '常见问题',
  open: '打开',
  notice: '通知',
  docs: '文档',
  help: '使用帮助',
  issues: '常见问题',
  apiDocs: 'API对接文档（支持操作窗口及Selenium/Puppeteer自动化脚本）',
  createdTime: '发布时间：',
  close: '关闭',
  groupControlOpened: '群控系统已打开',
  groupControlOpenFail: '群控系统打开失败，请确保网络通畅后重试；如果您的系统未安装.NET4.6.1环境，点击下方按钮下载并安装，然后重试！',
  upgrade: '请升级到最新版客户端后使用',
  couponText: `
    <p>8折优惠券领取成功！请在“费用中心 -> 查看优惠券”里面查看。</p>
    <p class="q-mt-sm">可以用于开通套餐/续费套餐/变更员工等消费项目使用！</p>
  `,
  downloadApp: '群控系统，仅支持Windows系统使用，如需使用请下载Windows版客户端！',
  noExpired: '永久可用',
  downloadDonet: '[下载 .NET4.6.1]',
  currentVersionQunkongTitle: '群控系统5.X（最新版本）',
  currentVersionQunkongMsg:
    '在4.0.5版本基础上，新增支持：鼠标事件延迟同步、随机数字输入、文本仿真输入速度的区间设置等功能， 不断更新优化各类辅助功能；如因电脑配置等问题引起同步操作不稳定，请反馈给客服，我们协助您进行优化！',
  lastVersionQunkongTitle: '群控系统4.0.5（老版本）',
  lastVersionQunkongMsg:
    '在3.1.4版本基础上，新增支持：鼠标键盘同步扩展/弹出菜单，以及一些辅助性功能！不再更新优化，如需最新功能请使用最新版本！',
  oldVersionQunkongTitle: '群控系统3.1.4（老版本）',
  oldVersionQunkongMsg1: '不支持：鼠标键盘同步扩展/弹出菜单，但部分老用户使用起来更加稳定；不再更新优化，如需最新功能请使用最新版本！',
  oldVersionQunkongMsg2:
    '提示：老版本3.1.4和4.0.5，点击后，将软件包下载完毕后解压，运行“SynchronousOperation.exe”或“启动比特浏览器群控系统.bat”即可！',
  weChatExchangeGroup: '微信交流群（添加客服拉您）',
  qunkongMsg1: '如果群控系统运行异常、无法自动升级，请先删除群控系统，并在该页面重新运行！',
  qunkongMsg2: '点击下载“删除比特浏览器群控系统.bat”',
  qunkongMsg3: '，运行该文件可以快速删除群控系统！',
  loginTipsForFreeAccount:
    '为了规范免费用户使用体验，做出限制：1. 同一设备只有第1个登录的免费账号可以正常使用，其他免费账号将限制打开窗口；2. 禁止虚拟机使用免费账号，将限制打开窗口。<p class="text-red q-mt-sm">涉及到使用多个免费账号的用户请规范化使用！以上限制，付费用户不受影响！</p>',
  apiErrorLogsTitle: 'API请求报错日志',
  apiErrorLogsTips: '您的API接口请求报错太过频繁，触发安全机制导致自动退出登录，请调试API接口正常后，再重新运行您的脚本，以下是报错内容：',
  groupControlSubLabel: '（仅支持Chrome内核，不支持Firefox内核）',
  loginTipsAction: '我已知晓并同意',
  forcePasswordChangeTip:
    '为了保证您的比特浏览器账号安全，我们强烈建议您定期修改登录密码，本次请务必修改密码后重新登录比特浏览器，给您带来的不便深感歉意！',
  goChange: '去修改'
}
