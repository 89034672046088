export default {
  buyMonthComputing: '购买包月算力',
  batchDelete: '批量删除算力',
  editNameWithSeq: '修改算力名称',
  computingName: '算力名称',
  name: '名称',
  remark: '备注',
  renewalComputing: '续费算力',
  useEnvironment: '使用中的环境',
  deleteTip: '算力删除后不可恢复，确认要彻底删除算力吗？',
  editNameSuccess: '算力名称修改成功',
  enableAutoRenew:
    '确认开启自动续费吗？<p class="q-mt-sm">开启自动续费后，系统会自动检测：在您的算力到期的前3天，若您的账户有充足的余额，会自动续费1个月算力并扣相应余额！</p>',
  disableAutoRenew: '确认取消自动续费吗？<p class="q-mt-sm">取消自动续费后，系统会停止检测算力是否到期，且不会为您自动续费算力！</p>',
  selectComputing: '请选择算力',
  selectComputingType: '请选择算力类型',
  delete: '删除',
  renewPhoneTip: '注意：请尽快完成支付，确保您所选的算力、数量、续费时长、支付方式等信息填写无误，一旦下单则不能退换！',
  changeTip: '注意：请尽快完成支付，确保您所选的算力类型、机型、时长、数量、支付方式等信息填写无误，一旦下单则不能退换！',
  buyComputing: '购买算力',
  expired: '已过期',
  buySuccess: '购买成功',
  type: '算力类型'
}
