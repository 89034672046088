export default {
  browserGroup: 'Группа профилей браузера',
  add: 'Добавить',
  groupName: 'Название группы:',
  search: 'Поиск',
  toEnvironment: 'Нажмите, чтобы быстро просмотреть среду',
  toBrowser: 'Нажмите, чтобы быстро просмотреть окна группы',
  toBrowser1: 'Нажмите, чтобы быстро просмотреть устройства',
  copyID: 'Копировать ID',
  groupNamePlaceholder: 'Введите название группы',
  sortPlaceholder: 'Введите число сортировки больше или равно 0',
  sortHint: 'Примечание: Введите число, чем меньше число, тем ближе к началу порядка сортировки',
  sort: 'Сортировка',
  belongTo: 'Принадлежность',
  belongUser: 'Владеющий пользователь',
  belongUserTips:
    'После изменения "Пользователя владения" данная группа и все ее окна будут переданы другому пользователю. Пожалуйста, будьте осторожны при выполнении этой операции!',
  belongUserTipsPhone: `После изменения "Принадлежащего пользователя" группа и профили в группе будут переданы другому пользователю. Пожалуйста, будьте осторожны!`,
  belongUserTipsEnv: `После изменения "Принадлежащего пользователя" эта группа и ее среды будут переданы другому пользователю. Пожалуйста, действуйте осторожно!`,
  groupNameRuleMsg1: 'Введите название группы',
  groupNameRuleMsg2: 'Название группы не может содержать более 50 символов',
  groupNameRuleMsg3: 'Название не может содержать пробелы и знаки переноса строки',
  sortRuleMsg1: 'Введите число',
  sortRuleMsg2: 'Число сортировки должно быть от 0 до 10000',
  addGroup: 'Добавить группу',
  browserCount: 'Количество окон в группе',
  createdTime: 'Время создания',
  deleteConfirmText:
    'Вы уверены, что хотите удалить эту группу?<br/>После удаления группы восстановление будет невозможно. Будьте осторожны!',
  deleteSuccess: 'Группа успешно удалена',
  editSuccess: 'Группа успешно изменена',
  addSuccess: 'Группа успешно добавлена',
  copySuccess: 'Успешно скопировано',
  deleteGroup: 'Удалить группу',
  phoneGroup: 'Группировка облачного телефона',
  belongUserTips1:
    'После изменения "Владеющего пользователя" группа и телефоны в ней будут переданы другому пользователю. Будьте осторожны при выполнении этой операции!'
}
