export default {
  fileManag: 'Files',
  buyCloudPhone: 'Buy Phone',
  totalDevices: 'Total Devices:',
  batchOpenTelecast: 'Batch Open Live Streaming',
  goLive: 'Start Live Streaming',
  endLive: 'Stop Live Streaming',
  batchStartLive: 'Batch Start/Stop Live Streaming',
  batchEndLive: 'Batch Stop Live Streaming',
  batchUpdate: 'Batch Edit',
  batchUpdateGroup: 'Batch Update Group',
  batchUpdateProxy: 'Batch Update Proxy',
  batchUpdateRemart: 'Batch Update Remarks',
  batchRenew: 'Batch Renewal',
  updateSelectSetting: 'Modify Selected Phone Settings',
  batchAutoRenew: 'Batch Enable Auto-Renewal',
  batchAutoRenew1: 'Batch Disable Auto-Renewal',
  batchAutoRenew2: 'Batch Enable/Disable Auto-Renewal',
  uploadApkTip: 'After uploading the application, select the desired applications and click "Install"',
  sharePhone: 'Share With User',
  sharePhone1: 'Share With User',
  transformPhone: 'Transfer To User',
  transformPhone1: 'Transfer To User',
  batchDelect: 'Batch Delete Phones',
  deletePhone: 'Delete Environment',
  expirationTime: 'Expiration Time',
  status: 'Online Status',
  model: 'Model',
  edit: 'Edit Phone',
  phoneBrand: 'Phone Brand',
  equipmentModel: 'Equipment Model',
  wifyName: 'WIFI Name',
  timingRules: 'Timing Rules',
  customDeviceName: 'Custom Device Name',
  '12hour': '12 Hours',
  '24hour': '24 Hours',
  wifyNameLimit: 'WIFI name cannot exceed 50 characters',
  selectCountry: 'Select Country/Region',
  selectTimeRules: 'Select Timing Rules',
  selectNationCode: 'Select Phone Area Code',
  enterWify: 'Enter WIFI Name',
  selectPhone: 'Select Phone',
  updateConfig: 'Edit Phone',
  installApps: 'Install Apps',
  batchInstall: 'Batch Install',
  uninstallApp: 'Uninstall Apps',
  batchUninstall: 'Batch Uninstall',
  batchApp: 'Bulk Install/Uninstall App',
  renew: 'Renew',
  restart: 'Restart',
  batchRestart: 'Bulk Restart',
  batchScreenshot: 'Bulk Screenshots',
  rootSwitch: 'Enable ROOT Access',
  batchRoot: 'Bulk Enable ROOT Access',
  autoRenew1: 'Disable Auto-Renewal',
  autoRenew2: 'Enable/Disable Auto-Renewal',
  autoRenew: 'Enable Auto-Renewal',
  autoRenewOpen: 'Auto-Renewal enabled',
  startStopLive: 'Start/Stop Live Streaming',
  restoreFactory: 'Factory Reset',
  batchRestoreFactory: 'Bulk Factory Reset',
  cancelCommonly: 'Bulk Unset From Favorites',
  setCommonly: 'Set As Favorite In Bulk',
  batchOpenClose: 'Bulk Open/Close',
  batchOpen: 'Bulk Open',
  batchClose: 'Bulk Close',
  batchUpdateConfig: 'Batch Modify Phone Configurations',
  batchUpdateTips:
    ' To modify, check the leftmost checkbox of the item, then edit its configuration value. All Phones will be updated accordingly!',
  area: 'Area',
  renewDevice: 'Renew Phone',
  renewTime: 'Renewal Period',
  renewPhoneTip1:
    'Note: Please complete the payment as soon as possible, ensuring that the selected region, model, duration, quantity, payment method, etc. are filled in correctly. Once the order is placed, it cannot be refunded or exchanged!',
  renewPhoneTip:
    'Note: Please complete the payment as soon as possible, ensuring that the selected device, quantity, renewal duration, payment method, etc. are filled in correctly. Once the order is placed, it cannot be refunded or exchanged!',
  order: 'Order Verification',
  buyMonth: 'Duration:',
  orderMoney: 'Total Order Amount:',
  payableAmount: 'Total Amount Payable:',
  submitOrder: 'Submit Order',
  confirmPayment: 'Confirm Payment',
  price: 'Price',
  selectedDevice: 'Selected Device:',
  fileUpload: 'File Upload',
  fileDele: ' File Deletion',
  openChecked: 'Open Selected',
  closeChecked: 'Close Selected Phones',
  closeAll: 'Close All Phones',
  deleteCompletely: 'Delete Selected Phones',
  transformTitle: 'Transfer Phones',
  selectModel: 'Choose model',
  link: 'Link',
  linking: 'linking',
  fileSize: 'File Size',
  uploadTime: 'Upload Time',
  uploadFile: 'Upload File',
  uploadApp: 'Upload Application',
  deleteFile: 'Delete File',
  all: 'All',
  app: 'APP',
  file: 'Files',
  doc: 'Documents',
  img: 'Images',
  other: 'Other',
  batchUploadFile: 'Batch Upload/Delete Files',
  batchUpload: 'Bulk Upload Files',
  screenshot: 'Execute screenshot',
  batchDel: 'Batch Delete Files',
  nameTip: 'Please name the device for future daily management operations!',
  deleteTip: 'Phone cannot be recovered after deletion. Are you sure you want to permanently delete the phone?',
  deleteSharePhone: 'Confirm deletion of the shared phone?',
  groupControlSync: 'Group Control Sync',
  groupControlSync1: 'Sync',
  groupSelectTips: `Note: If you select a group from another account, the phone will be transferred to that account's group (ownership of the phone will belong to the other account).`,
  setSuccess: 'Success.',
  openStatus: 'Link Status',
  open: 'Open',
  close: 'Close',
  yes: 'Yes',
  no: 'No',
  remarkPlaceholder: 'Enter remarks',
  allPhoneClosed: 'All phones have been turned off',
  closePhone: 'The phone has been turned off',
  checkedHasClosed: 'The selected phone has been turned off',
  ruleNameMsg: 'Name can have up to 50 characters',
  editShareGroupMsg: 'The phone shared cannot be modified in groups',
  editNameSuccess: 'Device name modified successfully',
  renewal: 'Expiring Soon',
  sortPlaceholder: 'Please enter a sort number greater than or equal to 0',
  editSeq: 'Edit Seq',
  ipCheckTips1:
    '1. Different IP query channels may result in some detection discrepancies! If the proxy detection result does not match the actual information, you can adjust the IP query channel to ensure the accuracy of the detection result!',
  ipCheckTips2:
    '2. If the fingerprint settings of this phone environment include IP-based generation for language, timezone, and geographical location, please ensure that the detection results of the selected IP query channel match the actual information, otherwise it may cause errors in the auto-generation in several places mentioned above!',
  hostTips: 'Automatically split when the cursor leaves for this template. <br/>Proxy Type://Host:Port:Username:Password',
  name: 'Name',
  phoneEpire: 'cloud phone expired',
  setGroupMain: 'Set Main Control',
  mainGroup: 'Main Control',
  controlled: 'Controlled',
  qunkongTip: 'Two phones must be open to set up group control',
  uninstall: 'Uninstall',
  uploadPhone: 'Upload Mobile Phone',
  alreadyUpload: ' has already been uploaded',
  maxFile: 'File upload cannot exceed 1GB',
  deleteFileTip: 'Are you sure you want to delete this file? Once deleted, it cannot be recovered. Please proceed with caution!',
  isOpening: 'Opening the phone',
  batchOpenMax: 'At most, open 20 units at a time',
  uploadTip:
    'After uploading the file, select the files you want to upload, then click "Upload to Phone". The uploaded files will be in the Downloads directory on your phone',
  controlType: 'Group control not set',
  controlType1: 'Group control set',
  actionMore: 'Load more',
  adControl: 'Add group control',
  delControl: 'Remove group control',
  createControlTip: 'To create group control, select 2 or more phones',
  releaseControl: 'Cancel All Control',
  controlDeviceTip: '{arg} master control device',
  slaveControlDeviceTip: '{arg} slave control device',
  noEditPermission: 'No editing permissions for phones. Contact admin to enable',
  openSelect: 'Open Selected',
  selectAddControl: 'Add selected to group control',
  selectRemoveControl: 'Remove selected from group control',
  cancelControlClosePhone: 'Close phone on cancel.',
  addContrlOpenPhone: 'Open phone on join.',
  newPhone: 'Generate New Phone',
  reInstall: 'Reinstall System',
  uploadSizeTip:
    'Each account has {size}GB of cloud storage space. Files uploaded here are uploaded to the cloud, not directly to the phone profile.',
  controlTip:
    'Supports up to 50 cloud phones for group control. During group control, please keep all cloud phone windows and screens consistent in size and display.',
  deviceOverdueTip:
    "The current account is not verified as individual/business, so all phones are limited to 3-day usage, now expired. Please verify your identity as individual/business in the 'Personal Center' to continue!",
  confirmBuy: 'Confirm Buy',
  buyDeviceTip: "Without verification, phones buy are limited to 3 days' use. Verify in 'Personal Center' after buy. Proceed with buy?",
  emptyFileTip: 'Empty files are prohibited from uploading',
  versionTip:
    "The current client version does not support opening the mobile environment. Please upgrade to the latest client version and try again. Click on 'Discover New Version' at the top to update.",
  updateCoreTip: 'Please update {arg} kernel usage',
  unit: '',
  香港: 'Hong Kong',
  江苏: 'Jiangsu',
  大陆: 'Mainland China',
  新加坡: 'Singapore',
  region: 'Region',
  enterRegion: 'Please select a region',
  buyMaxTip: 'Maximum of 50 units per single buy',
  recoverTip:
    'Performing the "Restore Factory Settings" operation will erase all previously uploaded apps, documents, images, and other files, and they cannot be recovered. Do you wish to continue?',
  reinstallTip:
    'After performing the "system reinstall" operation, you will need to restart your phone. The applications, documents, images, and other files you previously uploaded will be deleted and cannot be recovered. Do you want to continue?',
  randomPhoneTip:
    'Performing the "One-click new device" operation will generate new fingerprint information randomly, such as brand name, device model, IMEI, Wi-Fi, etc. Please proceed with caution! It is also recommended not to repeatedly click the "One-click new device" button! Do you wish to continue?',

  randomPhoneTip1:
    "Clicking 'One-click New Device' will generate new fingerprint info randomly. Avoid continuous clicks as it will limit further actions. Continue?",

  quantity: 'Quantity'
}
