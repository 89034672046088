import { request } from 'src/boot/axios'
import { getHash } from 'src/utils/jssha'
import { DeleteCacheMap } from 'utils/cache'

// 缓存请求的promise
const cachePromiseMap = new Map()

export function updatePreference(data) {
  return request({ url: '/v1/globalsetting', method: 'put', data })
}

export function getPreference(userId) {
  return request({
    url: `/v1/globalsetting/${userId}`,
    method: 'get',
    cacheTime: 24,
    cacheAlias: DeleteCacheMap.Preference
  })
}
// 查询全局代理信息
export function getAllGlobalProxy(userId) {
  return request({ url: '/v1/globalproxysetting/findByUserId', method: 'post', data: { userId } })
}

// 根据用户ID和代理类型查询全局代理信息
export function getGlobalProxy(data) {
  return request({
    url: '/v1/globalproxysetting/findByUserIdAndProxyType',
    method: 'post',
    data
  })
}
export function findByUserIdAndProxyTypelist(data) {
  return request({
    url: '/v1/globalproxysetting/findByUserIdAndProxyTypelist',
    cacheTime: 24,
    cacheAlias: 'proxyTypelist',
    method: 'post',
    data
  })
}

// 修改全局代理设置
export function setGlobalProxy(data) {
  return request({ url: '/v1/globalproxysetting', method: 'put', data })
}

// 查询全局指纹偏好
export function getFingerprintPreference(userId) {
  return request({
    url: '/v1/globalbrowsersetting/' + userId,
    method: 'get',
    cacheTime: 24,
    cacheAlias: DeleteCacheMap.Fingerprint
  })
}

// 设置全局指纹偏好
export function updateFingerprintPreference(data) {
  return request({ url: '/v1/globalbrowsersetting', method: 'put', data })
}

// 获取全部全局设置
export function getGlobalSettings() {
  return request({ url: '/v1/globalvaluesetting/getByUserId', method: 'post' })
}
export function getValueList(data) {
  const config = {
    url: '/v1/globalvaluesetting/getValueList',
    method: 'post',
    data
  }
  const hashKey = getHash(JSON.stringify(config))

  if (cachePromiseMap.has(hashKey)) return cachePromiseMap.get(hashKey)

  const p = request(config)
  cachePromiseMap.set(hashKey, p)
  // 2秒后自动删除
  setTimeout(() => {
    cachePromiseMap.delete(hashKey)
  }, 2000)

  return p
}

// 并发请求时，返回第一次的请求结果
export function getGlobalValue(data) {
  const config = {
    url: '/v1/globalvaluesetting/getGlobalValue',
    method: 'post',
    data,
    cacheTime: 24,
    cacheAlias: data.globalValueType
  }
  const hashKey = getHash(JSON.stringify(config))

  if (cachePromiseMap.has(hashKey)) return cachePromiseMap.get(hashKey)

  const p = request(config)
  cachePromiseMap.set(hashKey, p)
  // 2秒后自动删除
  setTimeout(() => {
    cachePromiseMap.delete(hashKey)
  }, 2000)

  return p
}

// 修改全局value设置
export function setGlobalValueSetting(data) {
  return request({ url: '/v1/globalvaluesetting', method: 'put', data })
}
