export default {
  fileManag: 'Файлы',
  buyCloudPhone: 'Купить облачный телефон',
  totalDevices: 'Всего устройств:',
  batchOpenTelecast: 'Массовое включение прямой трансляции',
  goLive: 'Начать прямую трансляцию',
  endLive: 'Завершить прямую трансляцию',
  batchStartLive: 'Массовое начало/завершение прямой трансляции',
  batchEndLive: 'Массовое завершение прямой трансляции',
  batchUpdate: 'Массовое изменение',
  batchUpdateGroup: 'Массовое изменение групп',
  batchUpdateProxy: 'Массовое обновление прокси',
  batchUpdateRemart: 'Массовое изменение заметок',
  batchRenew: 'Массовое продление',
  updateSelectSetting: 'Изменить настройки выбранного облачного телефона',
  batchAutoRenew: 'Массовое включение автопродление',
  batchAutoRenew1: 'Массовое отключение автопродление',
  batchAutoRenew2: 'Массовое вкл./откл. автопродление',
  autoRenew: 'Включить автопродление',
  autoRenew1: 'Откл. автопродление',
  autoRenew2: 'Вкл./Выкл. автопродление',
  autoRenewOpen: 'Включено Автопродление.',
  sharePhone: 'Поделиться с пользователем',
  sharePhone1: 'Поделиться с пользователем',
  transformPhone: 'Передать пользователю',
  transformPhone1: 'Передача облачного телефона другим пользователям',
  batchDelect: 'Массовое удаление телефонов',
  uploadApkTip: 'После загрузки приложения выберите нужные приложения и нажмите "Установить"',
  deletePhone: 'Удалить',
  expirationTime: 'Срок истечения',
  status: 'Статус онлайн',
  model: 'Модель',
  edit: 'Редактировать облачный телефон',
  phoneBrand: 'Марка телефона',
  equipmentModel: 'Модель оборудования',
  wifyName: 'Имя WIFI',
  timingRules: 'Правила тайминга',
  customDeviceName: 'Пользовательское имя устройства',
  '12hour': '12 часов',
  '24hour': '24 часа',
  wifyNameLimit: 'Имя WIFI не может превышать 50 символов',
  selectCountry: 'Выберите страну/регион',
  selectTimeRules: 'Выберите правила тайминга',
  selectNationCode: 'Выберите территориальный код телефона',
  enterWify: 'Введите имя WIFI',
  selectPhone: 'Выберите облачный телефон',
  updateConfig: 'Изменить настройки облачного телефона',
  installApps: 'Установить приложения',
  batchInstall: 'Массовая установка',
  uninstallApp: 'Удалить приложения',
  batchUninstall: 'Массовое удаление',
  batchApp: 'Массовая установка/удаление приложений',
  renew: 'Продлить',
  restart: 'Перезагрузить',
  batchRestart: 'Массовая перезагрузка',
  batchScreenshot: 'Массовое создание снимков экрана',
  rootSwitch: 'Включить ROOT-права',
  batchRoot: 'Массовое включение ROOT-прав',
  startStopLive: 'Начать/завершить прямую трансляцию',
  restoreFactory: 'Сброс на заводские настройки',
  batchRestoreFactory: 'Массовое сброс к заводским настройкам',
  cancelCommonly: 'Массовое удаление из Избранного',
  setCommonly: 'Установить Как Избранное Пакетно',
  batchOpenClose: 'Массовое открытие/закрытие',
  batchOpen: 'Массовое открытие',
  batchClose: 'Массовое закрытие',
  batchUpdateConfig: 'Массовое изменение настроек облачного телефона',
  batchUpdateTips:
    'Для изменения отметьте флажок слева от пункта и измените его значение. Данный пункт на всех облачных телефонах будет обновлен соответственно!',
  area: 'Регион дата-центра',
  renewDevice: 'Продлить абонентскую плату за облачный телефон',
  renewTime: 'Срок продления',
  renewPhoneTip1:
    'Внимание: Произведите оплату как можно скорее, убедившись, что выбранные регион, модель, срок продления, количество, способ оплаты и т. д. указаны правильно. После размещения заказа будет невозможно отменить или обменять!',
  renewPhoneTip:
    'Внимание: Произведите оплату как можно скорее, убедившись, что выбранное устройство, количество, срок продления, способ оплаты и т. д. указаны правильно. После размещения заказа будет невозможно отменить или обменять!',
  order: 'Проверка заказа',
  buyMonth: 'Продолжительность:',
  orderMoney: 'Общая сумма заказа:',
  payableAmount: 'Сумма к оплате: ',
  submitOrder: 'Подтвердить заказ',
  confirmPayment: 'Подтвердить оплату',
  price: 'Цена',
  selectedDevice: 'Выбранное устройство:',
  fileUpload: 'Загрузка файла',
  fileDele: ' Удаление файла',
  openChecked: 'Открыть выбранные облачные телефоны',
  closeChecked: 'Закрыть выбранные облачные телефоны',
  closeAll: 'Закрыть все облачные телефоны',
  deleteCompletely: 'Удалить выбранные облачные телефоны',
  transformTitle: 'Передать облачные телефоны',
  selectModel: 'Выбрать модель',
  link: 'Ссылка',
  linking: 'В ссылке',
  fileSize: 'Размер файла',
  uploadTime: 'Время загрузки',
  uploadFile: 'Загрузить файл',
  uploadApp: 'Загрузить приложение',
  deleteFile: 'Удалить файл',
  all: 'Все',
  app: 'Приложения',
  file: 'Файлы',
  doc: 'Документы',
  img: 'Изображения',
  other: 'Другие',
  batchUploadFile: 'Массовая загрузка/удаление файлов',
  batchUpload: 'Массовая загрузка файлов',
  screenshot: 'Выполнить снимок экрана',
  batchDel: 'Массовое удаление файлов',
  nameTip: 'Назовите устройство для удобства будущих ежедневных операций по управлению!',
  deleteTip: 'После удаления облачного телефона его нельзя будет восстановить. Вы уверены, что хотите удалить облачный телефон навсегда?',
  deleteSharePhone: 'Подтвердить удаление общего облачного телефона?',
  groupControlSync: 'Синхронизация группового контроля',
  groupControlSync1: 'Групповой контроль',
  groupSelectTips:
    'Внимание: Если выбрана группа из другого аккаунта, облачный телефон будет перемещен в группу этого аккаунта (право собственности на облачный телефон будет принадлежать другому аккаунту).',
  setSuccess: 'Успешно выполнено',
  openStatus: 'Статус ссылки',
  open: 'Открыть',
  close: 'Закрыть',
  yes: 'Да',
  no: 'Нет',
  remarkPlaceholder: 'Заполните комментарии облачного телефона',
  allPhoneClosed: 'Все облачные телефоны были отключены',
  closePhone: 'Облачный телефон был отключен',
  checkedHasClosed: 'Выбранный облачный телефон был отключен',
  ruleNameMsg: 'Наименование до 50 символов.',
  editShareGroupMsg: 'Для совместно используемого облачного телефона невозможно изменить группу',
  editNameSuccess: 'Имя устройства успешно изменено',
  renewal: 'Скоро потребуется продление',
  sortPlaceholder: 'Введите число больше или равно 0',
  editSeq: 'Изменить порядковый номер',
  ipCheckTips1:
    '1. Результаты могут отличаться в разных источниках запросов IP! При несоответствии результатов проверки прокси реальным данным можно изменить источник запросов IP для точности!',
  ipCheckTips2:
    '2. Если в настройках отпечатка включена генерация на основе IP для языка, часового пояса и местоположения, убедитесь, что результаты проверки соответствуют фактическим данным, иначе возможны ошибки автогенерации в указанных местах!',
  hostTips:
    'Тип_прокси://хост:порт:аккаунт:пароль, введите этот формат, после того как курсор покинет поле, произойдет автоматическая разбивка',
  name: 'Название устройства',
  phoneEpire: 'Истекший срок облачного телефона',
  setGroupMain: 'Установить главный контроль',
  mainGroup: 'Главный контроль',
  controlled: 'Контролируемый',
  qunkongTip: 'Для настройки группового контроля нужно два открытых облачных телефона',
  uninstall: 'Удалить',
  uploadPhone: 'Загрузить на телефон',
  alreadyUpload: 'Уже был загружен',
  maxFile: 'Загрузка файла не может превышать 1 ГБ',
  isOpening: 'Открытие телефона',
  deleteFileTip:
    'Вы уверены, что хотите удалить этот файл? После удаления его нельзя будет восстановить. Пожалуйста, действуйте осторожно!',
  batchOpenMax: 'Максимальное открытие 20 устройств за один раз',
  uploadTip:
    'После загрузки файлов отметьте нужные файлы и нажмите "Загрузить на телефон". Загруженные файлы будут сохранены в папке Downloads на вашем телефоне',
  controlType: 'Без настроенного группового контроля',
  controlType1: 'С настроенным групповым контролем',
  actionMore: 'Загрузить еще',
  adControl: 'Добавить групповой контроль',
  delControl: 'Отменить групповой контроль',
  createControlTip: 'Для создания группового контроля выберите 2 или более телефонов',
  releaseControl: 'Отменить весь групповой контроль',
  controlDeviceTip: 'Устройство главного контроля {arg}',
  slaveControlDeviceTip: 'Контролируемое устройство{arg}',
  noEditPermission: 'Нет прав на редактирование облачных телефонов. Обратитесь к администратору для получения',
  openSelect: 'Открыть выбранное',
  selectAddControl: 'Добавить выбранное в групповой контроль',
  selectRemoveControl: 'Удалить выбранное из группового контроля',
  cancelControlClosePhone: 'При отмене группового контроля закрыть окно облачного телефона',
  addContrlOpenPhone: 'При добавлении группового контроля открыть окно облачного телефона',
  newPhone: 'Быстрое создание нового телефона',
  reInstall: 'Переустановить систему',
  uploadSizeTip:
    'Каждый аккаунт имеет {size} ГБ облачного хранилища. Файлы, загруженные здесь, загружаются в облако, а не непосредственно в профиль телефона.',
  controlTip:
    'Поддержка до 50 облачных телефонов для группового контроля. Во время группового контроля поддерживайте одинаковый размер окон и экранов всех облачных телефонов.',
  deviceOverdueTip:
    "Текущий аккаунт не прошел проверку как физическое/юридическое лицо. Облачные телефоны доступны только 3 дня, и срок их истек. Пожалуйста, пройдите проверку в 'Личном кабинете', чтобы продолжить использование!",
  confirmBuy: 'Подтвердить покупку',
  buyDeviceTip:
    "Без проверки облачные телефоны можно использовать только 3 дня. Проверьте после покупки в 'Личном кабинете'. Продолжить покупку?",
  emptyFileTip: 'Запрещена загрузка пустых файлов',
  versionTip:
    "Текущая версия клиентского приложения не поддерживает функцию облачного телефона. Обновите приложение до последней версии. Нажмите на 'Обнаружить новую версию' в верхней части для обновления.",
  updateCoreTip: 'Обновите {arg} ядра.',
  unit: '',
  香港: 'Гонконг',
  江苏: 'Цзянсу',
  大陆: 'Материк',
  新加坡: 'Сингапур',
  region: 'Регион',
  enterRegion: 'Выберите регион',
  buyMaxTip: 'Максимум 50 устройств за одну покупку',
  recoverTip:
    'После выполнения операции "Сброс до заводских настроек" все ранее загруженные приложения, документы, изображения и другие файлы будут удалены и не могут быть восстановлены. Желаете продолжить?',
  reinstallTip:
    'После выполнения операции "Переустановка системы" вам нужно будет перезагрузить телефон. Приложения, документы, изображения и другие файлы, которые вы загрузили ранее, будут удалены и не подлежат восстановлению. Вы хотите продолжить?',
  randomPhoneTip:
    'Выполнение операции "Быстрое создание нового телефона" приведет к произвольноиу созданию новых данных отпечатка, как название марки, модель устройства, IMEI, Wi-Fi и т. д. Будьте осторожны! Также рекомендуется не нажимать кнопку "Быстрое создание нового устройства" повторно! Желаете продолжить?',
  randomPhoneTip1:
    "Нажатие 'Одним нажатием создать новое устройство' случайно сгенерирует новые отпечатки. Избегайте непрерывных нажатий, чтобы не превысить лимит. Продолжить?",
  quantity: 'Количество устройств'
}
