export default {
  groupControl: 'Групповой контроль',
  groupControlLabel: 'Групповой контроль',
  groupControlDesc: 'Синхронизация единого управления многими окнами',
  proxyIp: 'Рекомендация прокси-IP',
  proxyIpDesc: 'Собираются огромные ресурсы прокси-IP со всего мира',
  chuhai2345: 'Chuhai2345',
  chuhai2345Desc1: '2345 навигация по международным ресурсам',
  chuhai2345Desc2: 'Сбор высококачественных международных ресурсов',
  scriptMarket: 'Рынок сценариев',
  scriptMarketDesc: 'Платформа для настройки сценариев и обеспечения спроса и предложения',
  zhiwenshouji: 'Телефон с отпечатком',
  zhiwenshoujiDesc: 'Безграничная генерация сред с новыми устройствами, несвязанными между собой, и автоматическим ведением стримов',
  userLabel: 'Пользователь:',
  roleLabel: 'Статус:',
  balanceLabel: 'Баланс счета:',
  rechargeLabel: 'Пополнить',
  getCoupon: 'Получение купона со скидкой 20% для старых пользователей',
  package: 'Классический пакет:',
  browsers: 'Окна',
  unit: 'шт.',
  users: 'Сотрудники',
  additionUsers: 'Дополнительные сотрудники:',
  expired: 'Срок действия:',
  browsersCount: 'Общая квота:',
  used: 'Использовано:',
  assigned: 'Авторизовано:',
  activate: 'Активировать',
  totalUsers: 'Общая квота:',
  controlBrowsers: 'Общее контрольное число:',
  groupControlDocs: 'Руководство (Графическое)',
  groupControlVideoDocs: 'Руководство (Видео)',
  qa: 'Часто задаваемые вопросы',
  open: 'Открыть',
  notice: 'Уведомление',
  docs: 'Документы',
  help: 'Центр помощи',
  issues: 'Часто задаваемые вопросы',
  apiDocs: 'API документация (поддерживает операционные окна и автоматические сценарии Selenium/Puppeteer)',
  createdTime: 'Дата создания:',
  close: 'Закрыть',
  groupControlOpened: 'Система группового контроля открыта',
  groupControlOpenFail:
    'Не удалось открыть систему группового контроля, убедитесь, что доступ к сети свободен, и повторите попытку; если в вашей системе не установлена среда .NET 4.6.1, нажмите кнопку ниже, чтобы загрузить и установить, а затем повторите попытку!',
  upgrade: 'Пожалуйста, обновите клиентскую программу до последней версии для использования данной функции',
  couponText:
    ' <p>Купон со скидкой 20% успешно получен! Проверьте в "Центре оплаты -> Просмотр купонов".</p> <p class="q-mt-sm">Он может быть использован для оплаты подписки, продления срока действия, добавления сотрудников и других платежей!</p>',
  downloadApp:
    'Система группового контроля доступна только для использования в операционной системе Windows. Скачайте клиентскую программу для Windows, чтобы использовать ее!',
  noExpired: 'Постоянный доступ',
  weChatExchangeGroup: 'Группа общения в WeChat (обратитесь в клиентский сервис, чтобы вас добавили)',
  downloadDonet: '[Скачать .NET 4.6.1]',
  lastVersionQunkongTitle: 'Система группового контроля 4.0.5 (старая версия)',
  currentVersionQunkongTitle: 'Система группового контроля 5.X (последняя версия)',
  currentVersionQunkongMsg:
    'На базе версии 4.0.5 появилась новая поддержка: синхронизация с задержкой события мыши, ввод случайных чисел, настройка интервала скорости ввода текстовой симуляции и другие функции. Постоянно обновляются и оптимизируются различные вспомогательные функции; если работа синхронизации нестабильна из-за конфигурации компьютера и других проблем, свяжитесь со службой поддержки клиентов, мы поможем с оптимизацией!',
  lastVersionQunkongMsg:
    'На основе версии 3.1.4 появилась новая поддержка: расширение для синхронизации мыши и клавиатуры / всплывающее меню и некоторые вспомогательные функции! Больше никаких обновлений и оптимизаций, если вам нужны новейшие функции, используйте последнюю версию!',
  oldVersionQunkongTitle: 'Система группового контроля 3.1.4 (старая версия)',
  oldVersionQunkongMsg2:
    "Примечание: Для старых версий 3.1.4 и 4.0.5 после нажатия, как только пакет программного обеспечения будет загружен и извлечен, запустите 'SynchronousOperation.exe' или 'Run BitBrowser Group Control System.bat', чтобы начать!",
  oldVersionQunkongMsg1:
    'Не поддерживается: расширения синхронизации мыши и клавиатуры/всплывающие меню, но некоторые давние пользователи находят его более стабильным; больше нет обновлений и оптимизаций, для получения последних функций, используйте последнюю версию!',
  qunkongMsg1:
    'Если система группового контроля работает некорректно, не может выполнить автоматическое обновление, пожалуйста, удалите систему группового контроля и выполните повторный запуск на данной странице!',
  qunkongMsg2: 'Нажмите и скачайте "Delete BitBrowser Group Control.bat"',
  qunkongMsg3: ',Запустите этот файл, чтобы быстро удалить систему группового контроля!',
  loginTipsForFreeAccount:
    'Для стандартизации опыта использования бесплатных аккаунтов ограничено количество устройств, которые могут быть подключены к одному бесплатному аккаунту, до одного. Это не касается оплативших услуги пользователей. Пользователи, использующие несколько бесплатных аккаунтов, должны использовать их стандартизированно. <p class="text-red q-mt-sm">Внимание: Только первый вошедший в систему бесплатный аккаунт на одном устройстве может использоваться нормально; другие бесплатные аккаунты будут ограничены в возможности открытия окон.</p>',
  apiErrorLogsTitle: 'Журнал ошибок запросов API',
  apiErrorLogsTips:
    'Ваши запросы к API вызывают слишком частые ошибки, активируя механизм безопасности и автоматически выходя из системы. Пожалуйста, выполните отладку интерфейса API и запустите свой скрипт снова после того, как он заработает нормально. Вот текст ошибки：',
  groupControlSubLabel: '(Только Chrome, не Firefox)',
  loginTipsAction: 'Я признаю и соглашаюсь.',
  forcePasswordChangeTip:
    'Чтобы обеспечить безопасность вашей учетной записи в BitBrowser, мы настоятельно рекомендуем вам регулярно менять пароль для входа. На этот раз обязательно смените пароль и снова войдите в BitBrowser. Приносим извинения за возможные неудобства!',
  goChange: 'Изменить'
}
