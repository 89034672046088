<template>
  <bit-dialog :title="$t('common.upgradeAvailable')" :visible="showUpdateDialog" :width="570" @update:visible="onClose" no-route-dismiss>
    <div class="version-info theme-version-info">
      <h4>{{ $t('common.clientVersion') }}v{{ updateInfo.version }}</h4>
      <h4 class="q-mt-xs">{{ $t('common.releaseNote') }}</h4>
      <ul>
        <li v-for="(item, index) of updateInfo.releaseNotes" :key="`Release_Note_${index}`">{{ item }}</li>
      </ul>
    </div>
    <q-linear-progress size="30px" :value="percent / 100" stripe color="primary" class="q-mt-sm" v-show="showProgress">
      <div class="absolute-full flex flex-center">
        <q-badge color="white" text-color="primary" :label="progressLabel" />
      </div>
    </q-linear-progress>
    <div class="text-red q-mt-sm" v-if="errMsg" v-html="errMsg"></div>
    <template v-slot:footer>
      <div class="row">
        <q-checkbox
          v-model="ignoreThisVersionFlag"
          @update:model-value="checkIgnoreVersion"
          dense
          :label="$t('common.ignoreUpdate')"
          color="primary"
        />
        <q-space></q-space>
        <div v-if="isMac">
          <q-btn
            no-caps
            :label="$t('common.toDownlaod')"
            unelevated
            style="background: #ececec"
            class="q-mr-sm theme-cancel-btn"
            @click="toDownlaod"
          ></q-btn>
        </div>
        <div class="text-right row items-center" v-else>
          <q-btn
            no-caps
            :label="$t('common.downloadBackgroud')"
            unelevated
            class="q-mr-sm theme-cancel-btn"
            @click="backDownload"
            v-show="!showProgress && !showRetry"
          ></q-btn>
          <q-btn
            no-caps
            :label="$t('common.upgradeNow')"
            unelevated
            color="primary"
            @click="downloadNow"
            v-show="!showProgress && !showRetry"
          ></q-btn>
          <div class="text-orange q-mr-md text-left" v-show="showProgress">{{ $t('common.upgradeTips') }}</div>
          <q-btn no-caps :label="$t('form.retry')" unelevated color="primary" @click="retry" v-show="showRetry" class="q-mr-sm"></q-btn>
          <q-btn
            no-caps
            :label="$t('common.install')"
            unelevated
            color="primary"
            @click="quitAndInstall"
            v-show="showProgress"
            :disable="percent < 100"
          ></q-btn>
        </div>
      </div>
    </template>
  </bit-dialog>
</template>
<script>
import { defineComponent } from 'vue'
import { Cookies, SessionStorage, LocalStorage } from 'quasar'
import BitDialog from 'components/BitDialog'
import { mapGetters } from 'vuex'
import { appQuitManually, getReleaseNotes } from 'api/user'
import debounce from 'lodash/debounce'
import { getDomain, compareVersion } from 'utils/tools'
import { closeSSEEvent, SUPPORTPHONEVERSION } from './utils/clientMessage'
import { updatePhoneStatus } from 'utils/closePhone'
const IGNOREVERSIONKEY = 'hasNoUpdate'
export default defineComponent({
  name: 'App',
  components: { BitDialog },
  data() {
    return {
      ignoreThisVersionFlag: false,
      updateInfo: {},
      showRetry: false,
      clientInfo: {},
      errMsg: '',
      clientVersion: '',
      isMac: window.isMac
    }
  },
  computed: {
    showProgress() {
      return this.percent > 0
    },
    progressLabel() {
      return this.percent.toFixed(2) + '%'
    },
    ...mapGetters({
      showUpdateDialog: 'client/showUpdateDialog',
      isInBitBrowser: 'client/isInBitBrowser',
      percent: 'client/downloadPercent',
      userInfo: 'user/userInfo',
      openedPhones: 'client/openedPhones'
    }),
    isOfficial() {
      return location.hostname.endsWith('bitbrowser.cn') || location.hostname.endsWith('bitbrowser.net') || process.env.DEV
    },
    locale() {
      return this.$i18n.locale
    },
    upper702() {
      return !!this.clientVersion && compareVersion(SUPPORTPHONEVERSION, this.clientVersion) > -1
    }
  },
  watch: {
    showUpdateDialog(e) {
      // 展示数据的时候并且没有请求过才请求
      if (e) {
        if (this.clientInfo.version) {
          if (this.ignoreThisVersion(this.clientInfo.version)) {
            this.ignoreThisVersionFlag = true
          }
          // 服务端取升级日志
          getReleaseNotes(this.clientInfo.version).then(res => {
            if (!res.upgradeContent) return
            this.clientInfo.releaseNotes = res.upgradeContent.split('\n')
            this.updateInfo = this.clientInfo
          })
        }
      }
    }
  },
  created() {},
  mounted() {
    if (this.isInBitBrowser) {
      window.bitClientMain.onUpdateAvailable(info => {
        this.clientInfo = info
        this.$store.commit('client/UPDATE_AVAILABLE', true)

        if (!this.userInfo.updateAvailable) return // 此用户不展示升级时，直接返回

        // 每次启动时弹出一次，然后6小时内只弹出一次
        if (SessionStorage.has('UpdateDialogSession') && Cookies.has('APP_Update_Available')) return
        SessionStorage.set('UpdateDialogSession', '1')

        if (this.ignoreThisVersion(info.version)) return
        this.$nextTick().then(() => {
          this.$store.commit('client/SHOW_UPDATE_DIALOG', true)
        })
      })

      window.bitClientMain.onDownloadProgress(info => {
        this.$store.commit('client/UPDATE_PERCENT', info.percent)
        this.showRetry = false
        this.errMsg = ''
      })
      window.bitClientMain.onUpdateDownloaded(info => {
        this.$store.commit('client/SHOW_UPDATE_DIALOG', true)
        this.$store.commit('client/UPDATE_PERCENT', 100)
      })
      window.bitClientMain.onUpdateApply(() => {
        this.$q.loading.show({
          message: this.$t('common.applyingUpdates')
        })
      })
      // 下载出错
      window.bitClientMain.onUpdateError(msg => {
        if (msg === '更新遇到错误，请重新下载，或者重启软件后更新') return

        let permissionMsg = this.$t('common.updateError')

        this.$q.loading.hide()

        if (this.showUpdateDialog) {
          this.errMsg = msg.includes('文件写入失败') || msg.includes('解压升级文件失败') ? permissionMsg : msg
          this.showRetry = true
        } else {
          if (msg.includes('文件写入失败') || msg.includes('解压升级文件失败')) {
            this.$q.notify({
              type: 'negative',
              message: permissionMsg,
              timeout: 8000,
              html: true
            })
          } else {
            this.$q.notify({ type: 'negative', message: msg, timeout: 2000 })
          }
          this.$store.commit('client/UPDATE_PERCENT', 0)
        }
      })
    }
  },
  methods: {
    ignoreThisVersion(version) {
      return LocalStorage.getItem(IGNOREVERSIONKEY) === version
    },
    checkIgnoreVersion(val) {
      if (val) {
        LocalStorage.set(IGNOREVERSIONKEY, this.updateInfo.version)
      } else {
        LocalStorage.remove(IGNOREVERSIONKEY)
      }
    },

    onClose() {
      this.$store.commit('client/SHOW_UPDATE_DIALOG', false)
      // 关闭时设置6h内不再打开
      Cookies.set('APP_Update_Available', '1', {
        expires: '6h'
      })
    },
    backDownload() {
      this.onClose()
      this.downloadNow()
    },
    downloadNow: debounce(function () {
      window.bitClientMain.downloadUpdate()
    }, 300),
    // 打开官网下载
    toDownlaod() {
      let url = getDomain(this.$i18n.locale) + '/download'
      window.bitClientMain.openByDefaultBrowser(url)
    },
    retry() {
      this.$store.commit('client/UPDATE_PERCENT', 0)
      this.downloadNow()
    },
    quitAndInstall: debounce(async function () {
      this.$store.commit('client/SHOW_UPDATE_DIALOG', false)
      try {
        await window.bitClientMain.closeAllBrowsers()
        if (this.upper702) {
          await updatePhoneStatus()
        }
        await appQuitManually()
        closeSSEEvent()
      } catch {}
      window.bitClientMain.quitAndInstall()

      // 延迟10秒后再调用一次退出，防止客户端无法退出
      // setTimeout(() => {
      //   window.bitClientMain.relaunch()
      // }, 30000)
    }, 300)
  }
})
</script>

<style scoped lang="scss">
.version-info {
  background: #f7f7f7;
  box-sizing: border-box;
  padding: 10px;
  ul {
    li {
      padding-left: 10px;
      line-height: 26px;
    }
  }
}
</style>
