export default {
  tips: 'Переупорядочить окна',
  phoenTips: 'Переупорядочить окна',
  reset: 'Переупорядочить окна и изменить размер',
  resetPhone: 'Переупорядочить окна телефона и изменить размер',
  resetEnv: 'Переупорядочить окна и изменить размер',
  startBtn: 'Начать упорядочивание',
  box: 'Сеткой',
  diagonal: 'Диагональю',
  startX: 'Начальная позиция:',
  windowSize: 'Размер окна:',
  width: 'Ширина:',
  height: 'Высота:',
  col: 'Окон на строку:',
  space: 'Интервал окон:',
  spaceX: 'Гориз. расст.:',
  spaceY: 'Верт. расст.:',
  offset: 'Смещение окон:',
  minSizeTips:
    'Примечание: Установленное допустимое окно браузера составляет минимальная ширина - {width} пикселей, а минимальная высота - {height} пикселей.',
  minSizeTipsPhone:
    'Примечание: Минимально допустимая ширина окна браузера составляет {width} пикселей, а минимальная высота - {height} пикселей.',
  minSizeTipsEnv: 'Примечание: минимальная ширина окна среды - {width}px, минимальная высота - {height}px.',
  startXRuleMsg: 'Введите начальную позицию X',
  startYRuleMsg: 'Введите начальную позицию Y',
  widthRuleMsg: 'Введите ширину окна',
  heightRuleMsg: 'Введите высоту окна',
  colRuleMsg: 'Введите количество окон в строке',
  spaceXRuleMsg: 'Введите горизонтальное расстояние',
  spaceYRuleMsg: 'Введите вертикальное расстояние',
  offsetXRuleMsg: 'Введите горизонтальное смещение',
  offsetYRuleMsg: 'Введите вертикальное смещение',
  resetFlexable: 'Быстрое автопозиционирование',
  monitor: 'Монитор',
  orderBySeq: 'Сортировка по порядку',
  desc: 'По убыванию',
  asc: 'По возрастанию',
  minSizeTips1:
    'Примечание: Установленное  допустимое окно смартфона составляет минимальная ширина - {width} пикселей, а минимальная высота - {height} пикселей.'
}
