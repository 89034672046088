export default {
  workbench: '浏览器窗口工作台页面',
  chuhai2345: '2345出海导航',
  workbenchDisable: '不显示',
  localServer: '本地页面',
  workbenchTips: '使用本地页面或不显示可省流量',
  abortImg: '禁止加载图片',
  abortImgTips: '开启后，为防止无法显示图形验证，建议填写10KB，0KB则不加载任何图片！',
  abortMedia: '禁止视频自动播放/预加载',
  abortMediaTips: '开启后，将禁止视频自动播放和预加载，非标准格式可能无效',
  muteAudio: '禁止网站播放声音',
  muteAudioTips: '所有视频或音频都将静音播放（正常播放但处于静音模式）',
  stopWhileNetError: '网络不通停止打开',
  stopWhileNetErrorTips: '代理或其他原因导致的网络不通畅，停止打开浏览器',
  syncTabs: '同步标签页',
  syncTabsTips: '同步浏览器已打开的标签页面',
  syncCookies: '同步Cookie',
  syncCookiesTips: '同步浏览器Cookies，保持登录状态',
  syncIndexedDb: '同步IndexedDB',
  syncIndexedDbTips: '同步IndexedDB数据，一些站点会利用IndexedDB辅助Cookie',
  syncLocalStorage: '同步Local Storage',
  syncLocalStorageTips: '同步Local Storage数据，一些站点使用Local Storage存储登录信息',
  syncBookmarks: '同步书签',
  syncBookmarksTips: '同步浏览器书签',
  syncAuthorization: '同步已保存的用户名密码',
  syncAuthorizationTips: '同步浏览器已保存的用户名密码，6.0.1版本及以上支持',
  credentialsEnableService: '禁止保存密码弹窗',
  credentialsEnableServiceTips: '开启后，将不再弹出提示保存密码弹窗',
  syncHistory: '同步历史记录',
  syncHistoryTips: '同步浏览器访问历史记录',
  syncExtensions: '同步扩展应用数据',
  syncExtensionsTips: `同步该窗口的扩展应用数据，保持扩展应用的登录状态。<span class="text-red">使用数字货币钱包不建议开启此项，建议将扩展应用数据保存在本地！</span>`,
  syncUserExtensions: '跨窗口同步扩展应用数据',
  syncUserExtensionsTips:
    '所有开启了该开关的窗口，相互间将同步最新的扩展应用数据（这些窗口将会始终保持相同的扩展应用数据；可以实现这些窗口都保持扩展的登录状态）。',
  clearCacheFilesBeforeLaunch: '启动浏览器前删除缓存文件',
  clearCacheFilesBeforeLaunchTips: '启动浏览器前，删除全部缓存文件，且不同步服务端保存的缓存',
  clearCookiesBeforeLaunch: '启动浏览器前删除Cookie',
  clearCookiesBeforeLaunchTips: '启动浏览器前，删除全部Cookie，且不同步服务端保存的Cookie',
  clearHistoriesBeforeLaunch: '启动浏览器前删除历史记录',
  clearHistoriesBeforeLaunchTips: '启动浏览器前，删除全部历史记录，且不同步服务端保存的历史记录',
  randomFingerprint: '启动浏览时随机指纹',
  randomFingerprintTips: '每次启动浏览器时，均随机指纹',
  allowedSignin: '允许登录Chrome',
  allowedSigninTips: '关闭后，无需登录 Chrome 即可登录 Gmail 等 Google 网站（同时不同电脑之间可以同步Gmail等Google网站登录状态）',
  enableBackgroundMode: '关闭浏览器后继续运行应用',
  enableBackgroundModeTips: '关闭后，退出浏览器以后将不再继续运行后台应用，可以节约一定系统资源',
  disableGpu: '使用硬件加速模式',
  disableGpuTips: '开启后，在系统支持情况下将自动启用GPU硬件加速，提升浏览器性能',
  settingTips: '提示：新建窗口时，对应属性将优先以偏好值作为默认属性，修改此处值，不会修改现有窗口的任何一个属性，不支持Local API使用。',
  settingTips1: '新建窗口时，对应属性将优先以偏好值作为默认属性，修改此处值，不会修改现有窗口的任何一个属性，不支持Local API使用。',
  disableTranslatePopup: '禁止浏览器弹出谷歌翻译',
  disableTranslatePopupTips: '开启后，将禁止浏览器窗口自动弹出谷歌翻译浮窗',
  stopWhileIpChange: 'IP发生变化停止打开',
  stopWhileIpChangeTips: '当前IP与上次打开窗口IP不一致时，停止打开浏览器',
  resetLastIp: '重置IP',
  resetLastIpConfirm: '您确定要重置上次打开窗口IP吗？',
  resetLastIpSuccess: '重置IP完成，保存配置后生效！',
  disableSyncTips: '注意：5.0.9及以下版本不再支持同步功能，如需以下同步功能，请升级到最新版使用！',
  disableSyncTips1:
    '以下同步项开启后，均会同步相应数据到云端（便于跨设备使用/员工协作）；当分享/转移窗口时，以下开启项的数据也会同步给对方，请谨慎操作。',
  syncExtensionsWarning: '<p class="text-red">使用数字货币钱包不建议开启此项，建议将扩展应用数据保存在本地，您确定要开启吗？</p>',
  syncUserExtensionVersionTips: '6.0.1及以上版本支持此功能。',
  abortImgSizePrefix: '禁止加载',
  abortImgSizeSuffix: '以上的图片，可节省流量',
  stopWhileCountryChange: 'IP对应国家/地区发生变化停止打开',
  stopWhileCountryChangeTips: '当前IP对应国家/地区与上一次打开时不一致，停止打开浏览器',
  clearCacheWithoutExtensions: '启动前删除缓存（保留扩展数据）',
  clearCacheWithoutExtensionsTips: '启动浏览器前，删除缓存文件（保留扩展数据），且不同步服务端保存的缓存',
  syncPaymentsAndAddress: '同步已保存的付款方式及地址',
  syncPaymentsAndAddressTips: '同步浏览器窗口已保存的付款方式及地址',
  hide: '收起',
  more: '展示更多',
  disableNotifications: '禁止网页弹出消息通知',
  disableNotificationsTips: '开启后，将不再弹出网页的消息通知弹窗',
  disableClipboard: '禁止网站读取剪贴板内容',
  disableClipboardTips: '开启后，将禁止所有网站的默认读取剪贴板内容，增强安全性',
  memorySaver: '省内存模式',
  memorySaverTips: '开启后，会释放闲置标签页占用的内存。这可让使用中的标签页和其他应用获得更多计算机资源，保持快速运行'
}
