export default {
  buyMonthComputing: 'Purchase',
  buyComputing: 'Purchase Computing Power',
  batchDelete: 'Batch Delete Computing Power',
  editNameWithSeq: 'Edit Computing Power Name',
  computingName: 'Name',
  name: 'Name',
  remark: 'Remark',
  renewalComputing: 'Renew',
  useEnvironment: 'Profile',
  deleteTip: 'Computing power cannot be recovered after deletion. Are you sure you want to permanently delete the computing power?',
  editNameSuccess: 'Computing power name successfully updated',
  enableAutoRenew:
    'Are you sure you want to enable auto-renewal? <p class="q-mt-sm">After enabling auto-renewal, the system will automatically check: 3 days before your computing power expires, if your account has sufficient balance, it will automatically renew the computing power for 1 month and deduct the corresponding balance!</p>',
  disableAutoRenew:
    'Are you sure you want to disable auto-renewal? <p class="q-mt-sm">After disabling auto-renewal, the system will stop checking whether the computing power is about to expire and will not automatically renew the computing power for you!</p>',
  selectComputing: 'Enter select computing power',
  selectComputingType: 'Please select the type of computing power.',
  delete: 'Delete',
  renewPhoneTip:
    'Note: Please complete the payment promptly. Ensure the selected computing power, quantity, renewal duration, and payment method are correct. Orders cannot be canceled or exchanged once placed!',
  changeTip:
    'Note: Please complete the payment as soon as possible to ensure that the selected computing power type, device model, duration, quantity, and payment method are correct. Once the order is placed, it cannot be refunded or exchanged!',
  buySuccess: 'Buy successful.',
  expired: 'Expired',
  type: 'Type'
}
