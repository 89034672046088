import { LocalStorage } from 'quasar'
import { boot } from 'quasar/wrappers'
import store from 'src/store'

// 路由白名单，直接跳转
const routesWhiteList = ['/login', '/register', '/reset-pass', '/forget-account', '/404', '/403']

export default boot(async ({ router }) => {
  router.beforeEach(async (to, from, next) => {
    let hasToken = store.getters['user/token']
    if (hasToken) {
      const needReload = store.getters['client/reloadAppPage']
      if (needReload) {
        location.href = to.href
        location.reload()
      }

      await store.dispatch('user/checkUserInfo')
      // store.getters['routes/routes'].length
      if (store.getters['routes/routes'].length) {
        // 禁止已登录用户返回登录页
        if (to.path === '/login') {
          next({ path: '/' })
        } else next()
      } else {
        try {
          await store.dispatch('routes/setRoutes')

          if (window.isInBitBrowser) {
            // 从客户端同步已打开的窗口
            const map = await window.bitClientMain.getOpenedWindows()
            store.commit('client/SYNC_IDS', map)
          }
          if (from.query.redirect) {
            next(from.query.redirect)
          } else {
            next({ ...to, replace: true })
          }
        } catch (err) {
          await store.dispatch('user/resetAll')
          next({ name: 'Login', replace: true })
        }
      }
    } else {
      if (routesWhiteList.includes(to.path)) {
        next()
      } else {
        next({ name: 'Login', replace: true, query: { redirect: to.fullPath } })
      }
    }
  })
  router.afterEach(to => {
    // document.title = getPageTitle(to.meta.title)
    // if (VabProgress.status) VabProgress.done()
    if (window.isInBitBrowser) {
      window.bitClientMain.showMainWindow()
    }
  })
})
