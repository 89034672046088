export default {
  updateProxyWithSeq: 'Update Proxy(Seq: {seq})',
  updateProxy: 'Update Proxy',
  addProxy: 'Add Proxy',
  addProxyEasy: 'Add',
  updateSuccess: 'Success',
  proxyMethod: 'Method',
  ipCheckService: 'Lookup Channel',
  ipCheckTips1: '1. The IP information which is lookuped by you selected channel will be displayed on workbench page.',
  ipCheckTips2:
    '2. Different IP lookup channels may have different detection results. If the result is unexpected, please switch the lookup channel.',
  ipCheckTips3:
    '3. If the Language, Display Language, Timezone and Geolocation is checked by based on IP, please ensure that the detection results of the lookup channel are consistent with the actual information, otherwise errors will occur in these fingerprint properties.',
  ipCheckTips4: '(It is recommended to use channel IP123 for Rola-IP)',
  proxyType: 'Type',
  proxyTypeColon: 'Search by proxy type',
  ipProtocol: 'Protocol',
  useGlobalProxy: 'Use Global Settings',
  globalProxyInfo: '{proxyType} proxy with global settings.',
  host: 'Host',
  hostColon: 'Search by host',
  hostPlaceholder: 'Host:Port:Username:Password will auto-split',
  hostTips:
    'Automatically split when the cursor leaves for this template. <br/>Proxy Type://Host:Port:Username:Password <br/>If the host is in IPv6 format, please fill in the host in [ ]',
  port: 'Port',
  portPlaceholder: 'Please enter port',
  username: 'Username',
  usernamePlaceholder: 'Optional, please enter username',
  usernamePlaceholder2: 'Please enter username',
  password: 'Password',
  passwordPlaceholder: 'Optional, please enter password',
  passwordPlaceholder2: 'Please enter password',
  ipPlaceholder: 'Please enter the default IP(eg:11.22.33.44 )',
  noChangeIp: '只代理当前您填写的IP，若是当前填写IP失效，不会自动寻找可用IP代理！',
  ipHint: '若填写IP，下次打开窗口将优先使用该IP；不填写IP或已填写的IP失效，将使用下方位置信息自动寻找可用的IP！',
  extractIpTip: 'Extracting links should preferably be set to extract only 1 IP each time, to avoid wasting too much proxy platform fees!',
  ipTips: 'The country information will be automatically filled in by click the blank space after IP filled.',
  country: 'Country/Region',
  provicePlaceholder: 'Please enter State/Provice code',
  provice: 'State/Provice',
  cityPlaceholder: 'Please enter city code',
  city: 'City',
  checkProxy: 'Check Proxy',
  httpsProxyMsg1: '[Proxy monitoring]',
  httpsProxyMsg2: 'Open the browser to check proxy.',
  ipCheckFailedMsg: 'Proxy check failed, please ensure that the IP is valid and contact the provider if necessary.',
  ipNoChange: '只代理当前IP',
  ipNoChangeTips: '该IP失效不会自动更换IP',
  hostRuleMsg: 'Please enter host',
  portRuleRequiredMsg: 'Please enter port',
  portRuleNumMsg: 'Please enter a number',
  portRuleValidMsg: 'The port number range is 0-65535',
  usernameRuleMsg: 'Username can not exceed 200 characters',
  passwordRuleMsg: 'Password can not exceed 200 characters',
  ipRuleMsg1: '勾选“只代理当前IP”时，务必填写IP',
  ipRuleValidMsg: 'Please enter a valid IP address.',
  proviceRuleMsg: 'State/Province code is comprised of up to 30 alphanumeric characters.',
  cityRuleMsg1: 'Please enter a valid city code',
  cityRuleMsg2: 'City code can not exceed 30 characters.',
  customize: 'Custom',
  extractIp: 'Extract By API',
  extractIpDialog: 'Extracting proxy IP through API extraction link...',
  extractIpMsg1: 'Check whether the last proxy IP is valid...',
  extractIpMsg2: 'The proxy IP is available. Opening...',
  extractIpMsg3: 'The proxy IP has expired. Retrieve the proxy IP again...',
  extractIpMsg4: 'Check API extraction link...',
  extractIpMsg5: 'Unable to extract IP without link.',
  extractIpMsg6: 'Attempt to extract proxy IP information...',
  extractIpMsg7: 'Parsing extraction results...',
  extractIpMsg8: 'Setting succeeded, opening browser...',
  extractIpMsg9: 'Unable to extract the proxy IP, please check the link is valid.',
  extractIpMsg10: 'Resolution succeeded, setting proxy IP...',
  formatError: 'Please change the API return format to text/txt and try again...',
  addWhiteList: 'Try to add IP white list...',
  notInWhiteList: 'Not added to the white list',
  usernameRequiredRule: 'Please enter user name',
  passwordRequiredRule: 'Please enter password',
  provider: 'Provider',
  protocol: 'Protocol',
  extractType: 'Method',
  extractUrlPlaceholder: 'Please enter extraction link.',
  extractUrl: 'Extraction Link',
  extractTest: 'Test',
  whitelistTips:
    'Note: Most proxy IP platforms need to add the local IP address to their white list to access the extracted link normally. Add your local IP address to whitelist if there is an exception!',
  initialPass: 'Initial Password: ',
  extractUrlTips: 'How to get extraction link?',
  rolaTips:
    'Click "To ROLA-IP Admin" to goto Global Residential IP -> Rotating RESI IP, or to Global Phone IP, or to Global Datacenter IP -> Switch to "API Config" tab and click "Click to Generate API" to get extraction link after configuration!',
  extractIpRule: 'Please select a provider.',
  extractUrlRule: 'Please enter a valid URL.',
  extractProtocolRule: 'Please select proxy protocol.',
  extractIpChange1: 'Extract a new IP every time you open the browser',
  extractIpChange2: 'Extract a new IP util last IP is invalid',
  extractError: 'Failed to request data, please ensure the API link is valid.',
  ipCountry: 'Country/Region: ',
  ipState: 'State/Provice: ',
  ipCity: 'City: ',
  iplngAndLat: 'Lng/Lat: ',
  ipTimezone: 'Timezone: ',
  ipZip: 'Zip: ',
  ipNotWorking: 'Connection failed.',
  common: 'Common',
  noproxy: 'Direct Connection Mode(No Proxy)',
  asocks: 'Asocks Dynamic Proxy',
  ipideaauto: 'Ipidea Dynamic Proxy',
  storm: 'Storm Dynamic Proxy',
  iphtmlauto: 'Iphtml Dynamic Proxy',
  lunaproxy: 'Lunaproxy Dynamic Proxy',
  oxylabsauto: 'Oxylabs Dynamic Proxy',
  smart: 'Smart Dynamic Proxy',
  lumauto: 'Luminati Dynamic Proxy',
  '922proxy': '922s5 Dynamic Proxy',
  rolaip: 'Rola-IP Dynamic Proxy',
  skyip: 'Sky-IP Dynamic Proxy',
  kookeey: 'Kookeey Dynamic Proxy',
  socks5io: 'Socks5.io Dynamic Proxy',
  ipfly: 'Ipfly Dynamic Proxy',
  ssh: 'SSH',
  socks5: 'Socks5',
  http: 'HTTP',
  https: 'HTTPS',
  ip2world: 'IP2World Dynamic Proxy',
  skyipTips:
    'Click "To SKY-IP Admin", To "Rotating Residential IP", "3G/4G/5G Mobile IP" or "Rotating Datacenter IP" -> To "API Integration", after configuring the parameters, click "Generate API Link" to generate the link.',
  refreshProxyUrl: 'Refresh Proxy',
  refreshProxyUrlTips: `1. This feature only supports proxies with the "URL refresh function";</br>
    2. Clicking the refresh icon will automatically execute the URL refresh, changing the IP address of this browser;</br>
    3. When multiple browsers use the same proxy information, refreshing the proxy IP will simultaneously change the IP addresses of multiple browsers.`,
  singleAdd: 'Add',
  deleteConfirm: 'Confirm to delete this proxy?',
  remark: 'Remark',
  remarkColon: 'Search by remark',
  boundBrowsers: 'Bound Profiles',
  proxyInfo: 'Proxy Info',
  updateRemark: 'Update Proxy Remark',
  remarkRule: 'Proxy Remark can not exceed 200 characters.',
  batchAdd: 'Batch Add',
  batchAddTips: 'Please add at least one proxy item.',
  proxyRecommend: 'Recommended Global Proxy IP',
  proxyManage: 'Proxy Management',
  proxyIp: 'Proxy',
  exportProxy: 'Export Selected Proxies',
  exportAllProxy: 'Export All Proxies',
  exportProxyTitle: 'Export Proxy IP.xlsx',
  exportAllProxyTip: 'Are you sure you want to export all items?',
  batchExportProxy: 'Bulk Export Proxies',
  boundBrowsersCount: 'Bound {count} Profiles',
  batchDetection: 'Batch Detection',
  batchDetectionEasy: 'Detection',
  noSelection: 'Please select at least one proxy.',
  batchSection: 'Proxy Information: (Fill in according to the instructions, one per line)',
  batchSectionTitle: 'Filling Instructions: ',
  batchDesc1:
    "Only http, https, socks5, and ssh proxy types are supported; the corresponding proxy type needs to be followed by ://. If you need to add remarks, please add {'{remarks}'} at the end of the proxy information;",
  batchDesc2: 'When the proxy type is not filled in, it will be set to socks5 type by default;',
  batchDesc3: 'Fill in one proxy per line, up to 100 proxies can be added at a time;',
  batchDesc4: 'The proxy host supports IPv4 and IPv6. The IPv6 proxy host needs to be filled in [].',
  batchDesc5: 'If you need to add a refresh URL, please fill in the refresh URL at the end of the proxy information within parentheses',
  demoTitle: 'Example Of Filling Format: ',
  demo1: '192.168.1.1:8800&#123;remarks&#125(Refresh URL);',
  demo2: '192.168.1.1:8800:username:password&#123;remarks&#125(Refresh URL);',
  demo3: 'socks5://192.168.1.1:8800&#123;remarks&#125(Refresh URL);',
  demo4: 'http://[2001:db8:2de:0:0:0:0:e13]:8800&#123;remarks&#125(Refresh URL);',
  detectedInfo: 'Detected Proxy Information: ({count} items)',
  batchFormatError: 'Fill in one proxy per line, up to 100 proxies can be added at a time',
  outInfo: 'Detected IP',
  deleteProxy: 'Delete Proxy',
  proxyManageRule: 'Please select a proxy.',
  usedLabel1: 'Detected that this IP in recent time',
  usedLabel2: 'Used in this software!',
  ipNoUsed: 'This IP address has not been used in this software!',
  duplicateCheck: 'Duplicate Check',
  maxCheckTimes: 'Max Check Times',
  duplicateCheckTips: 'When opening the browser, it will check whether the extracted IP has been used in this system.',
  duplicateDetected: 'Detected duplicate IP, re-extracting...',
  chineseProxy: 'Recommended Chinese Proxy IP',
  visitWebsiteBtn: 'Visit Website',
  toGlobalSetting: '[To GLobal Setting]',
  verifyDuplicates: 'Verify Duplicates',
  verifyDuplicatesTip1: `Compared with all items in the "Proxy Management" list, when the proxy type and proxy information `,
  verifyDuplicatesTip2: `are completely consistent with a certain item, it is determined that the item is a duplicate and will not be added!`,
  hasProxyRepeat: `Detected that the proxy you want to add is duplicate from the "Proxy Management" list, prohibit adding! If you want to add, please do not check 'Verify Duplicates'!`,
  warningTips: `<p>Please exercise discretion in assessing the quality of the following platforms. This software only provides recommendations. You are responsible for any issues that may arise!</p><p>If any issues arise, you can file a complaint with us. After verification, we will take the necessary actions, including removal if warranted!</p>`,
  ipfoxy: 'IPFoxy Dynamic Proxy',
  lonAndLat: 'Lng/Lat',
  timeZone: 'Timezone',
  postcode: 'Language',
  correct: 'Correct',
  correctSuccess: 'Correct successfully',
  channel: 'Platform',
  correctTitle: 'IP Identify Queries/Edit',
  isNotSure: 'Not true?',
  batchAddProxyTip: 'Lines {line}: The proxy account and proxy password cannot exceed 200 characters.',
  dynamicProxy: 'Dynamic proxy',
  customProxy: 'When not using global settings, this dynamic proxy supports only Socks5 proxy type.',
  bulkPublish: 'Bulk Publish to Employee Accounts',
  bulkPublishTip:
    'Do you want to make the selected proxy IPs visible to employee accounts? Once published, the selected proxy IPs will be visible in the employee accounts.',
  bulkPublishTip1: ' This will allow employee accounts to perform the following actions:',
  bulkUnpublish: 'Bulk Unpublish from Employee Accounts',
  bulkUnpublishTip:
    'Do you want to unpublish the selected proxy IPs from employee accounts? After unpublishing, the selected proxy IPs will no longer be visible in the employee accounts.',
  bulkUnpublishNotice: 'Note: After unpublishing, the proxy IPs bound to profile in employee accounts will switch to direct mode!',
  allowDeleteIP: 'Allow deletion and modification',
  publishStaff: 'Publish to Employee Accounts',
  publishStaffTitle: 'Check this option to make the proxy IP visible to employee accounts.',
  publishStaffMsg: 'Check this option to expose the proxy IP to employee accounts, allowing employees to delete and modify it!',
  Publicized: ' Publicized by {name}',
  proxyDleteTip: 'The selected proxy IPs you have chosen contain proxy IPs with unauthorized deletion permissions',
  batchImportAgentTitle: 'Bulk Import of Proxy',
  batchImportAgentTip:
    'Note: This table is used for importing proxy IPs. It only imports the proxy IP data into the "Proxy Management" list of the BitBrowser and does not perform proxy detection. Please independently verify the availability of the proxy when binding to profiles.',
  batchImportAgentTemp: 'Proxy IP Import Template.xlsx',
  batchImportAgentTip2: 'Single Excel file: The number of imported proxy IPs should not exceed 1000.',
  proxySelectMethod: 'Please select the agency method',
  proxyBulkEditing: 'Batch Modify Proxy IP Configuration',
  proxyEditTip:
    'Please select proxy IPs with the same "Proxy Method" for batch modification. You can search by "Proxy Method" in the proxy management list!',
  proxyEditRepeatTip:
    'Note: The proxy IP configuration you are currently modifying, once saved successfully, will overlap with existing proxy IPs in the proxy IP list. Do you wish to proceed?',
  batchEditProxyTip:
    'To make modifications, please check the checkbox on the far left of the item you want to modify, and then proceed to modify the configuration value of that item. At that time, the configuration value of all selected proxy IPs will be simultaneously updated to your latest settings!',
  more: 'more'
}
