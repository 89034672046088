import { createI18n } from 'vue-i18n'
import messages from 'src/i18n'
import { LocalStorage } from 'quasar'

let locale = 'zh'
const params = new URL(document.location).searchParams
// 优先取URL里的语言配置
if (['zh', 'en', 'ru'].includes(params.get('lang'))) {
  locale = params.get('lang')
  LocalStorage.set('locale', locale)
} else {
  if (LocalStorage.has('locale')) locale = LocalStorage.getItem('locale')
}

// 往客户端传一次设置语言
if (window.bitClientMain && typeof window.bitClientMain.setLocale === 'function') {
  window.bitClientMain.setLocale(locale)
}

const i18n = createI18n({
  locale,
  globalInjection: true,
  legacy: false,
  messages
})

export default ({ app }) => {
  // Create I18n instance

  // Tell app to use the I18n instance
  app.use(i18n)
}

export function $t(args) {
  return i18n.global.t.call(i18n, args)
}

// 获取当前的语言
export function getCurrentLocale() {
  return i18n.global.locale
}
