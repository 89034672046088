export default {
  typePlaceholder: '选择分类搜索',
  datePlaceholder: '选择时间搜索',
  contentPlaceholder: '输入操作内容搜索',
  type: '分类',
  content: '操作内容',
  username: '操作用户',
  createdTime: '操作时间',
  browsers: '窗口管理',
  groups: '分组管理',
  users: '员工管理',
  roles: '角色管理',
  setting: '系统设置',
  app: '软件操作',
  bill: '账单管理',
  maskLogBtn: '屏蔽操作日志',
  maskLog: `您确认要屏蔽操作日志吗？屏蔽操作日志后，主账号和所有员工账号，均不会再显示任何操作日志。<p class="text-red q-mt-sm">注意：屏蔽操作日志为不可逆操作，一旦确认屏蔽，将永远不再显示操作日志！</p>`,
  maskLogTip: '屏蔽操作日志成功',
  maskLogTip1: '已屏蔽操作日志',
  historyLog: '历史日志',
  newLogs: '最新日志',
  chidrenPlaceholder: '请先选择分类搜索',
  loginLogs: '登录日志',
  actionLosg: '操作日志',
  exit: '退出',
  action: '操作',
  time: '时间',
  localDB: '本地库数据',
  fieldPlaceholder: '选择字段搜索',
  fieldValue: '字段值',
  deleteLocalLog: '删除日志',
  deleteLocalLogSuccess: '数据删除后不可恢复，确定删除日志吗？'
}
