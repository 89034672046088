const ALL_FONTS = [
  '.Al Bayan PUA',
  '.Al Nile PUA',
  '.Al Tarikh PUA',
  '.Apple Color Emoji UI',
  '.Apple SD Gothic NeoI',
  '.Aqua Kana',
  '.Aqua Kana Bold',
  '.Arabic UI Display',
  '.Arabic UI Text',
  '.Arial Hebrew Desk Interface',
  '.Baghdad PUA',
  '.Beirut PUA',
  '.Damascus PUA',
  '.DecoType Naskh PUA',
  '.Diwan Kufi PUA',
  '.Farah PUA',
  '.Geeza Pro Interface',
  '.Geeza Pro PUA',
  '.HeitiUI GB18030PUA',
  '.HeitiUI J',
  '.HeitiUI K',
  '.HeitiUI SC',
  '.HeitiUI TC',
  '.Helvetica LT MM',
  '.Helvetica Neue Desk UI',
  '.Helvetica Neue Desk UI Bold',
  '.Helvetica Neue Desk UI Bold Italic',
  '.Helvetica Neue Desk UI Italic',
  '.Helvetica Neue DeskInterface',
  '.Hiragino Kaku Gothic Interface',
  '.Hiragino Sans GB Interface',
  '.Keyboard',
  '.KufiStandardGK PUA',
  '.Lucida Grande UI',
  '.Muna PUA',
  '.Nadeem PUA',
  '.New York',
  '.Noto Nastaliq Urdu UI',
  '.PingFang HK',
  '.PingFang SC',
  '.PingFang TC',
  '.SF Compact Display',
  '.SF Compact Rounded',
  '.SF Compact Text',
  '.SF NS',
  '.SF NS Display',
  '.SF NS Display Condensed',
  '.SF NS Mono',
  '.SF NS Rounded',
  '.SF NS Symbols',
  '.SF NS Text',
  '.SF NS Text Condensed',
  '.Sana PUA',
  '.Savoye LET CC.',
  '.Times LT MM',
  'AIGDT',
  'AMGDT',
  'AR PL UKai CN',
  'AR PL UKai HK',
  'AR PL UKai TW',
  'AR PL UKai TW MBE',
  'AR PL UMing CN',
  'AR PL UMing HK',
  'AR PL UMing TW',
  'AR PL UMing TW MBE',
  'Abadi MT Condensed',
  'Abyssinica SIL',
  'AcadEref',
  'Adobe Arabic',
  'Adobe Caslon Pro',
  'Adobe Caslon Pro Bold',
  'Adobe Devanagari',
  'Adobe Fan Heiti Std B',
  'Adobe Fangsong Std R',
  'Adobe Garamond Pro',
  'Adobe Garamond Pro Bold',
  'Adobe Gothic Std B',
  'Adobe Hebrew',
  'Adobe Heiti Std R',
  'Adobe Kaiti Std R',
  'Adobe Ming Std L',
  'Adobe Myungjo Std M',
  'Adobe Naskh Medium',
  'Adobe Song Std L',
  'Agency FB',
  'Aharoni',
  'Aharoni Bold',
  'Aharoni CLM',
  'Al Bayan',
  'Al Bayan Bold',
  'Al Bayan Plain',
  'Al Nile',
  'Al Nile Bold',
  'Al Tarikh',
  'Al Tarikh Regular',
  'AlArabiya',
  'AlBattar',
  'AlHor',
  'AlManzomah',
  'AlYarmook',
  'Aldhabi',
  'Alexandra Script',
  'Algerian',
  'AlternateGothic2 BT',
  'Amadeus',
  'AmdtSymbols',
  'American Typewriter',
  'American Typewriter Bold',
  'American Typewriter Condensed',
  'American Typewriter Condensed Bold',
  'American Typewriter Condensed Light',
  'American Typewriter Light',
  'American Typewriter Semibold',
  'Amiri',
  'Amiri Quran',
  'Amiri Quran Colored',
  'AnastasiaScript',
  'Andale Mono',
  'Andalus',
  'Andika',
  'Android Emoji',
  'Angsana New',
  'Angsana New Bold',
  'Angsana New Bold Italic',
  'Angsana New Italic',
  'AngsanaUPC',
  'AngsanaUPC Bold',
  'AngsanaUPC Bold Italic',
  'AngsanaUPC Italic',
  'Ani',
  'AnjaliOldLipi',
  'Annabelle',
  'Aparajita',
  'Aparajita Bold',
  'Aparajita Bold Italic',
  'Aparajita Italic',
  'Apple Braille',
  'Apple Braille Outline 6 Dot',
  'Apple Braille Outline 8 Dot',
  'Apple Braille Pinpoint 6 Dot',
  'Apple Braille Pinpoint 8 Dot',
  'Apple Chancery',
  'Apple Color Emoji',
  'Apple LiGothic',
  'Apple LiGothic Medium',
  'Apple LiSung',
  'Apple LiSung Light',
  'Apple SD Gothic Neo',
  'Apple SD Gothic Neo Bold',
  'Apple SD Gothic Neo Heavy',
  'Apple SD Gothic Neo Light',
  'Apple SD Gothic Neo Medium',
  'Apple SD Gothic Neo Regular',
  'Apple SD Gothic Neo SemiBold',
  'Apple SD Gothic Neo Thin',
  'Apple SD Gothic Neo UltraLight',
  'Apple SD GothicNeo ExtraBold',
  'Apple Symbols',
  'AppleGothic',
  'AppleGothic Regular',
  'AppleMyungjo',
  'AppleMyungjo Regular',
  'AquaKana',
  'Arab',
  'Arabic Transparent',
  'Arabic Typesetting',
  'Arial',
  'Arial Baltic',
  'Arial Black',
  'Arial Bold',
  'Arial Bold Italic',
  'Arial CE',
  'Arial CYR',
  'Arial Cyr',
  'Arial Greek',
  'Arial Hebrew',
  'Arial Hebrew Bold',
  'Arial Hebrew Light',
  'Arial Hebrew Scholar',
  'Arial Hebrew Scholar Bold',
  'Arial Hebrew Scholar Light',
  'Arial Italic',
  'Arial Narrow',
  'Arial Narrow Bold',
  'Arial Narrow Bold Italic',
  'Arial Narrow Italic',
  'Arial Nova',
  'Arial Nova Bold',
  'Arial Nova Bold Italic',
  'Arial Nova Cond',
  'Arial Nova Cond Bold',
  'Arial Nova Cond Bold Italic',
  'Arial Nova Cond Italic',
  'Arial Nova Cond Light',
  'Arial Nova Cond Light Italic',
  'Arial Nova Italic',
  'Arial Nova Light',
  'Arial Nova Light Italic',
  'Arial Rounded MT Bold',
  'Arial TUR',
  'Arial Unicode MS',
  'Arimo',
  'Ariston',
  'Arno Pro',
  'Arno Pro Caption',
  'Arno Pro Display',
  'Arno Pro Light Display',
  'Arno Pro SmText',
  'Arno Pro Smbd',
  'Arno Pro Smbd Caption',
  'Arno Pro Smbd Display',
  'Arno Pro Smbd SmText',
  'Arno Pro Smbd Subhead',
  'Arno Pro Subhead',
  'Arundina',
  'Athelas',
  'Athelas Bold',
  'Athelas Bold Italic',
  'Athelas Italic',
  'Athelas Regular',
  'Avenir',
  'Avenir Black',
  'Avenir Black Oblique',
  'Avenir Book',
  'Avenir Book Oblique',
  'Avenir Heavy',
  'Avenir Heavy Oblique',
  'Avenir Light',
  'Avenir Light Oblique',
  'Avenir Medium',
  'Avenir Medium Oblique',
  'Avenir Next',
  'Avenir Next Bold',
  'Avenir Next Bold Italic',
  'Avenir Next Condensed',
  'Avenir Next Condensed Bold',
  'Avenir Next Condensed Bold Italic',
  'Avenir Next Condensed Demi Bold',
  'Avenir Next Condensed Demi Bold Italic',
  'Avenir Next Condensed Heavy',
  'Avenir Next Condensed Heavy Italic',
  'Avenir Next Condensed Italic',
  'Avenir Next Condensed Medium',
  'Avenir Next Condensed Medium Italic',
  'Avenir Next Condensed Regular',
  'Avenir Next Condensed Ultra Light',
  'Avenir Next Condensed Ultra Light Italic',
  'Avenir Next Demi Bold',
  'Avenir Next Demi Bold Italic',
  'Avenir Next Heavy',
  'Avenir Next Heavy Italic',
  'Avenir Next Italic',
  'Avenir Next Medium',
  'Avenir Next Medium Italic',
  'Avenir Next Regular',
  'Avenir Next Ultra Light',
  'Avenir Next Ultra Light Italic',
  'Avenir Oblique',
  'Avenir Roman',
  'Ayuthaya',
  'BIZ UDGothic',
  'BIZ UDGothic Bold',
  'BIZ UDMincho',
  'BIZ UDMincho Medium',
  'BIZ UDPGothic',
  'BIZ UDPGothic Bold',
  'BIZ UDPMincho',
  'BIZ UDPMincho Medium',
  'BPG Algeti GPL&GNU',
  'BPG Chveulebrivi GPL&GNU',
  'BPG Courier GPL&GNU',
  'BPG Courier S GPL&GNU',
  'BPG DedaEna Block GPL&GNU',
  'BPG DejaVu Sans 2011 GNU-GPL',
  'BPG Elite GPL&GNU',
  'BPG Excelsior Caps GPL&GNU',
  'BPG Excelsior Condencerd GPL&GNU',
  'BPG Excelsior GPL&GNU',
  'BPG Glaho GPL&GNU',
  'BPG Gorda GPL&GNU',
  'BPG Ingiri GPL&GNU',
  'BPG Mrgvlovani Caps GNU&GPL',
  'BPG Mrgvlovani GPL&GNU',
  'BPG Nateli Caps GPL&GNU',
  'BPG Nateli Condenced GPL&GNU',
  'BPG Nateli GPL&GNU',
  'BPG Nino Medium Cond GPL&GNU',
  'BPG Nino Medium GPL&GNU',
  'BPG Sans GPL&GNU',
  'BPG Sans Medium GPL&GNU',
  'BPG Sans Modern GPL&GNU',
  'BPG Sans Regular GPL&GNU',
  'BPG Serif GPL&GNU',
  'BPG Serif Modern GPL&GNU',
  'Baghdad',
  'Baghdad Regular',
  'Bahnschrift',
  'Bahnschrift Light',
  'Bahnschrift SemiBold',
  'Bahnschrift SemiLight',
  'Bangla MN',
  'Bangla MN Bold',
  'Bangla Sangam MN',
  'Bangla Sangam MN Bold',
  'BankGothic Lt BT',
  'BankGothic Md BT',
  'Baoli SC',
  'Baoli SC Regular',
  'Baoli TC',
  'Baoli TC Regular',
  'Baskerville',
  'Baskerville Bold',
  'Baskerville Bold Italic',
  'Baskerville Italic',
  'Baskerville Old Face',
  'Baskerville SemiBold',
  'Baskerville SemiBold Italic',
  'Batang',
  'BatangChe',
  'Bauhaus 93',
  'Beirut',
  'Beirut Regular',
  'Bell Gothic Std Black',
  'Bell Gothic Std Light',
  'Bell MT',
  'Berlin Sans FB',
  'Berlin Sans FB Demi',
  'Bernard MT Condensed',
  'BiauKai',
  'Bickham Script One',
  'Bickham Script Pro Regular',
  'Bickham Script Pro Semibold',
  'Bickham Script Two',
  'Big Caslon',
  'Big Caslon Medium',
  'Birch Std',
  'Bitstream Charter',
  'Blackadder ITC',
  'Blackoak Std',
  'Bodoni 72',
  'Bodoni 72 Bold',
  'Bodoni 72 Book',
  'Bodoni 72 Book Italic',
  'Bodoni 72 Oldstyle',
  'Bodoni 72 Oldstyle Bold',
  'Bodoni 72 Oldstyle Book',
  'Bodoni 72 Oldstyle Book Italic',
  'Bodoni 72 Smallcaps',
  'Bodoni 72 Smallcaps Book',
  'Bodoni MT',
  'Bodoni MT Black',
  'Bodoni MT Condensed',
  'Bodoni MT Poster Compressed',
  'Bodoni Ornaments',
  'Book Antiqua',
  'Bookman Old Style',
  'Bookshelf Symbol 7',
  'Bradley Hand',
  'Bradley Hand Bold',
  'Bradley Hand ITC',
  'Britannic Bold',
  'Broadway',
  'Browallia New',
  'Browallia New Bold',
  'Browallia New Bold Italic',
  'Browallia New Italic',
  'BrowalliaUPC',
  'BrowalliaUPC Bold',
  'BrowalliaUPC Bold Italic',
  'BrowalliaUPC Italic',
  'Brush Script MT',
  'Brush Script MT Italic',
  'Brush Script Std',
  'C059',
  'Caladea',
  'Caladings CLM',
  'Calibri',
  'Calibri Bold',
  'Calibri Bold Italic',
  'Calibri Italic',
  'Calibri Light',
  'Calibri Light Italic',
  'Californian FB',
  'Calisto MT',
  'Calligraph',
  'Cambria',
  'Cambria Bold',
  'Cambria Bold Italic',
  'Cambria Italic',
  'Cambria Math',
  'Candara',
  'Candara Bold',
  'Candara Bold Italic',
  'Candara Italic',
  'Candara Light',
  'Candara Light Italic',
  'Cantarell',
  'Cantarell Extra Bold',
  'Cantarell Light',
  'Cantarell Thin',
  'Carlito',
  'Carolina',
  'Castellar',
  'Centaur',
  'Century',
  'Century Gothic',
  'Century Schoolbook',
  'Century Schoolbook L',
  'Ceremonious Two',
  'Chalkboard',
  'Chalkboard Bold',
  'Chalkboard SE',
  'Chalkboard SE Bold',
  'Chalkboard SE Light',
  'Chalkboard SE Regular',
  'Chalkduster',
  'Chandas',
  'Chaparral Pro',
  'Chaparral Pro Light',
  'Charcoal CY',
  'Charlemagne Std',
  'Charter',
  'Charter Black',
  'Charter Black Italic',
  'Charter Bold',
  'Charter Bold Italic',
  'Charter Italic',
  'Charter Roman',
  'Chilanka',
  'Chiller',
  'CityBlueprint',
  'Clarendon BT',
  'Clarendon Blk BT',
  'Clarendon Lt BT',
  'Clean',
  'Cochin',
  'Cochin Bold',
  'Cochin Bold Italic',
  'Cochin Italic',
  'Colonna MT',
  'Comfortaa',
  'Comfortaa Light',
  'Comic Sans MS',
  'Comic Sans MS Bold',
  'Comic Sans MS Bold Italic',
  'Comic Sans MS Italic',
  'CommercialPi BT',
  'CommercialScript BT',
  'Complex',
  'Consolas',
  'Consolas Bold',
  'Consolas Bold Italic',
  'Consolas Italic',
  'Constantia',
  'Constantia Bold',
  'Constantia Bold Italic',
  'Constantia Italic',
  'Cooper Black',
  'Cooper Std Black',
  'Copperplate',
  'Copperplate Bold',
  'Copperplate Gothic',
  'Copperplate Gothic Bold',
  'Copperplate Gothic Light',
  'Copperplate Light',
  'Copyist',
  'Corbel',
  'Corbel Bold',
  'Corbel Bold Italic',
  'Corbel Italic',
  'Corbel Light',
  'Corbel Light Italic',
  'Cordia New',
  'Cordia New Bold',
  'Cordia New Bold Italic',
  'Cordia New Italic',
  'CordiaUPC',
  'CordiaUPC Bold',
  'CordiaUPC Bold Italic',
  'CordiaUPC Italic',
  'Corsiva Hebrew',
  'Corsiva Hebrew Bold',
  'Cortoba',
  'CountryBlueprint',
  'Courier',
  'Courier 10 Pitch',
  'Courier Bold',
  'Courier Bold Oblique',
  'Courier New',
  'Courier New Baltic',
  'Courier New Bold',
  'Courier New Bold Italic',
  'Courier New CE',
  'Courier New CYR',
  'Courier New Cyr',
  'Courier New Greek',
  'Courier New Italic',
  'Courier New TUR',
  'Courier Oblique',
  'Cousine',
  'Curlz MT',
  'Cursor',
  'D050000L',
  'DFKai-SB',
  'DIN Alternate',
  'DIN Alternate Bold',
  'DIN Condensed',
  'DIN Condensed Bold',
  'Damascus',
  'Damascus Bold',
  'Damascus Light',
  'Damascus Medium',
  'Damascus Regular',
  'Damascus Semi Bold',
  'DaunPenh',
  'David',
  'David Bold',
  'David CLM',
  'DecoType Naskh',
  'DecoType Naskh Regular',
  'Decor',
  'DejaVu Math TeX Gyre',
  'DejaVu Sans',
  'DejaVu Sans Condensed',
  'DejaVu Sans Light',
  'DejaVu Sans Mono',
  'DejaVu Serif',
  'DejaVu Serif Condensed',
  'DengXian',
  'DengXian Bold',
  'DengXian Light',
  'Devanagari MT',
  'Devanagari MT Bold',
  'Devanagari Sangam MN',
  'Devanagari Sangam MN Bold',
  'Didot',
  'Didot Bold',
  'Didot Italic',
  'DilleniaUPC',
  'DilleniaUPC Bold',
  'DilleniaUPC Bold Italic',
  'DilleniaUPC Italic',
  'Dimnah',
  'Dingbats',
  'Diwan Kufi',
  'Diwan Kufi Regular',
  'Diwan Mishafi',
  'Diwan Thuluth',
  'Diwan Thuluth Regular',
  'DokChampa',
  'Dotum',
  'DotumChe',
  'Droid Arabic Kufi',
  'Droid Arabic Naskh',
  'Droid Naskh Shift Alt',
  'Droid Sans',
  'Droid Sans Arabic',
  'Droid Sans Armenian',
  'Droid Sans Devanagari',
  'Droid Sans Ethiopic',
  'Droid Sans Fallback',
  'Droid Sans Georgian',
  'Droid Sans Hebrew',
  'Droid Sans Japanese',
  'Droid Sans Mono',
  'Droid Sans Tamil',
  'Droid Sans Thai',
  'Droid Serif',
  'Drugulin CLM',
  'Dutch801 Rm BT',
  'Dutch801 XBd BT',
  'Dyuthi',
  'Ebrima',
  'Ebrima Bold',
  'Eccentric Std',
  'Edwardian Script ITC',
  'Eeyek Unicode',
  'Electron',
  'Elephant',
  'Ellinia CLM',
  'EmojiOne Mozilla',
  'Engravers MT',
  'Eras Bold ITC',
  'Eras Demi ITC',
  'Eras Light ITC',
  'Eras Medium ITC',
  'Estrangelo Edessa',
  'EucrosiaUPC',
  'EucrosiaUPC Bold',
  'EucrosiaUPC Bold Italic',
  'EucrosiaUPC Italic',
  'Euphemia',
  'Euphemia UCAS',
  'Euphemia UCAS Bold',
  'Euphemia UCAS Italic',
  'EuroRoman',
  'Eurostile',
  'Ezra SIL',
  'Ezra SIL SR',
  'FangSong',
  'Farah',
  'Farah Regular',
  'Farisi',
  'Farisi Regular',
  'Felix Titling',
  'Fixed',
  'Fixed Miriam Transparent',
  'Fixedsys',
  'FontAwesome',
  'Footlight MT Light',
  'Forte',
  'Frank Ruehl CLM',
  'FrankRuehl',
  'Franklin Gothic Book',
  'Franklin Gothic Demi',
  'Franklin Gothic Demi Cond',
  'Franklin Gothic Heavy',
  'Franklin Gothic Medium',
  'Franklin Gothic Medium Cond',
  'Franklin Gothic Medium Italic',
  'Free Avant Garde',
  'Free Bookman',
  'Free Chancery',
  'Free Courier',
  'Free Helvetian',
  'Free Helvetian Condensed',
  'Free Paladin',
  'Free Schoolbook',
  'Free Times',
  'FreeMono',
  'FreeSans',
  'FreeSerif',
  'Freehand521 BT',
  'FreesiaUPC',
  'FreesiaUPC Bold',
  'FreesiaUPC Bold Italic',
  'FreesiaUPC Italic',
  'Freestyle Script',
  'French Script MT',
  'Furat',
  'Futura',
  'Futura Bold',
  'Futura Condensed ExtraBold',
  'Futura Condensed Medium',
  'Futura Md BT',
  'Futura Medium',
  'Futura Medium Italic',
  'GB18030 Bitmap',
  'GDT',
  'GENISO',
  'Gabriola',
  'Gadugi',
  'Gadugi Bold',
  'Galvji',
  'Garamond',
  'Garamond Premr Pro',
  'Garamond Premr Pro Smbd',
  'Gargi',
  'Garuda',
  'Gautami',
  'Gautami Bold',
  'Gayathri',
  'Gayathri Thin',
  'Geeza Pro',
  'Geeza Pro Bold',
  'Geeza Pro Regular',
  'Geneva',
  'Geneva CY',
  'Gentium',
  'Gentium Basic',
  'Gentium Book Basic',
  'GentiumAlt',
  'Georgia',
  'Georgia Bold',
  'Georgia Bold Italic',
  'Georgia Italic',
  'Georgia Pro',
  'Georgia Pro Black',
  'Georgia Pro Black Italic',
  'Georgia Pro Bold',
  'Georgia Pro Bold Italic',
  'Georgia Pro Cond',
  'Georgia Pro Cond Black',
  'Georgia Pro Cond Black Italic',
  'Georgia Pro Cond Bold',
  'Georgia Pro Cond Bold Italic',
  'Georgia Pro Cond Italic',
  'Georgia Pro Cond Light',
  'Georgia Pro Cond Light Italic',
  'Georgia Pro Cond Semibold',
  'Georgia Pro Cond Semibold Italic',
  'Georgia Pro Italic',
  'Georgia Pro Light',
  'Georgia Pro Light Italic',
  'Georgia Pro Semibold',
  'Georgia Pro Semibold Italic',
  'Giddyup Std',
  'Gigi',
  'Gill Sans',
  'Gill Sans Bold',
  'Gill Sans Bold Italic',
  'Gill Sans Italic',
  'Gill Sans Light',
  'Gill Sans Light Italic',
  'Gill Sans MT',
  'Gill Sans MT Condensed',
  'Gill Sans MT Ext Condensed Bold',
  'Gill Sans Nova',
  'Gill Sans Nova Bold',
  'Gill Sans Nova Bold Italic',
  'Gill Sans Nova Cond',
  'Gill Sans Nova Cond Bold',
  'Gill Sans Nova Cond Bold Italic',
  'Gill Sans Nova Cond Italic',
  'Gill Sans Nova Cond Lt',
  'Gill Sans Nova Cond Lt Italic',
  'Gill Sans Nova Cond Ultra Bold',
  'Gill Sans Nova Cond XBd',
  'Gill Sans Nova Cond XBd Italic',
  'Gill Sans Nova Italic',
  'Gill Sans Nova Light',
  'Gill Sans Nova Light Italic',
  'Gill Sans Nova Ultra Bold',
  'Gill Sans SemiBold',
  'Gill Sans SemiBold Italic',
  'Gill Sans Ultra Bold',
  'Gill Sans Ultra Bold Condensed',
  'Gill Sans UltraBold',
  'Gisha',
  'Gisha Bold',
  'Gloucester MT Extra Condensed',
  'GothicE',
  'GothicG',
  'GothicI',
  'Goudy Old Style',
  'Goudy Stout',
  'Granada',
  'Graph',
  'GreekC',
  'GreekS',
  'Gubbi',
  'Gujarati MT',
  'Gujarati MT Bold',
  'Gujarati Sangam MN',
  'Gujarati Sangam MN Bold',
  'Gulim',
  'GulimChe',
  'GungSeo',
  'GungSeo Regular',
  'Gungsuh',
  'GungsuhChe',
  'Gurmukhi MN',
  'Gurmukhi MN Bold',
  'Gurmukhi MT',
  'Gurmukhi Sangam MN',
  'Gurmukhi Sangam MN Bold',
  'Hadasim CLM',
  'Haettenschweiler',
  'Hani',
  'Hannotate SC',
  'Hannotate SC Bold',
  'Hannotate SC Regular',
  'Hannotate TC',
  'Hannotate TC Bold',
  'Hannotate TC Regular',
  'HanziPen SC',
  'HanziPen SC Bold',
  'HanziPen SC Regular',
  'HanziPen TC',
  'HanziPen TC Bold',
  'HanziPen TC Regular',
  'Haramain',
  'Harlow Solid Italic',
  'Harrington',
  'HeadLineA Regular',
  'HeadlineA',
  'Heather Script One',
  'Hei',
  'Hei Regular',
  'Heiti SC',
  'Heiti SC Light',
  'Heiti SC Medium',
  'Heiti TC',
  'Heiti TC Light',
  'Heiti TC Medium',
  'Helvetica',
  'Helvetica Bold',
  'Helvetica Bold Oblique',
  'Helvetica CY',
  'Helvetica CY Bold',
  'Helvetica CY BoldOblique',
  'Helvetica CY Oblique',
  'Helvetica CY Plain',
  'Helvetica Light',
  'Helvetica Light Oblique',
  'Helvetica Neue',
  'Helvetica Neue Bold',
  'Helvetica Neue Bold Italic',
  'Helvetica Neue Condensed Black',
  'Helvetica Neue Condensed Bold',
  'Helvetica Neue Italic',
  'Helvetica Neue Light',
  'Helvetica Neue Light Italic',
  'Helvetica Neue Medium',
  'Helvetica Neue Medium Italic',
  'Helvetica Neue Thin',
  'Helvetica Neue Thin Italic',
  'Helvetica Neue UltraLight',
  'Helvetica Neue UltraLight Italic',
  'Helvetica Oblique',
  'Herculanum',
  'Hershey-Gothic-English',
  'Hershey-Gothic-German',
  'Hershey-Gothic-Italian',
  'Hershey-Plain-Duplex',
  'Hershey-Plain-Duplex-Italic',
  'Hershey-Plain-Triplex',
  'Hershey-Plain-Triplex-Italic',
  'Hershey-Script-Complex',
  'Hershey-Script-Simplex',
  'High Tower Text',
  'Hiragino Kaku Gothic Pro',
  'Hiragino Kaku Gothic Pro W3',
  'Hiragino Kaku Gothic Pro W6',
  'Hiragino Kaku Gothic ProN',
  'Hiragino Kaku Gothic ProN W3',
  'Hiragino Kaku Gothic ProN W6',
  'Hiragino Kaku Gothic Std',
  'Hiragino Kaku Gothic Std W8',
  'Hiragino Kaku Gothic StdN',
  'Hiragino Kaku Gothic StdN W8',
  'Hiragino Maru Gothic Pro',
  'Hiragino Maru Gothic Pro W4',
  'Hiragino Maru Gothic ProN',
  'Hiragino Maru Gothic ProN W4',
  'Hiragino Mincho Pro',
  'Hiragino Mincho Pro W3',
  'Hiragino Mincho Pro W6',
  'Hiragino Mincho ProN',
  'Hiragino Mincho ProN W3',
  'Hiragino Mincho ProN W6',
  'Hiragino Sans',
  'Hiragino Sans CNS',
  'Hiragino Sans CNS W3',
  'Hiragino Sans CNS W6',
  'Hiragino Sans GB',
  'Hiragino Sans GB W3',
  'Hiragino Sans GB W6',
  'Hiragino Sans W0',
  'Hiragino Sans W1',
  'Hiragino Sans W2',
  'Hiragino Sans W3',
  'Hiragino Sans W4',
  'Hiragino Sans W5',
  'Hiragino Sans W6',
  'Hiragino Sans W7',
  'Hiragino Sans W8',
  'Hiragino Sans W9',
  'Hobo Std',
  'Hoefler Text',
  'Hoefler Text Black',
  'Hoefler Text Black Italic',
  'Hoefler Text Italic',
  'Hoefler Text Ornaments',
  'HoloLens MDL2 Assets',
  'Homa',
  'Hor',
  'IPAGothic',
  'IPAMincho',
  'IPAPGothic',
  'IPAPMincho',
  'ISOCP',
  'ISOCP2',
  'ISOCP3',
  'ISOCPEUR',
  'ISOCT',
  'ISOCT2',
  'ISOCT3',
  'ISOCTEUR',
  'ITF Devanagari',
  'ITF Devanagari Bold',
  'ITF Devanagari Book',
  'ITF Devanagari Demi',
  'ITF Devanagari Light',
  'ITF Devanagari Marathi',
  'ITF Devanagari Marathi Bold',
  'ITF Devanagari Marathi Book',
  'ITF Devanagari Marathi Demi',
  'ITF Devanagari Marathi Light',
  'ITF Devanagari Marathi Medium',
  'ITF Devanagari Medium',
  'Impact',
  'Imprint MT Shadow',
  'InaiMathi',
  'InaiMathi Bold',
  'Informal Roman',
  'Ink Free',
  'Iowan Old Style',
  'Iowan Old Style Black',
  'Iowan Old Style Black Italic',
  'Iowan Old Style Bold',
  'Iowan Old Style Bold Italic',
  'Iowan Old Style Italic',
  'Iowan Old Style Roman',
  'Iowan Old Style Titling',
  'IrisUPC',
  'IrisUPC Bold',
  'IrisUPC Bold Italic',
  'IrisUPC Italic',
  'Iskoola Pota',
  'Iskoola Pota Bold',
  'Italic',
  'ItalicC',
  'ItalicT',
  'Jamrul',
  'Japan',
  'JasmineUPC',
  'JasmineUPC Bold',
  'JasmineUPC Bold Italic',
  'JasmineUPC Italic',
  'Javanese Text',
  'Jet',
  'Jokerman',
  'Jomolhari',
  'Juice ITC',
  'KacstArt',
  'KacstBook',
  'KacstDecorative',
  'KacstDigital',
  'KacstFarsi',
  'KacstLetter',
  'KacstNaskh',
  'KacstOffice',
  'KacstOne',
  'KacstPen',
  'KacstPoster',
  'KacstQurn',
  'KacstScreen',
  'KacstTitle',
  'KacstTitleL',
  'Kai',
  'Kai Regular',
  'KaiTi',
  'Kailasa',
  'Kailasa Bold',
  'Kailasa Regular',
  'Kaiti SC',
  'Kaiti SC Black',
  'Kaiti SC Bold',
  'Kaiti SC Regular',
  'Kaiti TC',
  'Kaiti TC Black',
  'Kaiti TC Bold',
  'Kaiti TC Regular',
  'Kalapi',
  'Kalimati',
  'Kalinga',
  'Kalinga Bold',
  'Kalyani',
  'Kannada MN',
  'Kannada MN Bold',
  'Kannada Sangam MN',
  'Kannada Sangam MN Bold',
  'Kartika',
  'Kartika Bold',
  'Karumbi',
  'Kayrawan',
  'Kedage',
  'Kefa',
  'Kefa Bold',
  'Kefa Regular',
  'Keraleeyam',
  'Keter YG',
  'Keyboard',
  'Khalid',
  'Khmer MN',
  'Khmer MN Bold',
  'Khmer OS',
  'Khmer OS Battambang',
  'Khmer OS Bokor',
  'Khmer OS Content',
  'Khmer OS Fasthand',
  'Khmer OS Freehand',
  'Khmer OS Metal Chrieng',
  'Khmer OS Muol',
  'Khmer OS Muol Light',
  'Khmer OS Muol Pali',
  'Khmer OS Siemreap',
  'Khmer OS System',
  'Khmer Sangam MN',
  'Khmer UI',
  'Khmer UI Bold',
  'Kinnari',
  'Klee',
  'Klee Demibold',
  'Klee Medium',
  'KodchiangUPC',
  'KodchiangUPC Bold',
  'KodchiangUPC Bold Italic',
  'KodchiangUPC Italic',
  'Kohinoor Bangla',
  'Kohinoor Bangla Bold',
  'Kohinoor Bangla Light',
  'Kohinoor Bangla Medium',
  'Kohinoor Bangla Semibold',
  'Kohinoor Devanagari',
  'Kohinoor Devanagari Bold',
  'Kohinoor Devanagari Light',
  'Kohinoor Devanagari Medium',
  'Kohinoor Devanagari Regular',
  'Kohinoor Devanagari Semibold',
  'Kohinoor Gujarati',
  'Kohinoor Telugu',
  'Kohinoor Telugu Bold',
  'Kohinoor Telugu Light',
  'Kohinoor Telugu Medium',
  'Kohinoor Telugu Semibold',
  'Kokila',
  'Kokila Bold',
  'Kokila Bold Italic',
  'Kokila Italic',
  'Kokonor',
  'Kokonor Regular',
  'Kozuka Gothic Pr6N B',
  'Kozuka Gothic Pr6N EL',
  'Kozuka Gothic Pr6N H',
  'Kozuka Gothic Pr6N L',
  'Kozuka Gothic Pr6N M',
  'Kozuka Gothic Pr6N R',
  'Kozuka Gothic Pro B',
  'Kozuka Gothic Pro EL',
  'Kozuka Gothic Pro H',
  'Kozuka Gothic Pro L',
  'Kozuka Gothic Pro M',
  'Kozuka Gothic Pro R',
  'Kozuka Mincho Pr6N B',
  'Kozuka Mincho Pr6N EL',
  'Kozuka Mincho Pr6N H',
  'Kozuka Mincho Pr6N L',
  'Kozuka Mincho Pr6N M',
  'Kozuka Mincho Pr6N R',
  'Kozuka Mincho Pro B',
  'Kozuka Mincho Pro EL',
  'Kozuka Mincho Pro H',
  'Kozuka Mincho Pro L',
  'Kozuka Mincho Pro M',
  'Kozuka Mincho Pro R',
  'Kristen ITC',
  'Krungthep',
  'KufiStandardGK',
  'KufiStandardGK Regular',
  'Kunstler Script',
  'LKLUG',
  'Laksaman',
  'Lantinghei SC',
  'Lantinghei SC Demibold',
  'Lantinghei SC Extralight',
  'Lantinghei SC Heavy',
  'Lantinghei TC',
  'Lantinghei TC Demibold',
  'Lantinghei TC Extralight',
  'Lantinghei TC Heavy',
  'Lao MN',
  'Lao MN Bold',
  'Lao Sangam MN',
  'Lao UI',
  'Lao UI Bold',
  'LastResort',
  'Latha',
  'Latha Bold',
  'Lato',
  'Leelawadee',
  'Leelawadee Bold',
  'Leelawadee UI',
  'Leelawadee UI Bold',
  'Leelawadee UI Leelawadee UI',
  'Leelawadee UI Semilight',
  'Letter Gothic Std',
  'Levenim MT',
  'Levenim MT Bold',
  'LiHei Pro',
  'LiSong Pro',
  'Liberation Mono',
  'Liberation Sans',
  'Liberation Sans Narrow',
  'Liberation Serif',
  'Libian SC',
  'Libian SC Regular',
  'Libian TC',
  'Libian TC Regular',
  'Likhan',
  'LilyUPC',
  'LilyUPC Bold',
  'LilyUPC Bold Italic',
  'LilyUPC Italic',
  'LingWai SC',
  'LingWai SC Medium',
  'LingWai TC',
  'LingWai TC Medium',
  'Linux Biolinum Keyboard O',
  'Linux Biolinum O',
  'Linux Libertine Display O',
  'Linux Libertine Initials O',
  'Linux Libertine Mono O',
  'Linux Libertine O',
  'Lithos Pro Regular',
  'Lohit Assamese',
  'Lohit Bengali',
  'Lohit Devanagari',
  'Lohit Gujarati',
  'Lohit Gurmukhi',
  'Lohit Hindi',
  'Lohit Kannada',
  'Lohit Malayalam',
  'Lohit Marathi',
  'Lohit Nepali',
  'Lohit Odia',
  'Lohit Oriya',
  'Lohit Punjabi',
  'Lohit Tamil',
  'Lohit Tamil Classical',
  'Lohit Telugu',
  'Loma',
  'Lucida Bright',
  'Lucida Calligraphy',
  'Lucida Console',
  'Lucida Fax',
  'Lucida Grande',
  'Lucida Grande Bold',
  'Lucida Handwriting',
  'Lucida Sans',
  'Lucida Sans Typewriter',
  'Lucida Sans Unicode',
  'Luminari',
  'MS Gothic',
  'MS Mincho',
  'MS Outlook',
  'MS PGothic',
  'MS PMincho',
  'MS Reference Sans Serif',
  'MS Reference Specialty',
  'MS Sans Serif',
  'MS Serif',
  'MS UI Gothic',
  'MT Extra',
  'MV Boli',
  'Madan',
  'Magneto',
  'Maiandra GD',
  'Malayalam MN',
  'Malayalam MN Bold',
  'Malayalam Sangam MN',
  'Malayalam Sangam MN Bold',
  'Malgun Gothic',
  'Malgun Gothic Bold',
  'Malgun Gothic Semilight',
  'Mallige',
  'Mangal',
  'Mangal Bold',
  'Manjari',
  'Manjari Thin',
  'Marion',
  'Marion Bold',
  'Marion Italic',
  'Marion Regular',
  'Marker Felt',
  'Marker Felt Thin',
  'Marker Felt Wide',
  'Marlett',
  'Mashq',
  'Mashq-Bold',
  'Matura MT Script Capitals',
  'Meera',
  'Meiryo',
  'Meiryo Bold',
  'Meiryo Bold Italic',
  'Meiryo Italic',
  'Meiryo UI',
  'Meiryo UI Bold',
  'Meiryo UI Bold Italic',
  'Meiryo UI Italic',
  'Menlo',
  'Menlo Bold',
  'Menlo Bold Italic',
  'Menlo Italic',
  'Menlo Regular',
  'Mesquite Std',
  'Metal',
  'Microsoft Himalaya',
  'Microsoft JhengHei',
  'Microsoft JhengHei Bold',
  'Microsoft JhengHei Light',
  'Microsoft JhengHei Regular',
  'Microsoft JhengHei UI',
  'Microsoft JhengHei UI Bold',
  'Microsoft JhengHei UI Light',
  'Microsoft JhengHei UI Regular',
  'Microsoft New Tai Lue',
  'Microsoft New Tai Lue Bold',
  'Microsoft PhagsPa',
  'Microsoft PhagsPa Bold',
  'Microsoft Sans Serif',
  'Microsoft Tai Le',
  'Microsoft Tai Le Bold',
  'Microsoft Uighur',
  'Microsoft Uighur Bold',
  'Microsoft YaHei',
  'Microsoft YaHei Bold',
  'Microsoft YaHei Light',
  'Microsoft YaHei UI',
  'Microsoft YaHei UI Bold',
  'Microsoft YaHei UI Light',
  'Microsoft Yi Baiti',
  'MingLiU',
  'MingLiU-ExtB',
  'MingLiU_HKSCS',
  'MingLiU_HKSCS-ExtB',
  'Mingzat',
  'Minion Pro',
  'Minion Pro Cond',
  'Minion Pro Med',
  'Minion Pro SmBd',
  'Miriam',
  'Miriam CLM',
  'Miriam Fixed',
  'Miriam Mono CLM',
  'Mishafi',
  'Mishafi Gold',
  'Mishafi Gold Regular',
  'Mishafi Regular',
  'Mistral',
  'Mitra Mono',
  'Modern',
  'Modern No. 20',
  'Monaco',
  'Monaco CY',
  'Mongolian Baiti',
  'Monospac821 BT',
  'Monotxt',
  'Monotype Corsiva',
  'Montserrat',
  'Montserrat Alternates',
  'Montserrat Black',
  'Montserrat ExtraBold',
  'Montserrat ExtraLight',
  'Montserrat Light',
  'Montserrat Medium',
  'Montserrat SemiBold',
  'Montserrat Thin',
  'MoolBoran',
  'Mshtakan',
  'Mshtakan Bold',
  'Mshtakan BoldOblique',
  'Mshtakan Oblique',
  'MuktaMahee',
  'Mukti Narrow',
  'Mukti Narrow Bold',
  'Muna',
  'Muna Black',
  'Muna Bold',
  'Muna Regular',
  'Myanmar MN',
  'Myanmar MN Bold',
  'Myanmar Sangam MN',
  'Myanmar Sangam MN Bold',
  'Myanmar Text',
  'Myanmar Text Bold',
  'Myriad Arabic',
  'Myriad Arabic Black',
  'Myriad Arabic Black Italic',
  'Myriad Arabic Bold',
  'Myriad Arabic Bold Italic',
  'Myriad Arabic Italic',
  'Myriad Arabic Light',
  'Myriad Arabic Light Italic',
  'Myriad Arabic Semibold',
  'Myriad Arabic Semibold Italic',
  'Myriad Hebrew',
  'Myriad Pro',
  'Myriad Pro Cond',
  'Myriad Pro Light',
  'Myriad Web Pro',
  'NSimSun',
  'Nachlieli CLM',
  'Nada',
  'Nadeem',
  'Nadeem Regular',
  'Nagham',
  'Nakula',
  'Nanum Brush Script',
  'Nanum Gothic',
  'Nanum Myeongjo',
  'Nanum Pen Script',
  'Nanum Script',
  'NanumBarunGothic',
  'NanumGothic',
  'NanumGothic Bold',
  'NanumGothic ExtraBold',
  'NanumGothic Light',
  'NanumGothicCoding',
  'NanumGothicExtraBold',
  'NanumMyeongjo',
  'NanumMyeongjo Bold',
  'NanumMyeongjo ExtraBold',
  'NanumSquare',
  'NanumSquareRound',
  'Narkisim',
  'Navilu',
  'Nazli',
  'Neue Haas Grotesk Text Pro',
  'Neue Haas Grotesk Text Pro Bold',
  'Neue Haas Grotesk Text Pro Bold Italic',
  'Neue Haas Grotesk Text Pro Italic',
  'Neue Haas Grotesk Text Pro Medium',
  'Neue Haas Grotesk Text Pro Medium Italic',
  'New Peninim MT',
  'New Peninim MT Bold',
  'New Peninim MT Bold Inclined',
  'New Peninim MT Inclined',
  'News Gothic MT',
  'Niagara Engraved',
  'Niagara Solid',
  'Nice',
  'Nimbus Mono L',
  'Nimbus Mono PS',
  'Nimbus Roman',
  'Nimbus Roman No9 L',
  'Nimbus Sans',
  'Nimbus Sans L',
  'Nimbus Sans Narrow',
  'Nirmala UI',
  'Nirmala UI Bold',
  'Nirmala UI Semilight',
  'Norasi',
  'Noteworthy',
  'Noteworthy Bold',
  'Noteworthy Light',
  'Noto Color Emoji',
  'Noto Emoji',
  'Noto Kufi Arabic',
  'Noto Mono',
  'Noto Music',
  'Noto Naskh Arabic',
  'Noto Naskh Arabic UI',
  'Noto Nastaliq Urdu',
  'Noto Sans',
  'Noto Sans Adlam',
  'Noto Sans Adlam Unjoined',
  'Noto Sans Anatolian Hieroglyphs',
  'Noto Sans Arabic',
  'Noto Sans Arabic UI',
  'Noto Sans Armenian',
  'Noto Sans Avestan',
  'Noto Sans Balinese',
  'Noto Sans Bamum',
  'Noto Sans Bassa Vah',
  'Noto Sans Batak',
  'Noto Sans Bengali',
  'Noto Sans Bengali UI',
  'Noto Sans Bhaiksuki',
  'Noto Sans Brahmi',
  'Noto Sans Buginese',
  'Noto Sans Buhid',
  'Noto Sans CJK HK',
  'Noto Sans CJK HK Black',
  'Noto Sans CJK HK DemiLight',
  'Noto Sans CJK HK Light',
  'Noto Sans CJK HK Medium',
  'Noto Sans CJK HK Thin',
  'Noto Sans CJK JP',
  'Noto Sans CJK JP Black',
  'Noto Sans CJK JP DemiLight',
  'Noto Sans CJK JP Light',
  'Noto Sans CJK JP Medium',
  'Noto Sans CJK JP Thin',
  'Noto Sans CJK KR',
  'Noto Sans CJK KR Black',
  'Noto Sans CJK KR DemiLight',
  'Noto Sans CJK KR Light',
  'Noto Sans CJK KR Medium',
  'Noto Sans CJK KR Thin',
  'Noto Sans CJK SC',
  'Noto Sans CJK SC Black',
  'Noto Sans CJK SC DemiLight',
  'Noto Sans CJK SC Light',
  'Noto Sans CJK SC Medium',
  'Noto Sans CJK SC Regular',
  'Noto Sans CJK SC Thin',
  'Noto Sans CJK TC',
  'Noto Sans CJK TC Black',
  'Noto Sans CJK TC DemiLight',
  'Noto Sans CJK TC Light',
  'Noto Sans CJK TC Medium',
  'Noto Sans CJK TC Thin',
  'Noto Sans Canadian Aboriginal',
  'Noto Sans Carian',
  'Noto Sans Caucasian Albanian',
  'Noto Sans Chakma',
  'Noto Sans Cham',
  'Noto Sans Cherokee',
  'Noto Sans Coptic',
  'Noto Sans Cuneiform',
  'Noto Sans Cypriot',
  'Noto Sans Deseret',
  'Noto Sans Devanagari',
  'Noto Sans Devanagari UI',
  'Noto Sans Display',
  'Noto Sans Duployan',
  'Noto Sans Egyptian Hieroglyphs',
  'Noto Sans Elbasan',
  'Noto Sans Ethiopic',
  'Noto Sans Georgian',
  'Noto Sans Glagolitic',
  'Noto Sans Gothic',
  'Noto Sans Grantha',
  'Noto Sans Gujarati',
  'Noto Sans Gujarati UI',
  'Noto Sans Gurmukhi',
  'Noto Sans Gurmukhi UI',
  'Noto Sans Hanunoo',
  'Noto Sans Hatran',
  'Noto Sans Hebrew',
  'Noto Sans Imperial Aramaic',
  'Noto Sans Inscriptional Pahlavi',
  'Noto Sans Inscriptional Parthian',
  'Noto Sans JP Regular',
  'Noto Sans Javanese',
  'Noto Sans KR Regular',
  'Noto Sans Kaithi',
  'Noto Sans Kannada',
  'Noto Sans Kannada UI',
  'Noto Sans Kayah Li',
  'Noto Sans Kharoshthi',
  'Noto Sans Khmer',
  'Noto Sans Khmer UI',
  'Noto Sans Khojki',
  'Noto Sans Khudawadi',
  'Noto Sans Lao',
  'Noto Sans Lao UI',
  'Noto Sans Lepcha',
  'Noto Sans Limbu',
  'Noto Sans Linear A',
  'Noto Sans Linear B',
  'Noto Sans Lisu',
  'Noto Sans Lycian',
  'Noto Sans Lydian',
  'Noto Sans Mahajani',
  'Noto Sans Malayalam',
  'Noto Sans Malayalam UI',
  'Noto Sans Mandaic',
  'Noto Sans Manichaean',
  'Noto Sans Marchen',
  'Noto Sans Math',
  'Noto Sans Meetei Mayek',
  'Noto Sans Mende Kikakui',
  'Noto Sans Meroitic',
  'Noto Sans Miao',
  'Noto Sans Modi',
  'Noto Sans Mongolian',
  'Noto Sans Mono',
  'Noto Sans Mono CJK HK',
  'Noto Sans Mono CJK JP',
  'Noto Sans Mono CJK KR',
  'Noto Sans Mono CJK SC',
  'Noto Sans Mono CJK TC',
  'Noto Sans Mro',
  'Noto Sans Multani',
  'Noto Sans Myanmar',
  'Noto Sans Myanmar UI',
  'Noto Sans NKo',
  'Noto Sans Nabataean',
  'Noto Sans New Tai Lue',
  'Noto Sans Newa',
  'Noto Sans Ogham',
  'Noto Sans Ol Chiki',
  'Noto Sans Old Hungarian',
  'Noto Sans Old Italic',
  'Noto Sans Old North Arabian',
  'Noto Sans Old Permic',
  'Noto Sans Old Persian',
  'Noto Sans Old South Arabian',
  'Noto Sans Old Turkic',
  'Noto Sans Oriya',
  'Noto Sans Oriya UI',
  'Noto Sans Osage',
  'Noto Sans Osmanya',
  'Noto Sans Pahawh Hmong',
  'Noto Sans Palmyrene',
  'Noto Sans Pau Cin Hau',
  'Noto Sans Phags Pa',
  'Noto Sans PhagsPa',
  'Noto Sans Phoenician',
  'Noto Sans Psalter Pahlavi',
  'Noto Sans Rejang',
  'Noto Sans Runic',
  'Noto Sans SC Regular',
  'Noto Sans Samaritan',
  'Noto Sans Saurashtra',
  'Noto Sans Sharada',
  'Noto Sans Shavian',
  'Noto Sans Sinhala',
  'Noto Sans Sinhala UI',
  'Noto Sans Sora Sompeng',
  'Noto Sans Sundanese',
  'Noto Sans Syloti Nagri',
  'Noto Sans Symbols',
  'Noto Sans Symbols2',
  'Noto Sans Syriac',
  'Noto Sans Syriac Eastern',
  'Noto Sans Syriac Estrangela',
  'Noto Sans Syriac Western',
  'Noto Sans TC Regular',
  'Noto Sans Tagalog',
  'Noto Sans Tagbanwa',
  'Noto Sans Tai Le',
  'Noto Sans Tai Tham',
  'Noto Sans Tai Viet',
  'Noto Sans Takri',
  'Noto Sans Tamil',
  'Noto Sans Tamil UI',
  'Noto Sans Telugu',
  'Noto Sans Telugu UI',
  'Noto Sans Thaana',
  'Noto Sans Thai',
  'Noto Sans Thai UI',
  'Noto Sans Tibetan',
  'Noto Sans Tifinagh',
  'Noto Sans Tirhuta',
  'Noto Sans UI',
  'Noto Sans Ugaritic',
  'Noto Sans Vai',
  'Noto Sans Warang Citi',
  'Noto Sans Yi',
  'Noto Serif',
  'Noto Serif Ahom',
  'Noto Serif Armenian',
  'Noto Serif Balinese',
  'Noto Serif Bengali',
  'Noto Serif CJK JP',
  'Noto Serif CJK JP Black',
  'Noto Serif CJK JP ExtraLight',
  'Noto Serif CJK JP Light',
  'Noto Serif CJK JP Medium',
  'Noto Serif CJK JP SemiBold',
  'Noto Serif CJK KR',
  'Noto Serif CJK KR Black',
  'Noto Serif CJK KR ExtraLight',
  'Noto Serif CJK KR Light',
  'Noto Serif CJK KR Medium',
  'Noto Serif CJK KR SemiBold',
  'Noto Serif CJK SC',
  'Noto Serif CJK SC Black',
  'Noto Serif CJK SC ExtraLight',
  'Noto Serif CJK SC Light',
  'Noto Serif CJK SC Medium',
  'Noto Serif CJK SC SemiBold',
  'Noto Serif CJK TC',
  'Noto Serif CJK TC Black',
  'Noto Serif CJK TC ExtraLight',
  'Noto Serif CJK TC Light',
  'Noto Serif CJK TC Medium',
  'Noto Serif CJK TC SemiBold',
  'Noto Serif Devanagari',
  'Noto Serif Display',
  'Noto Serif Ethiopic',
  'Noto Serif Georgian',
  'Noto Serif Gujarati',
  'Noto Serif Gurmukhi',
  'Noto Serif Hebrew',
  'Noto Serif Kannada',
  'Noto Serif Khmer',
  'Noto Serif Lao',
  'Noto Serif Malayalam',
  'Noto Serif Myanmar',
  'Noto Serif Sinhala',
  'Noto Serif Tamil',
  'Noto Serif Tamil Slanted',
  'Noto Serif Telugu',
  'Noto Serif Thai',
  'Noto Serif Tibetan',
  'Nueva Std',
  'Nueva Std Cond',
  'Nuosu SIL',
  'Nyala',
  'OCR A Extended',
  'OCR A Std',
  'OCR-A BT',
  'OCR-B 10 BT',
  'Old English Text MT',
  'Onyx',
  'OpenSymbol',
  'Optima',
  'Optima Bold',
  'Optima Bold Italic',
  'Optima ExtraBlack',
  'Optima Italic',
  'Optima Regular',
  'Orator Std',
  'Oriya MN',
  'Oriya MN Bold',
  'Oriya Sangam MN',
  'Oriya Sangam MN Bold',
  'Osaka',
  'Osaka Mono',
  'Osaka-Mono',
  'Ostorah',
  'Ouhod',
  'Ouhod-Bold',
  'Ouverture script',
  'Overpass',
  'P052',
  'PCMyungjo',
  'PCMyungjo Regular',
  'PMingLiU',
  'PMingLiU-ExtB',
  'PSL Ornanong Pro',
  'PT Mono',
  'PT Mono Bold',
  'PT Sans',
  'PT Sans Bold',
  'PT Sans Bold Italic',
  'PT Sans Caption',
  'PT Sans Caption Bold',
  'PT Sans Italic',
  'PT Sans Narrow',
  'PT Sans Narrow Bold',
  'PT Serif',
  'PT Serif Bold',
  'PT Serif Bold Italic',
  'PT Serif Caption',
  'PT Serif Caption Italic',
  'PT Serif Italic',
  'Padauk',
  'Padauk Book',
  'Pagul',
  'PakType Naskh Basic',
  'PakTypeNaqsh',
  'PakTypeTehreer',
  'Palace Script MT',
  'Palatino',
  'Palatino Bold',
  'Palatino Bold Italic',
  'Palatino Italic',
  'Palatino Linotype',
  'Palatino Linotype Bold',
  'Palatino Linotype Bold Italic',
  'Palatino Linotype Italic',
  'PanRoman',
  'Papyrus',
  'Papyrus Condensed',
  'Parchment',
  'Perpetua',
  'Perpetua Titling MT',
  'Petra',
  'Phetsarath OT',
  'Phosphate',
  'Phosphate Inline',
  'Phosphate Solid',
  'PilGi',
  'PilGi Regular',
  'PingFang HK',
  'PingFang HK Light',
  'PingFang HK Medium',
  'PingFang HK Regular',
  'PingFang HK Semibold',
  'PingFang HK Thin',
  'PingFang HK Ultralight',
  'PingFang SC',
  'PingFang SC Light',
  'PingFang SC Medium',
  'PingFang SC Regular',
  'PingFang SC Semibold',
  'PingFang SC Thin',
  'PingFang SC Ultralight',
  'PingFang TC',
  'PingFang TC Light',
  'PingFang TC Medium',
  'PingFang TC Regular',
  'PingFang TC Semibold',
  'PingFang TC Thin',
  'PingFang TC Ultralight',
  'Plantagenet Cherokee',
  'Playbill',
  'Poor Richard',
  'Poplar Std',
  'Pothana2000',
  'Prestige Elite Std',
  'Pristina',
  'Proxy 1',
  'Proxy 2',
  'Proxy 3',
  'Proxy 4',
  'Proxy 5',
  'Proxy 6',
  'Proxy 7',
  'Proxy 8',
  'Proxy 9',
  'Purisa',
  'Quicksand',
  'Quicksand Light',
  'Quicksand Medium',
  'Raanana',
  'Raanana Bold',
  'Raavi',
  'Raavi Bold',
  'Rachana',
  'Rage Italic',
  'RaghuMalayalam',
  'RaghuMalayalamSans',
  'Rasa',
  'Rasa Light',
  'Rasa Medium',
  'Rasa SemiBold',
  'Rasheeq',
  'Rasheeq-Bold',
  'Ravie',
  'Rehan',
  'Rekha',
  'Roboto',
  'Roboto Condensed',
  'Rockwell',
  'Rockwell Bold',
  'Rockwell Bold Italic',
  'Rockwell Condensed',
  'Rockwell Extra Bold',
  'Rockwell Italic',
  'Rockwell Nova',
  'Rockwell Nova Bold',
  'Rockwell Nova Bold Italic',
  'Rockwell Nova Cond',
  'Rockwell Nova Cond Bold',
  'Rockwell Nova Cond Bold Italic',
  'Rockwell Nova Cond Italic',
  'Rockwell Nova Cond Light',
  'Rockwell Nova Cond Light Italic',
  'Rockwell Nova Extra Bold',
  'Rockwell Nova Extra Bold Italic',
  'Rockwell Nova Italic',
  'Rockwell Nova Light Italic',
  'Rockwell Nova Rockwell',
  'Rod',
  'Roman',
  'RomanC',
  'RomanD',
  'RomanS',
  'RomanT',
  'Romantic',
  'Rosewood Std Regular',
  'STFangSong',
  'STFangsong',
  'STHeiti',
  'STIX',
  'STIX Math',
  'STIX Two Math',
  'STIX Two Text',
  'STIX Two Text Bold',
  'STIX Two Text Bold Italic',
  'STIX Two Text Italic',
  'STIXGeneral',
  'STIXGeneral-Bold',
  'STIXGeneral-BoldItalic',
  'STIXGeneral-Italic',
  'STIXGeneral-Regular',
  'STIXIntegralsD',
  'STIXIntegralsD-Bold',
  'STIXIntegralsD-Regular',
  'STIXIntegralsSm',
  'STIXIntegralsSm-Bold',
  'STIXIntegralsSm-Regular',
  'STIXIntegralsUp',
  'STIXIntegralsUp-Bold',
  'STIXIntegralsUp-Regular',
  'STIXIntegralsUpD',
  'STIXIntegralsUpD-Bold',
  'STIXIntegralsUpD-Regular',
  'STIXIntegralsUpSm',
  'STIXIntegralsUpSm-Bold',
  'STIXIntegralsUpSm-Regular',
  'STIXNonUnicode',
  'STIXNonUnicode-Bold',
  'STIXNonUnicode-BoldItalic',
  'STIXNonUnicode-Italic',
  'STIXNonUnicode-Regular',
  'STIXSizeFiveSym',
  'STIXSizeFiveSym-Regular',
  'STIXSizeFourSym',
  'STIXSizeFourSym-Bold',
  'STIXSizeFourSym-Regular',
  'STIXSizeOneSym',
  'STIXSizeOneSym-Bold',
  'STIXSizeOneSym-Regular',
  'STIXSizeThreeSym',
  'STIXSizeThreeSym-Bold',
  'STIXSizeThreeSym-Regular',
  'STIXSizeTwoSym',
  'STIXSizeTwoSym-Bold',
  'STIXSizeTwoSym-Regular',
  'STIXVariants',
  'STIXVariants-Bold',
  'STIXVariants-Regular',
  'STKaiti',
  'STSong',
  'STXihei',
  'Saab',
  'Sahadeva',
  'Sakkal Majalla',
  'Sakkal Majalla Bold',
  'Salem',
  'Samanata',
  'Samyak Devanagari',
  'Samyak Gujarati',
  'Samyak Malayalam',
  'Samyak Tamil',
  'Sana',
  'Sana Regular',
  'SansSerif',
  'Sanskrit Text',
  'Sarai',
  'Sathu',
  'Savoye LET',
  'Savoye LET Plain CC.:1.0',
  'Savoye LET Plain:1.0',
  'Sawasdee',
  'Scheherazade',
  'Script',
  'Script MT Bold',
  'ScriptC',
  'ScriptS',
  'Segoe MDL2 Assets',
  'Segoe Print',
  'Segoe Print Bold',
  'Segoe Pseudo',
  'Segoe Script',
  'Segoe Script Bold',
  'Segoe UI',
  'Segoe UI Black',
  'Segoe UI Black Italic',
  'Segoe UI Bold',
  'Segoe UI Bold Italic',
  'Segoe UI Emoji',
  'Segoe UI Historic',
  'Segoe UI Italic',
  'Segoe UI Light',
  'Segoe UI Light Italic',
  'Segoe UI Semibold',
  'Segoe UI Semibold Italic',
  'Segoe UI Semilight',
  'Segoe UI Semilight Italic',
  'Segoe UI Symbol',
  'Seravek',
  'Seravek Bold',
  'Seravek Bold Italic',
  'Seravek ExtraLight',
  'Seravek ExtraLight Italic',
  'Seravek Italic',
  'Seravek Light',
  'Seravek Light Italic',
  'Seravek Medium',
  'Seravek Medium Italic',
  'Shado',
  'Sharjah',
  'Shofar',
  'Shonar Bangla',
  'Shonar Bangla Bold',
  'Showcard Gothic',
  'Shree Devanagari 714',
  'Shree Devanagari 714 Bold',
  'Shree Devanagari 714 Bold Italic',
  'Shree Devanagari 714 Italic',
  'Shruti',
  'Shruti Bold',
  'SignPainter',
  'SignPainter-HouseScript',
  'SignPainter-HouseScript Semibold',
  'Silom',
  'SimHei',
  'SimSun',
  'SimSun-ExtB',
  'Simple CLM',
  'Simplex',
  'Simplified Arabic',
  'Simplified Arabic Bold',
  'Simplified Arabic Fixed',
  'Sindbad',
  'Sinhala MN',
  'Sinhala MN Bold',
  'Sinhala Sangam MN',
  'Sinhala Sangam MN Bold',
  'Sitka',
  'Sitka Banner',
  'Sitka Banner Bold',
  'Sitka Banner Bold Italic',
  'Sitka Banner Italic',
  'Sitka Display',
  'Sitka Display Bold',
  'Sitka Display Bold Italic',
  'Sitka Display Italic',
  'Sitka Heading',
  'Sitka Heading Bold',
  'Sitka Heading Bold Italic',
  'Sitka Heading Italic',
  'Sitka Small',
  'Sitka Small Bold',
  'Sitka Small Bold Italic',
  'Sitka Small Italic',
  'Sitka Subheading',
  'Sitka Subheading Bold',
  'Sitka Subheading Bold Italic',
  'Sitka Subheading Italic',
  'Sitka Text',
  'Sitka Text Bold',
  'Sitka Text Bold Italic',
  'Sitka Text Italic',
  'Skia',
  'Skia Black',
  'Skia Black Condensed',
  'Skia Black Extended',
  'Skia Bold',
  'Skia Condensed',
  'Skia Extended',
  'Skia Light',
  'Skia Light Condensed',
  'Skia Light Extended',
  'Skia Regular',
  'Small Fonts',
  'Snap ITC',
  'Snell Roundhand',
  'Snell Roundhand Black',
  'Snell Roundhand Bold',
  'Song',
  'Songti SC',
  'Songti SC Black',
  'Songti SC Bold',
  'Songti SC Light',
  'Songti SC Regular',
  'Songti TC',
  'Songti TC Bold',
  'Songti TC Light',
  'Songti TC Regular',
  'Source Code Pro',
  'Source Code Pro Black',
  'Source Code Pro ExtraLight',
  'Source Code Pro Light',
  'Source Code Pro Medium',
  'Source Code Pro Semibold',
  'Source Han Sans CN',
  'Source Han Sans CN Bold',
  'Source Han Sans CN ExtraLight',
  'Source Han Sans CN Heavy',
  'Source Han Sans CN Light',
  'Source Han Sans CN Medium',
  'Source Han Sans CN Normal',
  'Source Han Sans TW',
  'Source Han Serif CN',
  'Source Han Serif TW',
  'Square721 BT',
  'Stam Ashkenaz CLM',
  'Stam Sefarad CLM',
  'Standard Symbols L',
  'Standard Symbols PS',
  'Stencil',
  'Stencil Std',
  'Stylus BT',
  'Sukhumvit Set',
  'Sukhumvit Set Bold',
  'Sukhumvit Set Light',
  'Sukhumvit Set Medium',
  'Sukhumvit Set Semi Bold',
  'Sukhumvit Set Text',
  'Sukhumvit Set Thin',
  'SuperFrench',
  'Superclarendon',
  'Superclarendon Black',
  'Superclarendon Black Italic',
  'Superclarendon Bold',
  'Superclarendon Bold Italic',
  'Superclarendon Italic',
  'Superclarendon Light',
  'Superclarendon Light Italic',
  'Superclarendon Regular',
  'Suruma',
  'Swis721 BT',
  'Swis721 BdCnOul BT',
  'Swis721 BdOul BT',
  'Swis721 Blk BT',
  'Swis721 BlkCn BT',
  'Swis721 BlkEx BT',
  'Swis721 BlkOul BT',
  'Swis721 Cn BT',
  'Swis721 Ex BT',
  'Swis721 Hv BT',
  'Swis721 Lt BT',
  'Swis721 LtCn BT',
  'Swis721 LtEx BT',
  'Syastro',
  'Sylfaen',
  'Symap',
  'Symath',
  'Symbol',
  'Symbola',
  'Symeteo',
  'Symusic',
  'System',
  'System Font Bold',
  'System Font Regular',
  'Tahoma',
  'Tahoma Bold',
  'Tahoma Negreta',
  'TakaoPGothic',
  'Tamil MN',
  'Tamil MN Bold',
  'Tamil Sangam MN',
  'Tamil Sangam MN Bold',
  'Tarablus',
  'TeamViewer8',
  'Teams',
  'Technic',
  'TechnicBold',
  'TechnicLite',
  'Tekton Pro',
  'Tekton Pro Cond',
  'Tekton Pro Ext',
  'Telugu MN',
  'Telugu MN Bold',
  'Telugu Sangam MN',
  'Telugu Sangam MN Bold',
  'Tempus Sans ITC',
  'Terminal',
  'Thabit',
  'Tholoth',
  'Thonburi',
  'Thonburi Bold',
  'Thonburi Light',
  'Tibetan Machine Uni',
  'Times',
  'Times Bold',
  'Times Bold Italic',
  'Times CY',
  'Times Italic',
  'Times New Roman',
  'Times New Roman Baltic',
  'Times New Roman Bold',
  'Times New Roman Bold Italic',
  'Times New Roman CE',
  'Times New Roman CYR',
  'Times New Roman Cyr',
  'Times New Roman Greek',
  'Times New Roman Italic',
  'Times New Roman TUR',
  'Times Roman',
  'Tinos',
  'Titr',
  'Tlwg Mono',
  'Tlwg Typewriter',
  'Tlwg Typist',
  'Tlwg Typo',
  'TlwgMono',
  'TlwgTypewriter',
  'Toppan Bunkyu Gothic',
  'Toppan Bunkyu Gothic Demibold',
  'Toppan Bunkyu Gothic Regular',
  'Toppan Bunkyu Midashi Gothic',
  'Toppan Bunkyu Midashi Gothic Extrabold',
  'Toppan Bunkyu Midashi Mincho',
  'Toppan Bunkyu Midashi Mincho Extrabold',
  'Toppan Bunkyu Mincho',
  'Toppan Bunkyu Mincho Regular',
  'Traditional Arabic',
  'Traditional Arabic Bold',
  'Trajan Pro',
  'Trattatello',
  'Trebuchet MS',
  'Trebuchet MS Bold',
  'Trebuchet MS Bold Italic',
  'Trebuchet MS Italic',
  'Tsukushi A Round Gothic',
  'Tsukushi A Round Gothic Bold',
  'Tsukushi A Round Gothic Regular',
  'Tsukushi B Round Gothic',
  'Tsukushi B Round Gothic Bold',
  'Tsukushi B Round Gothic Regular',
  'Tunga',
  'Tunga Bold',
  'Tw Cen MT',
  'Tw Cen MT Condensed',
  'Tw Cen MT Condensed Extra Bold',
  'Twemoji Mozilla',
  'Txt',
  'UD Digi Kyokasho',
  'UD Digi Kyokasho N-B',
  'UD Digi Kyokasho N-R',
  'UD Digi Kyokasho NK-B',
  'UD Digi Kyokasho NK-R',
  'UD Digi Kyokasho NP-B',
  'UD Digi Kyokasho NP-R',
  'UKIJ 3D',
  'UKIJ Basma',
  'UKIJ Bom',
  'UKIJ CJK',
  'UKIJ Chechek',
  'UKIJ Chiwer Kesme',
  'UKIJ Diwani',
  'UKIJ Diwani Kawak',
  'UKIJ Diwani Tom',
  'UKIJ Diwani Yantu',
  'UKIJ Ekran',
  'UKIJ Elipbe',
  'UKIJ Elipbe_Chekitlik',
  'UKIJ Esliye',
  'UKIJ Esliye Chiwer',
  'UKIJ Esliye Neqish',
  'UKIJ Esliye Qara',
  'UKIJ Esliye Tom',
  'UKIJ Imaret',
  'UKIJ Inchike',
  'UKIJ Jelliy',
  'UKIJ Junun',
  'UKIJ Kawak',
  'UKIJ Kawak 3D',
  'UKIJ Kesme',
  'UKIJ Kesme Tuz',
  'UKIJ Kufi',
  'UKIJ Kufi 3D',
  'UKIJ Kufi Chiwer',
  'UKIJ Kufi Gul',
  'UKIJ Kufi Kawak',
  'UKIJ Kufi Tar',
  'UKIJ Kufi Uz',
  'UKIJ Kufi Yay',
  'UKIJ Kufi Yolluq',
  'UKIJ Mejnun',
  'UKIJ Mejnuntal',
  'UKIJ Merdane',
  'UKIJ Moy Qelem',
  'UKIJ Nasq',
  'UKIJ Nasq Zilwa',
  'UKIJ Orqun Basma',
  'UKIJ Orqun Yazma',
  'UKIJ Orxun-Yensey',
  'UKIJ Qara',
  'UKIJ Qolyazma',
  'UKIJ Qolyazma Tez',
  'UKIJ Qolyazma Tuz',
  'UKIJ Qolyazma Yantu',
  'UKIJ Ruqi',
  'UKIJ Saet',
  'UKIJ Sulus',
  'UKIJ Sulus Tom',
  'UKIJ Teng',
  'UKIJ Tiken',
  'UKIJ Title',
  'UKIJ Tor',
  'UKIJ Tughra',
  'UKIJ Tuz',
  'UKIJ Tuz Basma',
  'UKIJ Tuz Gezit',
  'UKIJ Tuz Kitab',
  'UKIJ Tuz Neqish',
  'UKIJ Tuz Qara',
  'UKIJ Tuz Tom',
  'UKIJ Tuz Tor',
  'UKIJ Zilwa',
  'UKIJ_Mac Basma',
  'UKIJ_Mac Ekran',
  'URW Bookman',
  'URW Bookman L',
  'URW Chancery L',
  'URW Gothic',
  'URW Gothic L',
  'URW Palladio L',
  'Ubuntu',
  'Ubuntu Condensed',
  'Ubuntu Light',
  'Ubuntu Mono',
  'Ubuntu Thin',
  'Umpush',
  'UnBatang',
  'UnDotum',
  'Unifont',
  'Unifont CSUR',
  'Unifont Upper',
  'Unikurd Web',
  'UniversalMath1 BT',
  'Urdu Typesetting',
  'Urdu Typesetting Bold',
  'Uroob',
  'Utopia',
  'Utsaah',
  'Utsaah Bold',
  'Utsaah Bold Italic',
  'Utsaah Italic',
  'VL Gothic',
  'VL PGothic',
  'Vani',
  'Vani Bold',
  'Vemana2000',
  'Verdana',
  'Verdana Bold',
  'Verdana Bold Italic',
  'Verdana Italic',
  'Verdana Pro',
  'Verdana Pro Black',
  'Verdana Pro Black Italic',
  'Verdana Pro Bold',
  'Verdana Pro Bold Italic',
  'Verdana Pro Cond',
  'Verdana Pro Cond Black',
  'Verdana Pro Cond Black Italic',
  'Verdana Pro Cond Bold',
  'Verdana Pro Cond Bold Italic',
  'Verdana Pro Cond Italic',
  'Verdana Pro Cond Light',
  'Verdana Pro Cond Light Italic',
  'Verdana Pro Cond SemiBold',
  'Verdana Pro Cond SemiBold Italic',
  'Verdana Pro Italic',
  'Verdana Pro Light',
  'Verdana Pro Light Italic',
  'Verdana Pro SemiBold',
  'Verdana Pro SemiBold Italic',
  'Vijaya',
  'Vijaya Bold',
  'Viner Hand ITC',
  'Vineta BT',
  'Vivaldi',
  'Vladimir Script',
  'Vrinda',
  'Vrinda Bold',
  'WP Arabic Sihafa',
  'WP ArabicScript Sihafa',
  'WP CyrillicA',
  'WP CyrillicB',
  'WP Greek Century',
  'WP Greek Courier',
  'WP Greek Helve',
  'WP Hebrew David',
  'WP MultinationalA Courier',
  'WP MultinationalA Helve',
  'WP MultinationalA Roman',
  'WP MultinationalB Courier',
  'WP MultinationalB Helve',
  'WP MultinationalB Roman',
  'WST_Czec',
  'WST_Engl',
  'WST_Fren',
  'WST_Germ',
  'WST_Ital',
  'WST_Span',
  'WST_Swed',
  'Waree',
  'Waseem',
  'Waseem Light',
  'Waseem Regular',
  'Wawati SC',
  'Wawati SC Regular',
  'Wawati TC',
  'Wawati TC Regular',
  'Webdings',
  'Weibei SC',
  'Weibei SC Bold',
  'Weibei TC',
  'Weibei TC Bold',
  'WenQuanYi Micro Hei',
  'WenQuanYi Micro Hei Mono',
  'WenQuanYi Zen Hei',
  'WenQuanYi Zen Hei Mono',
  'WenQuanYi Zen Hei Sharp',
  'Westminster',
  'Wide Latin',
  'Wingdings',
  'Wingdings 2',
  'Wingdings 3',
  'Xingkai SC',
  'Xingkai SC Bold',
  'Xingkai SC Light',
  'Xingkai TC',
  'Xingkai TC Bold',
  'Xingkai TC Light',
  'Yehuda CLM',
  'Yrsa',
  'Yrsa Light',
  'Yrsa Medium',
  'Yrsa SemiBold',
  'Yu Gothic',
  'Yu Gothic Bold',
  'Yu Gothic Light',
  'Yu Gothic Medium',
  'Yu Gothic Regular',
  'Yu Gothic UI',
  'Yu Gothic UI Bold',
  'Yu Gothic UI Light',
  'Yu Gothic UI Regular',
  'Yu Gothic UI Semibold',
  'Yu Gothic UI Semilight',
  'Yu Mincho',
  'Yu Mincho Demibold',
  'Yu Mincho Light',
  'Yu Mincho Regular',
  'YuGothic',
  'YuGothic Bold',
  'YuGothic Medium',
  'YuKyokasho',
  'YuKyokasho Bold',
  'YuKyokasho Medium',
  'YuKyokasho Yoko',
  'YuKyokasho Yoko Bold',
  'YuKyokasho Yoko Medium',
  'YuMincho',
  'YuMincho +36p Kana',
  'YuMincho +36p Kana Demibold',
  'YuMincho +36p Kana Extrabold',
  'YuMincho +36p Kana Medium',
  'YuMincho Demibold',
  'YuMincho Extrabold',
  'YuMincho Medium',
  'Yuanti SC',
  'Yuanti SC Bold',
  'Yuanti SC Light',
  'Yuanti SC Regular',
  'Yuanti TC',
  'Yuanti TC Bold',
  'Yuanti TC Light',
  'Yuanti TC Regular',
  'Yuppy SC',
  'Yuppy SC Regular',
  'Yuppy TC',
  'Yuppy TC Regular',
  'Z003',
  'ZWAdobeF',
  'Zapf Dingbats',
  'Zapfino',
  'aakar',
  'cmex10',
  'cmmi10',
  'cmr10',
  'cmsy10',
  'esint10',
  'eufm10',
  'mry_KacstQurn',
  'msam10',
  'msbm10',
  'ori1Uni',
  'padmaa',
  'padmaa-Bold.1.1',
  'padmmaa',
  'rsfs10',
  'utkal',
  'wasy10',
  'מרים',
  'गार्गी',
  'नालिमाटी',
  'অনি Dvf',
  'মিত্র',
  'মুক্তি',
  'মুক্তি পাতনা',
  '宋体',
  '微软雅黑',
  '新細明體',
  '細明體',
  '굴림',
  '굴림체',
  '바탕',
  'ＭＳ ゴシック',
  'ＭＳ 明朝',
  'ＭＳ Ｐゴシック',
  'ＭＳ Ｐ明朝'
]

async function checkFontExists(fontName) {
  try {
    await document.fonts.load('1em ' + fontName)
    if (document.fonts.check('1em ' + fontName)) {
      return true
    }
  } catch (error) {}

  return false
}

// 获取检测到的字体列表
export async function getEnabledFontList() {
  const existsList = []
  for (const font of ALL_FONTS) {
    const exists = await checkFontExists(font)
    if (exists) existsList.push(font)
  }
  return existsList.join()
}

export const FONT_FAMILY_LIST = [
  'Comic Sans MS Bold',
  'Comic Sans',
  'Caurier Regular',
  'Fixedsys Regular',
  'Gabriola Regular',
  'HoloLens MDL2 Assets Regular',
  'Impact Regular',
  'Javanese Text Regular',
  'Leelawadee UI',
  'Lucida Console Regular',
  'Lucida Sans Unicode Regular',
  'Microsoft Himalaya Regular',
  'Microsoft PhangsPa',
  'Microsoft Sans Serif Regular',
  'Microsoft YaHei UI',
  'Microsoft Yi Baiti Regular',
  'MingLiu_HKSCS-ExtB Regular',
  'MingLiu-ExtB Regular',
  'Modern Regular',
  'Mongolia Baiti Regular',
  'MS Gothic Regular',
  'MS PGothic Regular',
  'MS Sans Serif Regular',
  'MS Serif Regular',
  'MS UI Gothic Regular',
  'MV Boli Regular',
  'Nimarla UI',
  'Myanmar Tet',
  'Nirmala UI',
  'NSimSun Regular',
  'PMingLiU-ExtB Regular',
  'Roman Regular',
  'Script Regular',
  'Segoe MDL2 Assets Regular',
  'Segoe UI Emoji Regular',
  'Segoe UI Historic Regular',
  'Segoe UI Symbol Regular',
  'SimSun Regular',
  'SimSun-ExtB Regular',
  'Sitka Banner',
  'Sitka Display',
  'Sitka Heading',
  'Sitka Subheading',
  'Sitka Text',
  'Small Fonts Regular',
  'Sylfaen Regular',
  'Symbol Regular',
  'System Bold',
  'Terminal',
  'Webdings Regular',
  'Wingdings Regular',
  'Yu Gothic UI',
  'Calibri Light',
  'Courier',
  'Fixedsys',
  'Franklin Gothic Medium',
  'HoloLens MDL2 Assets',
  'Leelawadee UI Semilight',
  'MS Sans Serif',
  'MS Serif',
  'Malgun Gothic Semilight',
  'Microsoft JhengHei Light',
  'Microsoft JhengHei UI Light',
  'Microsoft YaHei Light',
  'Microsoft YaHei UI Light',
  'Modern',
  'NSimsun',
  'Nirmala UI Semilight',
  'Roman',
  'Script',
  'Segoe MDL2 Assets',
  'Segoe UI Black',
  'Segoe UI Historic',
  'Segoe UI Light',
  'Segoe UI Semibold',
  'Segoe UI Semilight',
  'Small Fonts',
  'System',
  'Wingdings',
  'Yu Gothic Light',
  'Yu Gothic Medium',
  'Yu Gothic UI Light',
  'Yu Gothic UI Semibold',
  'Yu Gothic UI Semilight',
  'Al Bayan',
  'Al Nile',
  'Al Tarikh',
  'American Typewriter',
  'Andale Mono',
  'Apple Braille',
  'Apple Chancery',
  'Apple Color Emoji',
  'Apple SD Gothic Neo',
  'Apple Symbols',
  'AppleGothic',
  'AppleMyungjo',
  'Avenir Next Condensed',
  'Avenir Next',
  'Avenir',
  'Ayuthaya',
  'Baghdad',
  'Bangla MN',
  'Bangla Sangam MN',
  'Baskerville',
  'Beirut',
  'Big Caslon',
  'Bodoni Ornaments',
  'Bradley Hand',
  'Brush Script MT',
  'Chalkboard SE',
  'Chalkboard',
  'Chalkduster',
  'Cochin',
  'Copperplate',
  'Corsiva Hebrew',
  'Damascus',
  'DecoType Naskh',
  'Devanagari MT',
  'Devanagari Sangam MN',
  'Didot',
  'Diwan Kufi',
  'Diwan Thuluth',
  'Euphemia UCAS',
  'Farah',
  'Farisi',
  'Futura',
  'GB18030 Bitmap',
  'Geeza Pro',
  'Geneva',
  'Gill Sans',
  'Gujarati MT',
  'Gujarati Sangam MN',
  'Gurmukhi MN',
  'Gurmukhi MT',
  'Gurmukhi Sangam MN',
  'Heiti Sc',
  'Helvetica',
  'Herculanum',
  'Hiragino Sans GB',
  'Hiragino Sans',
  'Hoefler Text',
  'ITF Devanagari',
  'InaiMathi',
  'Kannada MN',
  'Kefa',
  'Khmer MN',
  'Khmer Sangam MN',
  'Kohinoor Bangla',
  'Kohinoor Telugu',
  'Kokonor',
  'Krungthep',
  'KufiStandardGK',
  'Lao MN',
  'Lao Sangam MN',
  'Lucida Grande',
  'Marker Felt',
  'Menlo',
  'Mishafi Gold',
  'Monaco',
  'Mshtakan',
  'Muna',
  'Nadeem',
  'New Peninim MT',
  'Noteworthy',
  'Optima',
  'Oriya Sangam MN',
  'PT Mono',
  'PT Sans Caption',
  'PT Sans Narrow',
  'PT Sans',
  'PT Serif Caption',
  'PT Serif',
  'Palatino',
  'Papyrus',
  'Phosphate',
  'PingFang HK',
  'Plantagenet Cherokee',
  'Raanana',
  'STIXGeneral',
  'STIXIntegralsD',
  'STIXIntegralsSm',
  'STIXIntegralsUp',
  'STIXIntegralsUpD',
  'STIXIntegralsUpSm',
  'STIXSizeFiveSym',
  'STIXSizeFourSym',
  'STIXSizeOneSym',
  'STIXSizeThreeSym',
  'STIXSizeTwoSym',
  'STIXVariants',
  'STSong',
  'Sana',
  'Sathu',
  'Savoye LET',
  'SignPainter',
  'Silom',
  'sinhala Sangam MN',
  'Skia',
  'Snell Roundhand',
  'Songti SC',
  'Sukhumvit Set',
  'Tamil Sangam MN',
  'Telugu Sangam MN',
  'Thonburi',
  'Trattatello',
  'Waseem',
  'Zapfino',
  'Charter',
  'DIN Alternate',
  'DIN Condensed',
  'Noto Nastaliq Urdu',
  'Rockwell',
  'Zapf Dingbats',
  'BlinkMacSystemFont',
  'Mishafi',
  'Myanmar MN',
  'Myanmar Sangam MN',
  'Oriya MN',
  'Songti TC',
  'Tamil MN',
  'Telugu MN'
]
