export default {
  updateProxyWithSeq: 'Изменить прокси (последовательность: {seq})',
  updateProxy: 'Изменить прокси',
  addProxy: 'Добавить прокси',
  addProxyEasy: 'Добавить прокси',
  updateSuccess: 'Успешно изменено',
  proxyMethod: 'Метод прокси',
  ipCheckService: 'Сервис проверки IP',
  ipCheckTips1:
    '1. Информация об IP, отображаемая на рабочем столе окна браузера, будет запрашиваться и отображаться с использованием выбранного вами сервиса проверки IP!',
  ipCheckTips2:
    '2. Различные сервисы проверки IP могут давать некоторые различия в результатах проверки! Если результаты проверки прокси не соответствуют фактической информации, вы можете изменить сервис проверки IP, чтобы обеспечить точность результатов проверки!',
  ipCheckTips3:
    '3. Если в настройках отпечатко этого окна установлены флажки "Генерации на основании IP": язык, язык интерфейса, часовая зона, географическое положение, убедитесь, что результаты проверки выбранного вами сервиса соответствуют фактической информации, иначе это может привести к ошибкам в автоматической генерации указанных выше элементов!',
  ipCheckTips4: '(Rola-IP рекомендует использовать сервис IP123)',
  proxyType: 'Тип прокси',
  ipProtocol: 'Протокол IP',
  useGlobalProxy: 'Использовать глобальные настройки',
  globalProxyInfo: 'Использовать { proxyType } прокси с глобальными настройками',
  host: 'Хост прокси',
  hostPlaceholder: 'Поддерживается автоматическое разделение "Тип_прокси://Хост:Порт:Логин:Пароль"',
  hostTips:
    'Тип_прокси://Хост:Порт:Логин:Пароль, введите этот формат, после того как курсор покинет поле, произойдет автоматическая разбивка.<br/>Если хост прокси является IPv6, пожалуйста, укажите его в [ ]!',
  port: 'Прокси-порт',
  portPlaceholder: 'Введите порт',
  username: 'Прокси-аккаунт',
  usernamePlaceholder: 'Введите имя пользователя (опционально)',
  usernamePlaceholder2: 'Введите имя пользователя',
  password: 'Пароль прокси',
  passwordPlaceholder: 'Введите пароль (опционально)',
  passwordPlaceholder2: 'Введите пароль',
  ipPlaceholder: 'Введите IP-адрес по умолчанию (например: 11.22.33.44)',
  noChangeIp: 'Проксируется только указанный вами IP. Если указанный IP недоступен, не будет автоматического поиска доступного IP!',
  ipHint:
    'Если указан IP, он будет использоваться в первую очередь при открытии окна. Если IP не указан или указанный IP недоступен, будет автоматически осуществлен поиск доступного IP на основе указанной ниже информации о местоположении!',
  ipTips: 'После ввода IP наведите курсор мыши на пустое место, чтобы автоматически определить и заполнить информацию о стране',
  country: 'Страна/регион',
  provicePlaceholder: 'Введите код штата/провинции',
  provice: 'Штат/провинция',
  cityPlaceholder: 'Введите код города',
  city: 'Город',
  checkProxy: 'Проверка прокси',
  httpsProxyMsg1: '[Проверка прокси]',
  httpsProxyMsg2: 'Откройте веб-страницу для проверки',
  extractIpTip:
    'Извлечение ссылок следует настраивать так, чтобы извлекался только 1 IP каждый раз, чтобы избежать излишних расходов на платформу прокси-серверов!',
  ipCheckFailedMsg:
    'Проверка прокси не удалась. Проверьте правильность прокси-IP и свяжитесь с поставщиком прокси-IP, если возникнут вопросы!',
  ipNoChange: 'Проксируется только текущий IP',
  ipNoChangeTips: 'При отказе от использования этого IP не будет автоматического переключения на другой IP',
  hostRuleMsg: 'Введите адрес хоста',
  portRuleRequiredMsg: 'Введите номер порта',
  portRuleNumMsg: 'Порт должен содержать только цифры',
  portRuleValidMsg: 'Диапазон порта должен быть от 0 до 65535, пожалуйста, не выходите за этот диапазон',
  usernameRuleMsg: 'Длина имени пользователя прокси не должна превышать 200 символов',
  passwordRuleMsg: 'Длина пароля прокси не должна превышать 200 символов',
  ipRuleMsg1: 'При выборе "Проксировать только текущий IP" обязательно укажите IP',
  ipRuleValidMsg: 'Введите действительный IP-адрес',
  proviceRuleMsg: 'Код штата/провинции состоит не более чем из 30 буквенно-цифровых символов.',
  cityRuleMsg1: 'Введите правильный код города',
  cityRuleMsg2: 'Код города должен содержать не более 30 символов латинского алфавита',
  customize: 'Настроить прокси',
  extractIp: 'Извлечение через ссылку API платформы прокси-IP',
  extractIpDialog: 'Извлечение прокси-IP через API ссылку...',
  extractIpMsg1: 'Проверка последнего использованного прокси-IP...',
  extractIpMsg2: 'Прокси-IP доступен, прямое открытие...',
  extractIpMsg3: 'Прокси-IP недоступен, повторное извлечение прокси-IP...',
  extractIpMsg4: 'Проверка API ссылки извлечения...',
  extractIpMsg5: 'Ссылка извлечения прокси-IP не установлена, невозможно извлечь прокси-IP.',
  extractIpMsg6: 'Попытка извлечения данных прокси-IP...',
  extractIpMsg7: 'Анализ результатов извлечения...',
  extractIpMsg8: 'Установка прошла успешно, открытие браузера...',
  extractIpMsg9: 'Не удалось извлечь прокси-IP, проверьте правильность ссылки извлечения.',
  extractIpMsg10: 'Анализ успешно выполнен, установка прокси-IP...',
  formatError: 'Измените формат возвращаемых API данных на текстовый (например, text/txt) и повторите попытку...',
  addWhiteList: 'Попытка автоматического добавления в белый список...',
  notInWhiteList: 'Не в белом списке',
  usernameRequiredRule: 'Введите логин прокси',
  passwordRequiredRule: 'Введите пароль прокси',
  provider: 'Провайдер',
  protocol: 'Протокол прокси',
  extractType: 'Способ извлечения',
  extractUrlPlaceholder: 'Введите URL-адрес извлечения',
  extractUrl: 'URL-адрес извлечения',
  extractTest: 'Проверить извлечение',
  whitelistTips:
    'Примечание: Большинство платформ прокси-серверов требуют добавления IP-адреса вашего устройства в белый список, чтобы иметь доступ к URL-адресу извлечения. Если возникают проблемы, пожалуйста, добавьте ваш IP-адрес в белый список вручную!',
  initialPass: 'Начальный пароль:',
  extractUrlTips: 'Метод получения URL-адреса извлечения',
  rolaTips:
    'После нажатия кнопки "Перейти на страницу администрирования ROLA-IP" перейдите на страницу администрирования Rola-IP, выберите в меню: Иностранные домашние IP -> Динамические домашние IP или Иностранные мобильные IP или Иностранные центры обработки IP -> Динамические центры обработки IP, затем нажмите на пункт "API доступ", настройте соответствующие параметры и нажмите кнопку "Создать извлечение API", чтобы сгенерировать URL-адрес для извлечения прокси-IP!',
  extractIpRule: 'Выберите провайдера',
  extractUrlRule: 'Введите корректный URL-адрес',
  extractProtocolRule: 'Выберите протокол прокси',
  extractIpChange1: 'Извлекать новый IP при каждом открытии окна браузера',
  extractIpChange2: 'Извлекать новый IP только если предыдущий IP недействителен',
  extractError: 'Не удалось получить данные, пожалуйста, проверьте правильность URL-адреса API',
  ipCountry: 'Страна/Регион: ',
  ipState: 'Штат/Провинция: ',
  ipCity: 'Город: ',
  iplngAndLat: 'Долгота/Широта: ',
  ipTimezone: 'часовая зона: ',
  ipZip: 'Почтовый индекс: ',
  ipNotWorking: 'Нет подключения к интернету',
  common: 'Общее',
  noproxy: 'Прямое подключение (без прокси)',
  ipideaauto: 'ipidea (динамический)',
  iphtmlauto: 'iphtml (динамический)',
  storm: 'storm (динамический)',
  oxylabsauto: 'oxylabs (динамический)',
  lumauto: 'luminati (динамический)',
  '922proxy': '922s5 динамический',
  lunaproxy: 'lunaproxy (динамический)',
  rolaip: 'rolaip (динамический)',
  skyip: 'skyip (динамический)',
  kookeey: 'kookeey (динамический)',
  socks5io: 'socks5io (динамический)',
  ipfly: 'ipfly (динамический)',
  smart: 'smart (динамический)',
  asocks: 'asocks (динамический)',
  ipfoxy: 'IPFoxy (динамический)',
  ssh: 'SSH',
  socks5: 'Socks5',
  http: 'HTTP',
  https: 'HTTPS',
  ip2world: 'ip2world (динамический)',
  skyipTips:
    'После нажатия кнопки "Войти на страницу администрирования SKY-IP" вы будете перенаправлены на страницу администрирования SKY-IP. На левой панели выберите "Домашний динамический IP"，"Мобильный динамический IP" или "Динамический центр обработки IP", затем перейдите в соответствующую панель. Выберите "API-доступ" и настройте необходимые параметры. После этого нажмите кнопку "Создать ссылку API" для генерации ссылки для извлечения IP-адреса!',
  refreshProxyUrl: 'Обновить URL',
  refreshProxyUrlTips:
    '1. Эта функция поддерживается только прокси-серверами с "функцией обновления URL";</br>\n2. При нажатии на значок обновления URL будет автоматически выполнено обновление URL и изменится IP-адрес текущего окна;</br>\n3. Если несколько окон используют одни и те же данные прокси, обновление прокси-IP одновременно изменит IP-адреса нескольких окон.',
  singleAdd: 'Добавить один прокси',
  deleteConfirm: 'Вы уверены, что хотите удалить этот прокси?',
  remark: 'Комментарий',
  boundBrowsers: 'Привязанные окна',
  proxyInfo: 'Информация о прокси',
  updateRemark: 'Изменить примечание прокси',
  remarkRule: 'Комментарий прокси не должен превышать 200 символов',
  batchAdd: 'Добавить прокси пакетом',
  batchAddTips: 'Добавьте данные хотя бы одного прокси',
  proxyRecommend: 'Рекомендуемые международные прокси-серверы',
  proxyManage: 'Управление прокси-IP',
  proxyIp: 'Прокси-IP',
  exportProxy: 'Экспорт выбранных прокси',
  batchExportProxy: 'Пакетный экспорт прокси',
  exportAllProxy: 'Экспорт всех прокси',
  exportProxyTitle: 'Экспорт прокси-IP.xlsx',
  exportAllProxyTip: 'Вы уверены, что хотите экспортировать все элементы?',
  boundBrowsersCount: 'Привязано к {count} окнам',
  batchDetection: 'Пакетная проверка прокси',
  batchDetectionEasy: 'Пакетная проверка прокси',
  noSelection: 'Выберите прокси',
  batchSection: 'Информация о прокси: (Вводите по одному прокси в каждой строке, согласно инструкциям справа)',
  batchSectionTitle: 'Инструкции по заполнению:',
  batchDesc1:
    "Поддерживаются только четыре типа прокси: http, https, socks5, ssh. После соответствующего типа следует добавить ://. Если вам нужно, добавьте {'{комментарий}'} в конце информации о прокси",
  batchDesc2: 'Если тип прокси не указан, по умолчанию будет установлен как socks5.',
  batchDesc3: 'Введите одну информацию о прокси на каждой строке. Максимально добавить за один раз - 100.',
  batchDesc4: ' Хост прокси может быть IPv4 или IPv6. Если хост прокси является IPv6, укажите в квадратных скобках [].',
  batchDesc5: 'Если вам нужно добавить URL для обновления, заполните в конце в круглых скобках ().',
  demoTitle: 'Примеры заполнения:',
  demo1: '192.168.1.1:8800&#123;комментарий&#125(Обновить URL);',
  demo2: '192.168.1.1:8800:имя_пользователя:пароль&#123;комментарий&#125(Обновить URL);',
  demo3: 'socks5://192.168.1.1:8800&#123;комментарий&#125(Обновить URL);',
  demo4: 'http://[2001:db8:2de:0:0:0:0:e13]:8800&#123;комментарий&#125(Обновить URL);',
  detectedInfo: 'Обнаруженная информация о прокси: (Всего {count} записей)',
  batchFormatError: 'Введите каждый прокси на отдельной строке. Максимальное количество прокси для добавления - 100.',
  outInfo: 'Исходящий IP',
  deleteProxy: 'Удалить прокси',
  proxyManageRule: 'Пожалуйста, выберите прокси IP',
  usedLabel1: 'Обнаружено использование этого IP в последнее время',
  usedLabel2: 'использовался в этом программном обеспечении!',
  ipNoUsed: 'Этот IP-адрес не использовался в этом программном обеспечении!',
  duplicateCheck: 'Проверка дубликатов',
  maxCheckTimes: 'Максимальное количество попыток',
  duplicateCheckTips:
    'При открытии окна проверяется, использовался ли ранее извлеченный IP в данной системе. Если использовался, то производится попытка извлечь новый IP не более 5 раз.',
  maxCheckTips: 'При дублировании прокси IP будет произведено максимальное количество попыток извлечения',
  duplicateDetected: 'Обнаружено дублирование IP, выполняется повторное извлечение...',
  chineseProxy: 'Рекомендуемые китайские проски-IP',
  visitWebsiteBtn: 'На сайт',
  toGlobalSetting: '[Глобальные Настройки]',
  verifyDuplicates: 'Проверка дубликатов',
  verifyDuplicatesTip1: 'Сравнение со всеми элементами в списке «Управление прокси-IP», когда тип прокси, информация прокси ',
  verifyDuplicatesTip2: 'полностью совпадают с каким-либо элементом, то он определяется как повторяющийся и не добавляется!',
  hasProxyRepeat:
    'Обнаружено, что вы пытаетесь добавить прокси, который уже существует в списке "Управление прокси IP". Добавление запрещено! Если вы все равно хотите добавить, снимите флажок "Проверять повторяющиеся элементы"!',
  warningTips:
    '\n  <p>Просим вас самостоятельно оценивать качество следующих платформ. Это программное обеспечение является лишь рекомендуемым. Вы несете ответственность за любые возможные проблемы!</p><p>Если возникнут проблемы, вы можете подать жалобу к нам, после проверки мы примем меры по снятию с публикации!</p>',
  hostColon: 'Введите прокси-хост',
  remarkColon: 'Введите комментарий',
  proxyTypeColon: 'Выберите тип прокси',
  lonAndLat: 'Широта/Долгота',
  timeZone: 'часовая зона',
  postcode: 'Язык',
  correct: 'Исправить',
  correctSuccess: 'Исправлено',
  channel: 'Платформа',
  correctTitle: 'Запрос/изменение идентификации IP',
  isNotSure: 'Неправильно?',
  batchAddProxyTip: 'Данные {line} строки: Логин и пароль прокси не должны превышать 200 символов.',
  dynamicProxy: 'Глобальный динамический агент',
  customProxy: 'При отсутствии глобальных настроек этот динамический прокси поддерживает только тип прокси Socks5.',
  bulkPublish: 'Массовая публикация для учетных записей сотрудников',
  bulkPublishTip:
    'Вы хотите сделать выбранные прокси-IP видимыми для учетных записей сотрудников? После публикации выбранные прокси-IP будут видны в учетных записях сотрудников. ',
  bulkPublishTip1: 'Это позволит учетным записям сотрудников выполнять следующие действия:',
  bulkUnpublish: 'Массовая отмена публикации для учетных записей сотрудников',
  bulkUnpublishTip:
    'Хотите отменить публикацию выбранных прокси-IP для учетных записей сотрудников? После отмены публикации выбранные прокси-IP больше не будут видны в учетных записях сотрудников.',
  bulkUnpublishNotice:
    'Примечание: После отмены публикации прокси-IP, связанные с окнами в учетных записях сотрудников, перейдут в режим прямого подключения!',
  allowDeleteIP: 'Разрешить удаление и изменение',
  publishStaff: 'Опубликовать учетную запись для сотрудников',
  publishStaffTitle: 'Включение этой функции сделает выбранный прокси-IP видимым в учетных записях сотрудников.',
  publishStaffMsg:
    'Отметьте этот вариант, чтобы предоставить доступ к IP-адресу прокси учетным записям сотрудников, позволяя сотрудникам удалять и изменять его!',
  Publicized: 'Опубликовано {name}',
  proxyDleteTip: 'Выбранные вами прокси-IP содержат прокси-IP с несанкционированными правами удаления',
  batchImportAgentTitle: 'Массовый импорт прокси',
  batchImportAgentTip:
    'Примечание: Эта таблица используется для импорта прокси-IP. Она только импортирует данные прокси-IP в список "Управление прокси-IP" BitBrowser и не выполняет проверку прокси. Пожалуйста, самостоятельно проверяйте доступность прокси при привязке к окнам.',
  batchImportAgentTemp: 'Шаблон импорта прокси-IP.xlsx',
  batchImportAgentTip2: 'Одиночный файл Excel: Количество импортированных прокси-IP не должно превышать 1000.',
  proxySelectMethod: 'Выберите метод прокси',
  proxyBulkEditing: 'Массовое изменение конфигурации прокси-IP',
  proxyEditTip:
    'Выберите прокси-IP с тем же "Метод прокси" для массового изменения. Вы можете использовать функцию поиска по "Метод прокси" в списке управления прокси-IP!',
  proxyEditRepeatTip:
    'После успешного сохранения текущей конфигурации прокси-IP, она будет дублироваться с уже существующими прокси-IP в списке прокси. Желаете продолжить?',
  batchEditProxyTip:
    'Для внесения изменений в необходимый элемент, отметьте флажок слева от этого элемента, затем измените значение конфигурации этого элемента. В этот момент все выбранные прокси-IP будут изменены на последнюю настройку, которую вы установите!'
}
