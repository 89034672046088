export default {
  groupPlaceholder: 'Filter by group',
  belong: 'Belong To: ',
  datePlaceholder: 'Filter by delete date',
  browserNamePlaceholder: 'Filter by Name',
  remarkPlaceholder: 'Filter by remarks',
  showAll: 'All Browser Profiles',
  showSelf: 'My Browser Profiles',
  recoverSelection: 'Restore Selected',
  recoverAll: 'Restore All Profiles',
  deleteSelection: 'Delete Selected',
  deleteAll: 'Delete All',
  recoverDialog: 'Restore Profiles',
  recoverTips1:
    'Note: This operation will restore all the profiles in the recycle bin to the following group, including the profiles in the sub account.',
  recoverTips2: 'Note: This operation will restore the selected browser profiles to the following group.',
  createdName: 'Belong To',
  updateBy: 'Operator',
  deleteTime: 'Delete Time',
  deleteConfirm:
    '<p class="text-red q-mt-sm">Please note that the profiles cannot be restored again.Confirm to delete selected browser profiles?</p>',
  deleteSuccess: 'Success',
  clearConfirm:
    '<p class="text-red q-mt-sm">Please note that the profiles cannot be restored again.Confirm to delete all these browser profiles?</p>',
  recoverSuccess: 'Success',
  browserName: 'Name',
  browserSeq: 'Seq',
  browserSeqPlaceholder: 'Filter by Seq',
  browserSeqToolTip:
    'Multiple numbers are separated by commas, and number ranges can only be entered from small to large, using a hyphen to separate (e.g. 1-100).'
}
