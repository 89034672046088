<template>
  <router-view />
  <upgrade-dialog></upgrade-dialog>
</template>
<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { useQuasar, LocalStorage } from 'quasar'
import { getServerTime, getSymbolList } from 'api/user'
import { loadScript, sysInfoCollection } from 'utils/tools'
import { CacheMap } from 'utils/cache'
import UpgradeDialog from './UpgradeDialog.vue'
export default defineComponent({
  name: 'App',
  components: { UpgradeDialog },
  data() {
    return {
      updateInfo: {},
      errMsg: '',
      isMac: window.isMac
    }
  },
  computed: {
    locale() {
      return this.$i18n.locale
    },
    ...mapGetters({
      isInBitBrowser: 'client/isInBitBrowser'
    })
  },
  watch: {
    locale() {
      this.initOem()
    }
  },
  created() {
    this.getLocalSymbol()
    this.initOem()
    // 本地时间与服务端时间相差超过一分钟，则记录下时间差值
    getServerTime().then(res => {
      const timeDifference = res.result - Date.now()
      if (Math.abs(timeDifference) > 60 * 1000) {
        this.$store.commit('client/SET_TIME_DIFFERENCE', timeDifference)
      }
    })
  },
  mounted() {
    this.loadBaiduJS()

    // 收集系统信息
    sysInfoCollection()
    let customerObj = {
      zh: 4347,
      en: 4348,
      ru: 8592
    }
    let customerId = customerObj[this.locale]
    loadScript(`https://kefuapi.bitbrowser.net/static/kefu_bit.js?agentid=${customerId}&show=false`)

    CacheMap.DeleteChildrenDictsStorage()
  },
  setup() {
    const $q = useQuasar()
    // 主题色
    const darkMode = LocalStorage.getItem('darkMode')
    if (darkMode) $q.dark.set(true)
    else $q.dark.set(false)
    // Example of adding support for
    // <q-icon name="bit-xxx" />
    // This includes support for all "icon" props
    // of Quasar components

    // 无需显示loadingbar的接口
    const NO_LOADING_APIS = ['sysUsers/softHeartBeat', 'sysDictionarys/getNoPassCode']

    $q.loadingBar.setDefaults({
      hijackFilter: url => {
        return !NO_LOADING_APIS.some(api => url.includes(api))
      }
    })

    $q.iconMapFn = iconName => {
      // 只设置bit-开头的
      if (iconName.startsWith('bit-')) {
        return {
          cls: 'iconfont ' + iconName
        }
      }
    }
  },
  methods: {
    async getLocalSymbol() {
      let symbol_list = LocalStorage.getItem('symbol_list')
      if (!symbol_list) {
        let list = (await getSymbolList()) || []
        let symbol = {}
        list.forEach(i => {
          symbol[i.code] = i
        })
        LocalStorage.set('symbol_list', JSON.stringify(symbol))
        this.$store.commit('user/SET_SYMBOL_LIST', symbol)
      } else {
        this.$store.commit('user/SET_SYMBOL_LIST', JSON.parse(symbol_list))
      }
    },
    unHandledRejection(e) {
      if (process.env.NODE_ENV === 'development') {
        console.error(e.reason)
      }
    },

    initOem() {
      document.querySelector('#favicon').setAttribute('href', 'favicon.ico')
      let title = {
        zh: '比特浏览器',
        en: 'BitBrowser',
        ru: 'BitBrowser'
      }
      this.$store.commit('client/SET_OEM_INFO', {
        title: title[this.locale] || title['en']
      })
    },
    // 加载百度统计代码
    loadBaiduJS() {
      window._hmt = window._hmt || []
      const hm = document.createElement('script')
      let obj = {
        zh: 'https://hm.baidu.com/hm.js?fc92b58779174201e170852d32913732',
        en: 'https://hm.baidu.com/hm.js?357e476f40afa65243627e7c206c72ec',
        ru: 'https://hm.baidu.com/hm.js?8ffeec4f6e0b3415116f254bbb5633bb'
      }

      hm.src = obj[this.locale]
      const s = document.getElementsByTagName('script')[0]
      s.parentNode.insertBefore(hm, s)
    }
  },
  beforeCreate() {
    window.addEventListener('unhandledrejection', this.unHandledRejection)
  },
  beforeUnmount() {
    window.removeEventListener('unhandledrejection', this.unHandledRejection)
  }
})
</script>

<style scoped lang="scss"></style>
