import { request } from 'src/boot/axios'

// 推广分成列表
export function promotionShareRecordsList(data) {
  return request({
    url: 'v1/promotionShareRecords/list',
    method: 'post',
    data
  })
}
// 删除推广分成
export function deleteShare(data) {
  return request({
    url: 'v1/promotionShareRecords',
    method: 'delete',
    data: data
  })
}

// 提现
export function withdrawApply(data) {
  return request({
    url: 'v1/sysUsers/withdrawApply',
    method: 'post',
    data
  })
}

// 提现列表
export function withdrawRecords(data) {
  return request({
    url: 'v1/withdrawRecords/list',
    method: 'post',
    data
  })
}

// 在线充值支付记录列表
export function getPayRecords(data) {
  return request({ url: 'v1/sysPayRecords/list', method: 'post', data })
}

// 查询提现申请记录列表
export function getwithdrawRecordsList(data) {
  return request({
    url: 'v1/withdrawRecords/list',
    method: 'post',
    data
  })
}

// 汇率换算
export function getUsdRate(data) {
  return request({
    url: '/v1/sysDictionarys/getUsdRate',
    method: 'post',
    data
  })
}

// 获取云手机账单详情
export function cloudPhonesBilling(data) {
  return request({
    url: '/v1/cloudphone/cloudPhonesBilling/list',
    method: 'post',
    data
  })
}
