export default {
  groupTab: '手机环境分组',
  num: '环境数量',
  creatEnv: '创建环境',
  editEnv: '编辑环境',
  computingSet: '算力设置',
  computingSetTip: '环境创建后无法更改算力类型，请谨慎选择！',
  computingSetChineTip: '仅在中国网络环境下可正常使用，创建后不可修改，请谨慎选择！',
  openEnvCombo: '开通环境套餐',
  computingManage: '包月算力管理',
  batchUpdateProxy: '批量修改代理',
  batchCloseEnv: '批量关闭环境',
  closeEnvTitle: '关闭环境',
  editConfig: '修改环境配置',
  openEnv: '正在打开环境',
  envExpired: '环境已过期',
  batchOpenEnv: '批量关闭选中环境',
  batchAllCloseEnv: '批量关闭所有环境',
  todayPhone: '今日新机',
  editNameWithSeq1: '修改环境名称（序号：{seq}）',
  envName: '环境名称',
  name: '环境名称',
  envRmark: '备注',
  lastUpdateTime: '最后更新时间',
  model: '机型',
  createPeople: '创建人',
  pkgEnvironment: '手机环境：{count}个',
  buy: '购买',
  totalEnvironment: '总环境数: {count}个',
  openStatus: '环境打开状态',
  pleaseSelect: '请选择环境',
  customEnvName: '自定义环境名称',
  nameTip: '为该环境命名，方便以后的日常管理操作！',
  groupSelectTips: '注意：如果选择了其他账号下的分组，则该环境将转移至其他账号的分组里（环境归属权将属于其他账号）',
  computeType0: '优先使用“包月算力”，如果没有可用的包月算力则使用“临时算力”。',
  computeType1: '计费单位：{num}/15分钟（单日{maxNum}/天封顶），关机时自动释放算力并停止计费，挂起则持续计费。',
  computeType2: '自动绑定可用的包月算力，一个环境占用一个包月算力；关机时可解绑算力，供其他环境使用。',
  computeType3: '自带各种传感器，环境更真实。须绑定包月算力使用，环境到期才可解绑算力。',
  remarkPlaceholder: '请填写备注',
  ruleNameMsg: '环境名称最多30个字符',
  allPhoneClosed: '已关闭所有环境',
  closePhone: '环境已关闭',
  checkedHasClosed: '所选环境已关闭',
  deleteTip: '手机环境删除后不可恢复，确认要彻底删除手机环境吗？',
  batchDelect: '批量删除环境',
  deleteCompletelybrowser: '彻底删除环境',
  computingStatus: '算力状态',
  boundComputingTips: '关机并解绑包月算力，解绑的包月算力可供其他环境使用。',
  releaseComputingTips: '关机并停止计费。',
  billingProgress: '计费中',
  billingProgressTip: '算力使用中，正在持续扣费',
  boundComTip: '已绑定包月算力：{name}',
  closeEnv: '释放算力',
  releaseComputing: '释放算力',
  closeEnvStopCharging: '点击"确定"，将释放算力并同时停止计费。确定关机吗？',
  closeEnvUnbindComputing: '点击"确定"，将解除绑定包月算力并关机，解绑的包月算力可供其他环境使用！确定关机吗？',
  temporarilyClosed: '挂起',
  temporarilyClosed1: '挂起',
  closeEnvBtn1: '关机',
  closeEnvBtn2: '关机',

  unbindComputing: '解绑算力',
  notMultipleOpen: '当前环境不允许多个账号同时打开',
  temporarilyClosedTip1:
    '<span class="text-negative">关机：</span>关闭该环境窗口并将云手机关机，解绑当前绑定的包月算力，以供其他环境使用！',
  temporarilyClosedTip1_1: '<span class="text-primary">挂起：</span>只关闭该环境窗口，包月算力不解绑，云手机持续运行中！',
  temporarilyClosedTip2_1: '<span class="text-primary">挂起：</span>只关闭该环境窗口，临时算力持续计费中，云手机持续运行中！',
  temporarilyClosedTip2: '<span class="text-negative">关机：</span>关闭该环境窗口并将云手机关机，释放临时算力并停止计费！',
  closeEnvTip1: '关机并解绑包月算力，关机后再次启动该环境可能会慢一些哦!',
  closeEnvTip2: '关机并释放临时算力，关机后再次启动该环境可能会慢一些哦!',
  searchNamePlaceholder: '输入环境名搜索',
  changeConfirm: `
  <p class="text-red text-bold">降低套餐以后：</p>
  <p class="text-red q-mt-sm">1. 系统不进行退费，并且立刻生效</p>
  <p class="text-red q-mt-sm">2. 手机环境数和一键新机次数，将变更为最新套餐对应的数量！</p>
  <p class="q-mt-sm">是否需要操作？</p>
  `,
  againOpen: '环境已被其他用户打开，无法重复打开，是否要关闭此环境，重新打开?',
  envType: '环境类型',
  envProxyTip: '手机环境务必配置代理，不支持中国大陆代理IP，请确保代理网络畅通，网络不通无法打开手机环境！',
  envChineProxyTip: '手机环境务必配置代理，请确保代理网络畅通，网络不通无法打开手机环境！',
  selectComputing: '选择算力',
  selectBillingMethod: '请选择计费方式',
  billingMethod: '计费方式',
  languageTips: '基于IP生成对应国家的手机系统语言，不开启可自定义',
  seq: '环境序号',
  udpLable: 'UDP协议',
  udpTip: '无法连接网络时移除UDP勾选，说明网络不支持UDP，默认勾选可以保护IP不泄露。',
  createEnvTip: `您购买的{name}环境已达上限（{num}个），继续购买需要您完成实名认证以解除此限制。`,
  envChangeTip:
    '套餐中的个数，仅代表您最多可以创建的云手机环境数，每个云手机环境需要挂载云端算力方可运行，会额外消耗云端算力费用，请知晓！',
  maxEnvTip: '已超过主账号可用环境数总数，请开通环境套餐或者变更为更多环境套餐！',
  createEnvTip: '余额和包月算力不足，请充值或购买算力'
}
