export default {
  attention: 'Attention：',
  tips1:
    '1. The following are recommended proxy IP platforms, only for displaying. BitBrower does not guarantee and assumes responsibility! Please contact platform customer service if any questions.',
  tips2: '',
  itemTitle: 'The {arg} account is automatically registered for you:',
  account: 'Account: ',
  password: 'Initial Password: ',
  itemTitle2: 'Exclusive 40% off Coupon Code:',
  toAdmin: 'To {arg} Website',
  toIndex: 'To {arg} Website',
  toWebsite: 'To {arg} Website',
  showMore: 'Show More',
  hideMore: 'Hide More',
  addTelMsg: 'Please fill in your phone number or email before using.',
  promoCode: 'Promo Code:',
  activationCode: '(Get exclusive activation code for 200M traffic: BitBrowser)'
}
