import { boot } from 'quasar/wrappers'
import axios from 'axios'
import store from 'src/store'
import router from 'src/router'
import { Cookies, LocalStorage, Notify } from 'quasar'
import { cache } from 'utils/cache'
import { getHash } from 'src/utils/jssha'
import { getClientXCF } from './freetrail'
import { isExchangeToken } from 'src/utils/token'
import { closeAllPhoneBrowsers } from 'src/api/client'
const LineMap = {
  localhost: 'https://testserviceapi.bitbrowser.cn/bitbrowser',
  '127.0.0.1': 'http://127.0.0.1:9020/bitbrowser',
  'client.bitbrowser.cn': 'https://apiservice.bitbrowser.cn/bitbrowser',
  'client2.bitbrowser.cn': 'http://apiservice2.bitbrowser.cn/bitbrowser',
  'testclient.bitbrowser.cn': 'https://testserviceapi.bitbrowser.cn/bitbrowser',
  'testclient2.bitbrowser.cn': 'http://testserviceapi2.bitbrowser.cn/bitbrowser',
  'client3.bitbrowser.net': 'http://apiservice3.bitbrowser.net/bitbrowser',
  'client4.bitbrowser.net': 'https://apiservice4.bitbrowser.net/bitbrowser',
  'prodclient.bitbrowser.cn': 'http://prodapiservice.bitbrowser.cn/bitbrowser'
}

// 心跳走API接口
const HeartBeatApi = {
  localhost: 'https://testserviceapi.bitbrowser.cn/bitbrowser',
  'client.bitbrowser.cn': 'https://serviceapi.bitbrowser.cn/bitbrowser',
  'client2.bitbrowser.cn': 'http://serviceapi2.bitbrowser.cn/bitbrowser',
  'testclient.bitbrowser.cn': 'https://testserviceapi.bitbrowser.cn/bitbrowser',
  'testclient2.bitbrowser.cn': 'http://testserviceapi2.bitbrowser.cn/bitbrowser',
  'client3.bitbrowser.net': 'http://apiservice3.bitbrowser.net/bitbrowser',
  'client4.bitbrowser.net': 'https://apiservice4.bitbrowser.net/bitbrowser',
  'prodclient.bitbrowser.cn': 'http://prodapiservice.bitbrowser.cn/bitbrowser'
}

const phoneApi = {
  localhost: 'http://localhost:8081/',
  'testclient.bitbrowser.cn': 'https://testphone.bitbrowser.cn/',
  'testclient2.bitbrowser.cn': 'https://testphone.bitbrowser.cn/',
  'client.bitbrowser.cn': 'https://phone.bitbrowser.net/',
  'client2.bitbrowser.cn': 'http://phone2.bitbrowser.net/',
  'client3.bitbrowser.net': 'http://phone2.bitbrowser.net/',
  'client4.bitbrowser.net': 'https://phone2.bitbrowser.net/'
}

export const PHONE_URL = phoneApi[location.hostname]
export const BASE_URL = LineMap[location.hostname]

// Be careful when using SSR for cross-request state pollution
// due to creating a Singleton instance here;
// If any client changes this (global) instance, it might be a
// good idea to move this instance creation inside of the
// "export default () => {}" function below (which runs individually
// for each client)
const api = axios.create({
  baseURL: BASE_URL, // 通过hostname获取对应的baseURL
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  }
})
// 取消请求
const CancelToken = axios.CancelToken
// 操作正常Code数组
const codeVerificationArray = [200, 0, '200', '0', '20000', 20000]

// 针对特定url是否弹出提示
function isInWhiteList(url, code) {
  const whiteList = { '/users/register': 17014 }
  if (!whiteList[url]) return false
  return whiteList[url] === code
}
const CODE_MESSAGE = {
  200: '服务器成功返回请求数据',
  201: '新建或修改数据成功',
  202: '一个请求已经进入后台排队(异步任务)',
  204: '删除数据成功',
  400: '发出信息有误',
  401: '用户没有权限(令牌失效、用户名、密码错误、登录过期)',
  402: '令牌过期',
  403: '用户得到授权，但是访问是被禁止的',
  404: '访问资源不存在',
  406: '请求格式不可得',
  410: '请求资源被永久删除，且不会被看到',
  500: '服务器发生错误',
  502: '网关错误',
  503: '服务不可用，服务器暂时过载或维护',
  504: '网关超时',
  50000: '服务器繁忙，请稍后再试',
  40005: '服务器繁忙，请稍后再试2'
}

/**
 * axios响应拦截器
 * @param config 请求配置
 * @param data response数据
 * @param status HTTP status
 * @param statusText HTTP status text
 * @returns {Promise<*|*>}
 */
const handleData = async ({ data, status, statusText, config }) => {
  // 若data.code存在，覆盖默认code
  let code = data && data.code ? data.code : status
  // 若code属于操作正常code，则status修改为200
  if (codeVerificationArray.includes(data.code)) {
    code = 200
  }
  switch (code) {
    case 200:
      if (config.cacheTime) {
        const cacheKey = generateCacheKey(config)
        cache.set(cacheKey, JSON.stringify(data), config.cacheTime, config.cacheAlias, config.cacheStore)
      }
      if (typeof data.result == 'string' || typeof data.result === 'number') {
        return {
          msg: 'Success',
          result: data.result
        }
      }
      if (Object.prototype.toString.call(data) === '[object Blob]') {
        if (['application/x-download', 'application/octet-stream', 'multipart/form-data'].includes(data.type)) {
          return data
        } else {
          const fileReader = new FileReader()
          fileReader.addEventListener('loadend', e => {
            const res = JSON.parse(e.target.result)
            Notify.create({ message: res.message, position: 'top', type: 'negative' })
          })
          fileReader.readAsText(data)

          return Promise.reject(data)
        }
      }
      if (typeof data.result === 'boolean') return data.result
      return data.result || {}
    case 401:
    // case 20001:
    case 40104:
    case 50401:
    case 50406:
      // 正在交换tokne的话，不处理退出
      if (!isExchangeToken()) {
        if (window.isInBitBrowser) {
          await window.bitClientMain.closeAllBrowsers()
          await closeAllPhoneBrowsers()
        }
        store.dispatch('user/resetAll').then(() => router.replace({ name: 'Login' }).then(() => {}))
      }
      break
    case 403:
      router.push({ path: '/403' }).then(() => {})
      break
    case 11019:
      store.dispatch('user/getUserInfo')
      break
    case 11209:
      return Promise.reject(data)
    case 16024:
      return Promise.reject({ message: data.message, showMsg: true })
    case 16187:
      return Promise.reject(data)
    case 16184:
      return Promise.reject({ message: data.message, showMsg: true })
  }
  // 异常处理
  // 若data.msg存在，覆盖默认提醒消息
  let errMsg = `${data && data.message ? data.message : CODE_MESSAGE[code] ? CODE_MESSAGE[code] : statusText}`
  if (code === 400) {
    errMsg = 'Error with 400'
  }
  if (!isInWhiteList(config.url, data.code) && !isExchangeToken()) {
    Notify.create({ message: errMsg, position: 'top', type: 'negative', timeout: 2000 })
  }

  return Promise.reject(data)
}
/**
 * 生成缓存所需key
 * @param config 请求配置
 * @param request 请求
 * @returns {Promise<*|*>}
 */
function generateCacheKey(config, request = false) {
  const { method, url, data, params } = config
  const requestData = request ? JSON.stringify(data || params) : data || params
  const cacheKey = JSON.stringify({
    method,
    url,
    data: requestData || ''
  })
  // 根据method、url和data生成缓存键
  return getHash(cacheKey)
}

/**
 * @description axios请求拦截器
 */
api.interceptors.request.use(
  async config => {
    const token = store.getters['user/token']
    if (token) config.headers['Authorization'] = `Bearer ${token}`

    const sid = store.getters['user/sid']
    if (sid) config.headers['sid'] = sid

    config.headers['Language'] = LocalStorage.getItem('locale') || 'zh'
    const timeDifference = store.getters['client/timeDifference']
    config.headers['Accept-Decoding'] = (Math.abs(Math.floor((Date.now() + timeDifference) / 60000) << 1) >> 3).toString(16)

    const xcf = await getClientXCF()
    config.headers['Accept-XCF'] = xcf

    // 接口是否需要缓存 - 例子：/v1/browserExtend/getBrowserExtendUser
    if (config.cacheTime) {
      const source = CancelToken.source()
      config.cancelToken = source.token // 取消令牌

      const cacheKey = generateCacheKey(config, true)
      const cacheData = cache.get(cacheKey, config.cacheStore)
      // 未过期 source.cancel会取消当前的请求 并将内容返回到拦截器的err中
      if (cacheData) {
        // console.log(config, '数据已经缓存')
        source.cancel(cacheData)
      }
    }
    // 重写心跳接口baseUrl
    if (config.url.includes('softHeartBeat')) {
      config.baseURL = HeartBeatApi[location.hostname]
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

/**
 * @description axios响应拦截器
 */
api.interceptors.response.use(
  response => {
    return handleData(response)
  },
  error => {
    if (axios.isCancel(error)) {
      return Promise.resolve(error.message.result || {})
    }
    const { response } = error
    if (response === undefined) {
      return Promise.reject(error)
    } else {
      return handleData(response)
    }
  }
)

export default boot(({ app }) => {
  // for use inside Vue files (Options API) through this.$axios and this.$api

  app.config.globalProperties.$axios = axios
  // ^ ^ ^ this will allow you to use this.$axios (for Vue Options API form)
  //       so you won't necessarily have to import axios in each vue file

  app.config.globalProperties.$api = api
  // ^ ^ ^ this will allow you to use this.$api (for Vue Options API form)
  //       so you can easily perform requests against your app's API
})

export { api as request }
