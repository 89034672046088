import { dataCollectionAndUpload } from 'src/api/user'
import { getHash } from './jssha'
import { getEnabledFontList } from 'src/static-data/font'
/*
 * @description 从url中匹配hostname
 * @params {string} url
 */
export function getHostname(url) {
  // 自定义域名可能没有以 / 结尾，手动补上，以匹配以下正则
  if (!url.endsWith('/')) {
    url += '/'
  }
  const domain = url.match(/^http(s)?:\/\/(www.)?(.*?)\//)
  if (domain) {
    return domain[3]
  }
  return url
}
export function getSymbolFlag(isZh, money, flag, moneyUnit) {
  money = money ?? 0
  if (flag === '¥') {
    if (moneyUnit) {
      if (isZh) {
        return money + moneyUnit
      } else {
        return moneyUnit + money
      }
    }
  }
  return flag + ' ' + money
}
// 获取网站地址
export function getDomain(language) {
  let domain = {
    zh: 'cn',
    en: 'net',
    ru: 'ru'
  }
  let lang = domain[language] || domain.en
  return `https://www.bitbrowser.${lang}`
}
// 跳转至官网下载页面，redirect 是否当前页面重定向
export function gotoDownload(redirect, language) {
  let url = getDomain(language) + '/download/'

  if (redirect) {
    location.href = url
  } else {
    window.open(url)
  }
}

export function gotoDownloadByDefault(language) {
  let url = getDomain(language) + '/download/'
  window.bitClientMain.openByDefaultBrowser(url)
}

// 读取通过file input 选择的文件内容
export async function getFileReaderTextResult(file) {
  if (!file) return ''

  return new Promise(resolve => {
    const reader = new FileReader()
    reader.readAsText(file)
    reader.addEventListener('loadend', () => {
      resolve(reader.result)
    })
  })
}

// 获取折扣值
export function getDiscountValue(num, locale) {
  return locale === 'zh' ? parseFloat(num * 10).toFixed(1) + '折' : parseFloat(num * 100) + '%'
}

// get请求参数拼接
export function getformatQuery(obj) {
  let str = ''
  for (const key in obj) {
    str += `&${key}=${obj[key]}`
  }
  if (str) {
    str = str.slice(1)
  }
  return str
}
// 是否是移动端
export function hasMobile() {
  let isMobile = false
  if (
    navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    )
  ) {
    isMobile = true
  }
  if (isMobile && document.body.clientWidth <= 768) {
    isMobile = true
  }
  return isMobile
}

// 获取系统信息并上报
export async function sysInfoCollection() {
  const UAParser = await import('ua-parser-js')
  const parser = new UAParser.default(navigator.userAgent)
  const result = parser.getResult()

  const webgl = getWebGLInfo()
  const speechVoices = await getSpeechVoices()
  const mediaDevices = await getMediaDevices()

  const params = {
    os: result.os.name,
    osVersion: result.os.version,
    ua: result.ua,
    browser: result.browser.name,
    browserVersion: result.browser.version,
    cpu: result.cpu.architecture,
    device: result.device.vendor,
    deviceModel: result.device.model,
    height: window.screen.height,
    width: window.screen.width,
    availWidth: window.screen.availWidth,
    availHeight: window.screen.availHeight,
    colorDepth: window.screen.colorDepth,
    ...webgl,
    speechVoices: JSON.stringify(speechVoices),
    mediaDevices: JSON.stringify(mediaDevices),
    devicePixelRatio: window.devicePixelRatio,
    font: await getEnabledFontList()
  }

  params.fingerPrintId = getHash(JSON.stringify(params))

  dataCollectionAndUpload(params)
}

function getWebGLInfo() {
  var canvas = document.createElement('canvas')
  var gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl')
  var debugInfo = gl.getExtension('WEBGL_debug_renderer_info')
  var unmaskedVendor = gl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL)
  var unmaskedRenderer = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL)
  return {
    unmaskedVendor,
    unmaskedRenderer
  }
}

function getSpeechVoices() {
  return new Promise(resolve => {
    const speech = window.speechSynthesis
    if (speech.onvoiceschanged !== undefined) {
      speech.onvoiceschanged = () => {
        const voices = speech.getVoices()
        const voicesArr = []
        voices.forEach(item => {
          voicesArr.push({
            name: item.name,
            default: item.default,
            lang: item.lang,
            localService: item.localService,
            voiceURI: item.voiceURI
          })
        })
        resolve(voicesArr)
      }
    }
  })
}

async function getMediaDevices() {
  if (!navigator.mediaDevices) return []

  const devices = await navigator.mediaDevices.enumerateDevices()

  return devices.reduce((acc, cur) => {
    acc.push({
      deviceId: cur.deviceId === 'default' ? 'default' : '',
      kind: cur.kind,
      label: cur.label
    })
    return acc
  }, [])
}

// 加载外部js
export function loadScript(src) {
  var scriptElement = document.createElement('script')
  scriptElement.src = src
  var s = document.getElementsByTagName('script')[0]
  s.parentNode.insertBefore(scriptElement, s)
}

export function jsonp(url, options) {
  // 超时处理
  const { timeout } = options
  return new Promise((resolve, reject) => {
    // 防止函数名冲突
    let funcName = `jsonp${Date.now()}`
    let time = null,
      scriptNode
    // 定义callback
    window[funcName] = function (data) {
      if (timeout) clearTimeout(time)
      resolve(data)
      // 很重要的性能优化点
      // 清除本次请求产生的回调函数和script标签
      delete window[funcName]
      document.body.removeChild(scriptNode)
    }
    // 创建script标签
    scriptNode = document.createElement('script')
    // 给script标签添加src属性
    scriptNode.src = `${url}?callback=${funcName}&${options.params}`
    // 发出请求
    document.body.appendChild(scriptNode)
    time = setTimeout(() => {
      reject('network err, timeout')
    }, timeout)
    // 失败
    scriptNode.onerror = function (err) {
      reject(err)
    }
  })
}

const objectToString = Object.prototype.toString
const toTypeString = value => objectToString.call(value)
const isPlainObject = val => toTypeString(val) === '[object Object]'
export function isEmptyObject(val) {
  return isPlainObject(val) && Object.keys(val).length === 0
}
// 获取国家列表里面当前语言对应的名称
export function getLanguagesName(lang, json) {
  return lang === 'zh' ? json.lang : json[lang]
}
// 获取国家地区里面当前语言对应名称
export function getCountryListName(lang, json) {
  return lang === 'zh' ? json.cname : json[`${lang}Name`]
}

/**
 * @description 版本对比
 * @returns {number} 1 => 远程版本大，-1 => 本地版本大，0 => 版本相同
 */
export function compareVersion(curr = '', remote = '') {
  if (curr === remote) return 0
  const currStack = curr.split('.')
  const remoteStack = remote.split('.')
  while (remoteStack.length) {
    if (currStack.length === 0) return 1 // 远程版本stack长度大于curr长度时，代表远程的版本大，此时所有currStack都已经遍历完，且和remoteStack的前几项都一样
    const currItem = +currStack.shift()
    const remoteItem = +remoteStack.shift()
    if (currItem > remoteItem) return -1
    if (currItem < remoteItem) return 1
  }
  if (currStack.length > 0) return -1 // 远程版本比较完，currStack还有元素，代表curr版本大
}
// 延迟
export function sleep(seconds) {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, seconds * 1000)
  })
}
