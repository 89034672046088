export default {
  sysDialog: 'Notice',
  forbiddenMobile: 'Please use the software client or computer browser to log in! Mobile web is forbidden.',
  refresh: 'Reload the page, if the page is still blank, press {shortKey} to reload again!',
  noticeNavBar: 'Notice:',
  syncError: 'Sync Error',
  upgradeAvailable: 'Upgrade Available',
  onlineService: 'Online Customer Service',
  wechatGroup: 'Contact',
  wechatGroupTips: 'Click here to scan the QR code to join us.',
  iKnow: 'Know It',
  officialWebsite: 'Official Website',
  profile: 'Profile',
  settings: 'Settings',
  signOut: 'Sign Out',
  logAndClose: 'Sign Out And Quit',
  wechatGroupDialog: 'Customer Group QR Code',
  joinGroup: '(Click To Join)',
  signOutConfirm:
    'Confirm to sign out?<p class="q-mt-sm">Before signing out, we recommend you to close all opened browsers, otherwise which will be closed directly and the data step will fail!</p>',
  signOutConfirmClose:
    'Confirm Sign Out And Quit?<p class="q-mt-sm">Before Sign Out And Quit, we recommend you to close all opened browsers, otherwise which will be closed directly and the data step will fail!</p>',
  signOutWeb: 'Confirm to sign out?',
  signOutSuccess: 'Success',
  pageRecords: '{total} Records',
  gotoPage: 'Goto',
  page: 'Page',
  '10PerPage': '10 Records/Page',
  '20PerPage': '20 Records/Page',
  '50PerPage': '50 Records/Page',
  '100PerPage': '100 Records/Page',
  noData: 'No Data',
  reminder: 'Tips',
  reminderComments:
    'BitBrowser has been free for more than a year. With repeated epidemics, it has been unable to afford staff expenses and server expenses. BitBrowser experienced a difficult time. In order to improve services, BitBrowser decided to start charging! After charging, the service will be maintained permanently. Thanks for understanding and support (Our pricing is set low, currently 1/4-1/8 of the price of peers)',
  reminderTips: 'Note: ',
  reminder1: '1. Users whose subscription have not expired will not be charged within the validity period.',
  reminder2:
    '2. You can change to a lower subscription. There is no charge for changing downwards subscription, and the price will be lower if you renew. You can also reduce users or subscriptions, which can also reduce the renewal price!',
  reminder3:
    'In order to thank old customers for their support, all old customers can get a 20% discount coupon, please click the dashboard button "Old users receive 20% discount coupon" to get it!',
  dismiss: 'No longer pop-up',
  imgLoadingError: 'Image Could Not Be Loaded',
  scanWechat: 'Scan WeChat To Contact Customer Service.',
  paymentMoney: 'Amount',
  cny: '¥ ({money})',
  moneyUnit: '¥',
  waitingForPay: 'Waitting For Payment...',
  syncDialog: 'Synchronous Data Exception Handling',
  syncTips:
    'Note: The following browsers may fail to synchronize data due to network issues! BitBrowser recommends to switch or close the proxy node and click "Retry" to sync again! (Synchronization failure may cause problems such as account logout)',
  syncTips2: 'Click "Ignore" to ignore the current failed sync-data and no longer synchronize',
  syncIgnore: 'Ignore',
  usdtMoney: 'Amount: ',
  usdtValue: '(¥ {money}, Rate: {rate})',
  usdtAddress: 'Address',
  taxIDPlaceholder: 'After the transfer is completed, please enter the complete transaction ID (TxID, Transaction ID)',
  taxID: 'TxID',
  usdtTips: 'Note: ',
  usdtTips1:
    '1. Please scan the QR code or use the "USDT Address" to pay. Be sure to enter the exact number to the decimal point in the "Transfer Amount", otherwise it will not be credited! ',
  usdtTips11: 'If deduct USDT in the transfer process, the received actual amount must be consistent with the page amount',
  usdtTips2:
    '2. After payment, enter the confirmation process, after confirming that the payment successfully, to enter the transaction number (TxID) of this order, and click "Payment Completed"!',
  taxIDRule: 'Input TxID',
  wechatScanPay: 'Please Use WeChat Scan To Pay',
  quitConfirm:
    'Confirm to quit?<p class="q-mt-sm">We recommend you to close all opened browsers beforing quiting, otherwise the opened browsers will be closed directly and data synchronization will fail!</p>',
  changeLineConfirm:
    'Confirm to switch lines?<br/>When switching lines, all browsers will be closed and the client needs to be signed in again!',
  clientVersion: 'Version: ',
  releaseNote: 'Release Notes: ',
  downloadBackgroud: 'Download Background',
  upgradeNow: 'Upgrade',
  upgradeTips: 'Updates will close open browsers. please save your data!',
  install: 'Install',
  applyingUpdates: 'Applying updates, please wait.',
  updateError:
    'Upgrade failed. Please relaunch as an administrator to try to upgrade again.<br/>Note: Dont run the client as an administrator after upgrade, or some functions may fail!',
  uncompressError: 'Uncompress error.',
  updateDetected: 'Updated content is avaliable. Please refresh the page.',
  首页: 'Dashboard',
  line: 'Line',
  goodMorning: 'Good morning, ',
  goodMorning2: 'Good morning, ',
  goodAfternoon: 'Good afternoon, ',
  goodAfternoon2: 'Good afternoon, ',
  GoodEvening: 'Good evening, ',
  loginTo: 'welcome to log in to {arg}',
  usdtAddressTips:
    'Note: Please carefully check whether the receipt address is consistent with the copied receipt address to prevent your clipboard from being tampered with by a Trojan horse and causing losses.',
  whatsappService: 'Click to Add Customer Service WhatsApp',
  telegramService: 'Click to Add Customer Service Telegram',
  error400: 'Username or password error.',
  changeLocaleTips: 'Switching languages will reload the page, if the page is always blank, please press {shortKey} to load it again!',
  numUnit: '',
  toDownlaod: 'To Download',
  oldWindowsTips: 'win7/win8/win8.1/windows server 2012 no longer supports the current kernel version, please switch to 104 kernel.',
  quit: 'Exit',
  popTip: 'Pop Up',
  securityTitle: 'Data Security Tips',
  securityContent:
    "BitBrowser's latest version has comprehensively updated its encryption strategy, providing separate key isolation for each window's data, maximizing user data security. <span style='text-decoration:underline'>Due to the inability of versions 5.0.8 and below to decrypt the latest encrypted data, some sync functions in older versions have been disabled. We strongly recommend all users to upgrade to the latest version immediately to enhance data protection and use more secure sync features!</span>",
  securityContent2:
    'For users concerned about Web3.0 and their data security, we recommend taking measures such as enabling two-factor authentication when logging into BitBrowser, changing website passwords, and transferring wallet assets to enhance security.',
  securityContent3:
    "Friendly reminder: For Web3.0 and digital wallet users, regardless of the browser used, it is not recommended to enable the 'sync extension data' option. We recommend keeping extension application data locally for maximum security!! Also, delete cloud extension data to reduce unknown security risks!!! (BitBrowser's security promise: Whether now or in the future, as long as you create a new window to import a new wallet without enabling extension data sync, there are no security risks).",
  chromeUpdateDetected: 'Kernel update detected, click to view details',
  chromeUpdateTitle: 'Kernel update detected',
  chromeVersionUpdate: 'Kernel version {version} detected an update, click to update now',
  chromeUpdateError: 'Update failed: {msg}',
  chromeUpdateSuccess: 'Kernel {version} update successful.',
  ignoreUpdate: 'Ignore this update',
  themeDarkMode: 'Light Mode / Dark Mode',
  copyPaymentUrl: 'Copy the payment link and open it in Chrome browser to make the payment.',
  alipayQRTips: 'Please use Alipay to scan and pay.'
}
