export default {
  groupPlaceholder: 'Выберите группу для поиска',
  belong: 'Принадлежность:',
  datePlaceholder: 'Поиск по времени удаления',
  browserNamePlaceholder: 'Поиск по имени',
  remarkPlaceholder: 'Поиск по комментариям',
  showAll: 'Показать все окна',
  showSelf: 'Показать собственные окна',
  recoverSelection: 'Восстановить выбранные',
  recoverAll: 'Восстановить все',
  deleteSelection: 'Удалить выбранные',
  deleteAll: 'Удалить все',
  recoverDialog: 'Восстановление окна',
  recoverTips1: 'Внимание: это действие восстановит все окна из корзины в следующую группу, включая окна в подчиненных аккаунтах',
  recoverTips2: 'Внимание: это действие восстановит только выбранные окна в следующую группу',
  createdName: 'Принадлежность',
  updateBy: 'Пользователь операции',
  deleteTime: 'Время удаления',
  deleteConfirm: '<p class="text-red">Удаленные окна невозможно восстановить. Вы уверены, что хотите удалить выбранные окна?</p>',
  deleteSuccess: 'Успешно удалено',
  clearConfirm: '<p class="text-red">Удаленные окна невозможно восстановить. Вы уверены, что хотите удалить все окна?</p>',
  recoverSuccess: 'Успешно восстановлено',
  browserName: 'Имя',
  browserSeq: '№',
  browserSeqPlaceholder: 'Поиск по номеру',
  browserSeqToolTip:
    'Несколько номеров разделяются запятыми, и диапазоны номеров могут быть только в порядке возрастания, разделяемые дефисом (например: 1-100).'
}
