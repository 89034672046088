export default {
  category: 'Категория:',
  addExtension: 'Добавить пользовательское расширение',
  addFromLocal: 'Добавить локальное расширение',
  addFromGoogle: 'Добавить расширение из Chrome Web Store',
  publishTips: 'Для публикации расширения в центре расширений свяжитесь с обслуживанием клиентов.',
  publish: 'Опубликовать',
  enable: 'Включено',
  disable: 'Выключено',
  status: 'Статус:',
  name: 'Название расширения:',
  userExtensions: 'Пользовательские расширения',
  noUserExtensions: 'Нет добавленных расширений',
  extensions: 'Центр расширений',
  more: 'Показать больше',
  provider: 'Поставщик:',
  notInClient: 'Выполните операцию в клиентском приложении',
  uploadExtension: 'Загрузить расширение',
  uploadTips:
    'Подсказка: В стандартной папке для Chrome-расширений должен быть файл manifest.json, содержащий информацию о названии и версии расширения. Пожалуйста, выберите папку с файлом manifest.json в качестве корневой директории.',
  checkDir: 'Нажмите, чтобы выбрать папку с расширением и автоматически определить информацию о расширении',
  pickDir: 'Выберите папку с расширением',
  extensionDir: 'Директория расширения',
  versionPlaceholder: 'Автоматическое определение версии расширения',
  version: 'Версия расширения',
  icon: 'Иконка расширения',
  iconTips: 'Выберите квадратное изображение размером не более 50 КБ',
  extensionDesc: 'Описание расширения',
  googleStore: 'Распознать расширение Chrome Web Store',
  googleStoreTips:
    'Подсказка: Введите URL страницы с подробной информацией о требуемом расширении из Google Chrome Web Store, чтобы автоматически распознать и загрузить расширение',
  extensionUrlPlaceholder: 'Введите URL расширения',
  extensionUrl: 'URL расширения',
  detect: 'Распознать',
  nameRuleMsg: 'Введите название расширения',
  descRuleMsg: 'Введите описание расширения',
  pathRuleMsg: 'Выберите папку с расширением, нажав кнопку выше',
  disableExtension: 'Вы точно хотите удалить это расширение из всех браузеров?',
  enableExtension: 'Вы уверены, что хотите включить это расширение?',
  syncSuccess: 'Синхронизация выполнена успешно',
  syncFailed: 'Синхронизация не удалась, пожалуйста, повторите попытку',
  noManifest: 'В выбранной директории отсутствует файл manifest.json. Выберите еще раз',
  saveSuccess: 'Сохранение выполнено успешно',
  deleteExtension: 'Удалить локальное расширение?',
  deleteSuccess: 'Удаление выполнено успешно',
  detectFailed: 'Не удалось выполнить распознавание, убедитесь, что глобальный прокси-сервер включен',
  saveFailed: 'Выполните правильное распознавание перед добавлением',
  '9PerPage': '9 записей на странице',
  '18PerPage': '18 записей на странице',
  '45PerPage': '45 записей на странице',
  '90PerPage': '90 записей на странице',
  upgradeTips: 'Обновите клиентское приложение до последней версии, чтобы использовать функцию расширений!',
  uploadSuccess: 'Загрузка успешно завершена',
  uploadMaxSize: 'Выберите изображение меньше 50 КБ!',
  notImg: 'Выберите изображение для загрузки!',
  syncEnabled: 'Синхронизация аккаунтов сотрудников включена',
  syncEnabledConfirm: 'Вы уверены, что хотите включить синхронизацию этого расширения для всех аккаунтов сотрудников?',
  syncDisabled: 'Синхронизация аккаунтов сотрудников отключена',
  syncDisabledConfirm: 'Вы уверены, что хотите синхронизировать отключение этого расширения для всех сотрудников?',
  cloudSync: 'Облачная синхронизация',
  deleteExtensionTips: 'Удалить расширение',
  upgradeExtensionTips: ' Обновить расширение',
  updateLocalExtensionTips:
    'Важно правильно выбрать расширение для обновления, иначе это может привести к потере информации о текущем расширении!',
  noNeedUpdate: 'У вас уже установлена последняя версия, обновление не требуется!',
  name1: 'Название расширения',
  extensionExists: 'Расширение уже существует',
  updateFailed: 'Обновление не удалось. Проверьте глобальный прокси в локальной сети',
  uploader: 'Загрузил:',
  cannotDelete: 'Не загружено вами, не может быть удалено!',
  allBrowser: 'Все окна',
  allBrowserDesc: 'Все окна будут открывать это расширение',
  prescribedBrowser: 'Определенные окна',
  prescribedBrowserDesc: 'Чтобы включить расширение, необходимо вручную выбрать конкретное окно или группу',
  settingExtensionMethod: 'Настройка способа активации расширения',
  extendsSetiingaVailable: 'Эта функция доступна только для версии 6.0.4 и выше',
  setting: 'Настройки',
  noGoogleUrl: 'Введите URL страницы деталей приложения расширения в магазине приложений Chrome.',
  googleUrlDetection: 'Обнаружение не удалось, введите URL страницы расширения в Chrome.',
  mainExtensionsTip: 'Расширение с таким же именем уже существует в основной учетной записи. Пожалуйста, выберите обновление расширения.',
  toggleExtensEetection: 'Настройка расширений запрещена для аккаунтов, кроме основного.',
  failedToGetExtension: 'Не удалось получить расширение, пожалуйста, повторите попытку позже.'
}
